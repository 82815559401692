import React, { Component } from 'react'
import OlMap from "ol/Map"
import View from "ol/View"
import { transform } from "ol/proj"
import { Polygon } from "ol/geom";
import Draw from "ol/interaction/Draw"
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer'
import { OSM, Vector as VectorSource } from 'ol/source'
import styled from "styled-components"

const MapDiv = styled.div`
    width: 100%;
    height: 380px;
`;

export default class CompanyAirportMap extends Component {

    mapId = null;
    map;
    draw;
    addInteraction;
    raster;
    source;
    vector;

    componentWillUnmount() {
        this.map = null;
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.selectMapCenter !== this.props.selectMapCenter && nextProps.selectMapCenter) {

            this.draw = new Draw({
                source: this.source,
                type: "Point"
            });

            this.map && this.map.addInteraction(this.draw);

            this.draw.on("drawend", (data) => {

                this.source.clear()

                let coords = data.feature.getGeometry().transform('EPSG:3857', 'EPSG:4326').getCoordinates()
                data.feature.getGeometry().transform('EPSG:4326', 'EPSG:3857').getCoordinates()

                this.props.onSelectMapCenter && this.props.onSelectMapCenter([coords[1], coords[0]])

            })

        } else if (nextProps.selectMapCenter !== this.props.selectMapCenter && !nextProps.selectMapCenter) {
            this.map.removeInteraction(this.draw)
            this.source.clear()
        }

        if (nextProps.selectMapBoundaries !== this.props.selectMapBoundaries && nextProps.selectMapBoundaries) {

            this.draw = new Draw({
                source: this.source,
                type: "Circle",
                geometryFunction: function (coords, geom) {
                    if (!geom)
                        geom = new Polygon([coords[0], coords[0], coords[0], coords[0], coords[0]]);

                    var firstCorner = coords[0];
                    var thirdCorner = coords[1];

                    var secondCorner = [thirdCorner[0], firstCorner[1]];
                    var forthCorner = [firstCorner[0], thirdCorner[1]];

                    var newCoordinates = [firstCorner, secondCorner, thirdCorner, forthCorner, firstCorner];
                    geom.setCoordinates([newCoordinates]);

                    return geom;

                }
            });

            this.map && this.map.addInteraction(this.draw);

            this.draw.on("drawend", (data) => {

                this.source.clear()

                let coords = data.feature.getGeometry().transform('EPSG:3857', 'EPSG:4326').getCoordinates()
                data.feature.getGeometry().transform('EPSG:4326', 'EPSG:3857').getCoordinates()

                let northEast, southWest, i = 0;

                coords[0].forEach(coord => {

                    let lat = coord[1];
                    let long = coord[0];

                    if (i === 0) {
                        northEast = [lat, long];
                        southWest = [lat, long];
                    } else {

                        if (lat >= northEast[0] && long <= northEast[1])
                            northEast = [lat, long];

                        if (lat <= southWest[0] && long >= southWest[1])
                            southWest = [lat, long];

                    }

                    i++
                })

                this.props.onSelectMapBoundaries && this.props.onSelectMapBoundaries([northEast, southWest])

            })

        } else if (nextProps.selectMapBoundaries !== this.props.selectMapBoundaries && !nextProps.selectMapBoundaries) {
            this.map.removeInteraction(this.draw)
            this.source.clear()
        }

    }

    state = {
        mapId: null
    }

    async componentDidMount() {

        await this.setState({ mapId: `map-${Math.floor(Math.random() * 10000)}` });

        this.raster = new TileLayer({
            source: new OSM()
        })

        this.source = new VectorSource({ wrapX: false })

        this.vector = new VectorLayer({
            source: this.source
        })

        this.map = new OlMap({
            layers: [this.raster, this.vector],
            target: this.state.mapId,
            view: new View({
                center: [-11000000, 4600000],
                zoom: 4
            })
        })

    }

    componentDidUpdate(prevProps, prevState) {
        this.map && this.map.updateSize()

        this.props.mapCenter && this.map.getView().setCenter(transform([this.props.mapCenter[1], this.props.mapCenter[0]], 'EPSG:4326', 'EPSG:3857'));
    }


    render() {
        return (
            <div>
                <MapDiv id={this.state.mapId} className={this.state.mapId}></MapDiv>
            </div>
        )
    }
}
