import React, { Component } from "react";
import Header from "../../../../components/Header";
import Content from "../../../../components/Content";
import { Button } from "antd";
import { permissions } from "../../../../utils/permissions";
import history from "../../../../history";
import { set } from "../../../../_actions/appActions";
import store from "../../../../store";
import axios from "axios";
import { getToken } from "../../../../utils/token";
import { unknownErrorAlert } from "../../../../utils/app";

import RolesList from "components/RolesList";

export default class Index extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            dataSource: [],
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidMount() {
        this._isMounted = true;
        this._isMounted &&
            store.dispatch(
                set({
                    loading: true,
                    breadcrumb: [{ name: "Roles", href: "/roles" }],
                }),
            );

        try {
            const API_RESPONSE = await axios.get(`${window.API}/roles`, {
                headers: { Authorization: `Bearer ${getToken()}` },
            });

            if (API_RESPONSE.status === 200 && API_RESPONSE.data.success) {
                this._isMounted &&
                    this.setState({ dataSource: API_RESPONSE.data.roles });
                this._isMounted && store.dispatch(set({ loading: false }));
            }
        } catch (err) {
            unknownErrorAlert();
        }
    }

    render() {
        if (!permissions.check("ROLE-R")) return <div />;

        return (
            <div>
                <Header
                    extra={
                        permissions.check("ROLE-C") ? (
                            <Button
                                onClick={e => history.push("/roles/add")}
                                size="small"
                            >
                                Add
                            </Button>
                        ) : (
                            ""
                        )
                    }
                >
                    Roles
                </Header>
                <Content>
                    <RolesList dataSource={this.state.dataSource} />
                </Content>
            </div>
        );
    }
}
