import React, { Component } from "react";
import Header from "../../../../components/Header";
import Content from "../../../../components/Content";
import store from "../../../../store";
import { set } from "../../../../_actions/appActions";
import { connect } from "react-redux";
import history from "../../../../history";
import { Button, Row, Col, message, Modal } from "antd";
import { permissions } from "../../../../utils/permissions";
import axios from "axios";
import { getToken } from "../../../../utils/token";
import { unknownErrorAlert, unknownErrorMessage } from "../../../../utils/app";

import Info from "./Tabs/Info";
import Permissions from "./Tabs/Permissions";
import Users from "./Tabs/Users";

const { confirm } = Modal;

export class Role extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            roleName: null,
            role: {},
            activeTab:
                history.location.hash !== ""
                    ? history.location.hash.split("#")[1]
                    : "info",
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidMount() {
        this._isMounted = true;
        this._isMounted && store.dispatch(set({ loading: true }));

        try {
            const API_RESPONSE = await axios.get(
                `${window.API}/roles/${this.props.match.params.role}?company=${this.props.match.params.company}`,
                {
                    headers: { Authorization: `Bearer ${getToken()}` },
                },
            );

            if (
                API_RESPONSE.status === 200 &&
                API_RESPONSE.data.success &&
                API_RESPONSE.data.role
            ) {
                this._isMounted &&
                    this.setState({
                        roleName: API_RESPONSE.data.role.roleName,
                        role: API_RESPONSE.data.role,
                    });

                this._isMounted &&
                    store.dispatch(
                        set({
                            loading: false,
                            breadcrumb: [
                                { name: "Roles", href: "/roles" },
                                {
                                    name: this.state.role.roleName,
                                    href:
                                        "/roles/" +
                                        this.state.role.company.company +
                                        "/" +
                                        this.state.username,
                                },
                            ],
                        }),
                    );
            } else {
                unknownErrorAlert(API_RESPONSE.data.message);
            }
        } catch (err) {
            unknownErrorAlert();
        }
    }

    tabChange = key => {
        this.setState({ activeTab: key });
    };

    renderTab() {
        switch (this.state.activeTab) {
            case "info":
                return <Info role={this.state.role} />;
            case "permissions":
                return <Permissions role={this.state.role} />;
            case "users":
                return <Users role={this.state.role} />;
            default:
                return <div />;
        }
    }

    async handleDelete(e) {
        confirm({
            title: "Are you sure delete this user?",
            content: "This operation is permament.",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: async e => {
                try {
                    const API_RESPONSE = await axios.delete(
                        `${window.API}/users/${this.props.user._id}`,
                        {
                            headers: { Authorization: `Bearer ${getToken()}` },
                        },
                    );

                    if (
                        API_RESPONSE.status === 200 &&
                        API_RESPONSE.data.success === true
                    ) {
                        message.success("Successfully deleted the user.");

                        history.push("/users");
                    } else {
                        message.warning(API_RESPONSE.data.message);
                    }
                } catch (err) {
                    unknownErrorMessage(err.response ? err.response.data.message : null);
                }
            },
        });
    }

    render() {
        if (!permissions.check("ROLE-R")) return <div />;

        return (
            <div>
                <Header
                    extra={
                        <Row type="flex">
                            <Col>
                                {permissions.check("ROLE-U-A") ||
                                (permissions.check("ROLE-U-C") &&
                                    this.props.user.company &&
                                    this.state.role.company &&
                                    this.state.role.company._id ===
                                        this.props.user.company._id) ? (
                                    <Button
                                        onClick={e =>
                                            history.push(
                                                "/roles/" +
                                                    (this.state.role
                                                        ? this.state.role
                                                              .company.company +
                                                          "/" +
                                                          this.state.role.role +
                                                          "/"
                                                        : "") +
                                                    "update",
                                            )
                                        }
                                        size="small"
                                    >
                                        Update
                                    </Button>
                                ) : (
                                    ""
                                )}
                            </Col>
                            <Col style={{ marginLeft: 12 }}>
                                {permissions.check("ROLE-D-A") ||
                                (permissions.check("ROLE-D-C") &&
                                    this.props.user.company &&
                                    this.state.role.company &&
                                    this.state.role.company._id ===
                                        this.props.user.company._id) ? (
                                    <Button
                                        onClick={e => this.handleDelete()}
                                        size="small"
                                        type="danger"
                                    >
                                        Delete
                                    </Button>
                                ) : (
                                    ""
                                )}
                            </Col>
                        </Row>
                    }
                    activeTab={this.state.activeTab}
                    tabs={[
                        {
                            text: "Information",
                            key: "info",
                            onClick: this.tabChange,
                        },
                        {
                            text: "Permissions",
                            key: "permissions",
                            onClick: this.tabChange,
                        },
                        {
                            text: "Users",
                            key: "users",
                            onClick: this.tabChange,
                        },
                    ]}
                >
                    Role: {this.state.roleName}
                </Header>
                <Content>{this.renderTab()}</Content>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Role);
