import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Auth from "./components/Auth";
import { set } from "./_actions/appActions"
import store from "./store"
import history from "./history"

//Routes

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";

export default class Routes extends Component {

    componentDidMount() {
        history.listen((location, action) => {
            store.dispatch(set({ loading: false, disabled: false, disabledText: null }))
        })
    }


    render() {
        return (
            <Switch>
                <Route exact path="/login" component={Login} />
                <Auth>
                    <Route path="/" component={Dashboard} />
                </Auth>
            </Switch>
        );
    }
}
