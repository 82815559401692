import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { permissions } from "../../../utils/permissions";

import Trips from "./Index/";
import Trip from "./Trip/";

export default class index extends Component {
    render() {
        if (!permissions.oneOf(["TRIP-R"])) return <Redirect to="/" />;

        return (
            <div>
                <Switch>
                    <Route exact path="/trips" component={Trips} />
                    <Route exact path="/trips/:trip" component={Trip} />
                </Switch>
            </div>
        );
    }
}
