import React, { Component } from "react";
import { Table, Modal, Button, Icon, message } from "antd";
import moment from "moment";
import { permissions } from "../utils/permissions";
import history from "../history";
import axios from "axios";
import { getToken } from "../utils/token";
import { unknownErrorMessage } from "../utils/app";
import styled from "styled-components";

const { confirm } = Modal;

const Online = styled.span`
    color: #287906;
    font-weight: 500;
`;

export class EquipmentList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSource: [],
            modalVisible: false,
            currentRecord: null,
            filtersType: [],
        };

        this.columns = [
            {
                title: "",
                dataIndex: "info",
                key: "info",
                render: (el, record) => {
                    return (
                        <Icon
                            style={{ fontSize: 18 }}
                            onClick={e => this.openModal(e, record)}
                            type="info-circle"
                            theme="twoTone"
                        />
                    );
                },
            },
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: status =>
                    status ? <Online>Online</Online> : "Offline",
            },
            {
                title: "Type",
                dataIndex: "type",
                key: "type",
                filters: this.state.filtersType,
                render: type => <span>{type.name}</span>,
            },
            {
                title: "Manufacture",
                dataIndex: "manufacture",
                key: "manufacture",
                render: manufacture => <span>{manufacture.name}</span>,
            },
            {
                title: "Model",
                dataIndex: "model",
                key: "model",
                render: model => <span>{model.name}</span>,
            },
            {
                title: "Number plate",
                dataIndex: "numberPlate",
                key: "numberPlate",
            },
            {
                title: "Company",
                dataIndex: "company",
                key: "company",
                render: company => (company ? company.companyName : ""),
            },
            {
                title: "Create date",
                dataIndex: "createDate",
                key: "createDate",
                sorter: (a, b) =>
                    new Date(a.createDate) - new Date(b.createDate),
                render: date => (
                    <span>{moment(date).format("D MMMM Y HH:mm")}</span>
                ),
            },
        ];
    }

    openModal(e, record) {
        e.stopPropagation();

        this.setState({ modalVisible: true, currentRecord: record });
    }

    async handleDelete(e) {
        e.stopPropagation();

        confirm({
            title: "Are you sure delete this equipment?",
            content: "This operation is permament.",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: async e => {
                try {
                    const API_RESPONSE = await axios.delete(
                        `${window.API}/equipment/${this.state.currentRecord._id}`,
                        {
                            headers: { Authorization: `Bearer ${getToken()}` },
                        },
                    );

                    if (
                        API_RESPONSE.status === 200 &&
                        API_RESPONSE.data.success === true
                    ) {
                        message.success("Successfully deleted the equipment.");

                        let index = this.props.dataSource.findIndex(
                            el => el._id === this.state.currentRecord._id,
                        );
                        if (index !== -1)
                            this.setState({
                                dataSource: this.props.dataSource.splice(
                                    index,
                                    1,
                                ),
                                modalVisible: false,
                            });
                    } else {
                        message.warning(API_RESPONSE.data.message);
                    }
                } catch (err) {
                    unknownErrorMessage(
                        err.response ? err.response.data.message : null,
                    );
                }
            },
        });
    }

    rowClick(record) {
        this.setState({ modalVisible: true, currentRecord: record });
    }

    handleOk = e => {
        this.setState({
            modalVisible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            modalVisible: false,
        });
    };

    render() {
        if (!permissions.check("EQ-R")) return <div />;

        return (
            <div>
                <Table
                    showSearch={true}
                    rowKey={record => record._id}
                    filterOption={(inputValue, item) =>
                        item.name
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) !== -1 ||
                        item.description
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) !== -1
                    }
                    rowClassName="table-row"
                    columns={this.columns}
                    dataSource={this.props.dataSource}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event =>
                                history.push(
                                    "/equipment/" +
                                        record.company.company +
                                        "/" +
                                        record._id,
                                ),
                        };
                    }}
                />

                <Modal
                    title={
                        this.state.currentRecord
                            ? "Equipment: " +
                              this.state.currentRecord.manufacture.name +
                              " " +
                              this.state.currentRecord.model.name
                            : ""
                    }
                    visible={this.state.modalVisible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        permissions.check("EQ-U") ? (
                            <Button
                                onClick={e =>
                                    history.push(
                                        "/equipment/" +
                                            (this.state.currentRecord
                                                ? this.state.currentRecord
                                                      .company.company
                                                : "") +
                                            "/" +
                                            (this.state.currentRecord
                                                ? this.state.currentRecord._id
                                                : "") +
                                            "/update",
                                    )
                                }
                                type="ghost"
                                color="gold"
                                key="update"
                            >
                                Update
                            </Button>
                        ) : (
                            ""
                        ),
                        permissions.check("EQ-D") ? (
                            <Button
                                type="danger"
                                key="delete"
                                onClick={e => this.handleDelete(e)}
                            >
                                Delete
                            </Button>
                        ) : (
                            ""
                        ),
                        <Button
                            onClick={e =>
                                history.push(
                                    "/equipment/" +
                                        (this.state.currentRecord
                                            ? this.state.currentRecord.company
                                                  .company
                                            : "") +
                                        "/" +
                                        (this.state.currentRecord
                                            ? this.state.currentRecord._id
                                            : ""),
                                )
                            }
                            type="ghost"
                            color="gold"
                            key="showEq"
                        >
                            Show equipment
                        </Button>,
                    ]}
                >
                    <p>
                        <strong>Type:</strong>{" "}
                        {this.state.currentRecord &&
                            this.state.currentRecord.type.name}
                    </p>
                    <p>
                        <strong>Manufacture:</strong>{" "}
                        {this.state.currentRecord &&
                            this.state.currentRecord.manufacture.name}
                    </p>
                    <p>
                        <strong>Model:</strong>{" "}
                        {this.state.currentRecord &&
                            this.state.currentRecord.model.name}
                    </p>
                    <p>
                        <strong>Number plate:</strong>{" "}
                        {this.state.currentRecord &&
                            this.state.currentRecord.numberPlate}
                    </p>
                </Modal>
            </div>
        );
    }
}

export default EquipmentList;
