import React, { Component } from 'react'
import { Switch, Route, Redirect } from "react-router-dom";
import { permissions } from "../../../utils/permissions"

import Users from "./Index/index.js";
import Add from "./Add/index.js";
import Update from "./Update/index.js";
import User from "./User/index.js";

export default class index extends Component {
    render() {
        if (!permissions.oneOf(["USER-C", "USER-R", "USER-U", "USER-D"])) return <Redirect to="/" />

        return (
            <div>
                <Switch>
                    <Route exact path="/users" component={Users} />
                    <Route exact path="/users/add" component={Add} />
                    <Route exact path="/users/:user" component={User} />
                    <Route path="/users/:user/update" component={Update} />
                </Switch>
            </div>
        )
    }
}
