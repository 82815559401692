import React, { Component } from 'react'
import { Link } from "react-router-dom"
import { Row, Col, Card, Tag } from "antd"
import fontColorContrast from "font-color-contrast"
import { permissions } from "../../../../../utils/permissions"

export default class Info extends Component {
    render() {
        return (
            <div>
                <Row gutter={42}>
                    <Col style={{ marginBottom: 42 }} lg={12} md={24}>
                        <Card title="Basic information">
                            <p><strong>Role name:</strong> {this.props.role.roleName}</p>
                            {permissions.check("ROLE-R-A") && this.props.role.company && <p><strong>Company:</strong> {(
                                permissions.check("COMPANY-R") ?
                                    <Link to={"/companies/" + this.props.role.company.company}>{this.props.role.company.companyName}</Link>
                                    : <span>{this.props.role.company.companyName}</span>
                            )} </p>}
                        </Card>
                    </Col>
                    <Col style={{ marginBottom: 42 }} lg={12} md={24}>
                        <Card title="Additional information">
                            <strong>Color:</strong> <Tag color={this.props.role.color}><span style={{ color: fontColorContrast(this.props.role.color) }}>{this.props.role.color}</span></Tag>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}
