import React, { Component } from "react";
import { connect } from "react-redux";
import { permissions } from "../../utils/permissions";
import { Layout, Menu, Icon, Spin, Alert } from "antd";
import styled from "styled-components";
import { Switch, Route } from "react-router-dom";
import history from "../../history";
import { removeToken } from "../../utils/token";

import Home from "./Home";
import Users from "./Users";
import Account from "./Account";
import Roles from "./Roles";
import Companies from "./Companies";
import System from "./System";
import Equipment from "./Equipment";
import Trips from "./Trips";

const { Content, Sider } = Layout;
const { SubMenu } = Menu;

const Logo = styled.div`
    height: 72px;
    color: "#333";
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    letter-spacing: 0.2em;
    font-size: 1.2em;
`;

const LogoImage = styled.img`
    max-width: 260px;
    max-height: 120px;
`;

const Disabled = styled.div``;

export class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedKeys: window.location.pathname
        };
    }

    componentDidMount() {
        this.handleKeysWithoutMenuItem();
    }

    componentWillReceiveProps() {
        this.handleKeysWithoutMenuItem();
    }

    handleKeysWithoutMenuItem() {
        const KEYS_TRANSLATE = [
            {
                from: "/roles/@string",
                to: "/roles",
                except: ["/add"]
            },
            {
                from: "/users/@string",
                to: "/users",
                except: ["/add"]
            },
            {
                from: "/companies/@string",
                to: "/companies",
                except: ["/add"]
            },
            {
                from: "/equipment/@string",
                to: "/equipment",
                except: ["/add", "/types", "/manufactures", "/models"]
            }
        ];

        let tempKey = KEYS_TRANSLATE.find(
            key =>
                window.location.pathname.includes(
                    key.from.split("/@string")[0]
                ) &&
                !key.except.includes(
                    window.location.pathname.split(
                        key.from.split("/@string")[0]
                    )[1]
                )
        );

        if (tempKey) {
            this.setState({ selectedKeys: tempKey.to });
        } else this.setState({ selectedKeys: window.location.pathname });
    }

    handleLogout() {
        removeToken();
        window.location.reload();
    }

    render() {
        let disabledStyle = {};

        if (this.props.app.disabled) {
            disabledStyle = {
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                opacity: 1,
                background: "#f0f2f5",
                zIndex: 999,
                padding: 24
            };
        } else {
            disabledStyle = {};
        }

        return (
            <Layout style={{ height: "100vh" }}>
                <Sider
                    breakpoint="lg"
                    collapsedWidth="0"
                    theme="light"
                    width="260"
                >
                    {this.props.user && this.props.user.company.logo ? (
                        <Logo>
                            <LogoImage src={this.props.user.company.logo} />
                        </Logo>
                    ) : (
                        <Logo>PAWELGDAK</Logo>
                    )}

                    <Menu
                        theme="light"
                        mode="inline"
                        defaultSelectedKeys={["/"]}
                        selectedKeys={[this.state.selectedKeys]}
                    >
                        <Menu.Item key="/" onClick={e => history.push("/")}>
                            <Icon type="dashboard" />
                            <span className="nav-text">Dashboard</span>
                        </Menu.Item>

                        {permissions.oneOf(["USER-R-O", "USER-U-O"]) && (
                            <Menu.Item
                                key="/account"
                                onClick={e => history.push("/account")}
                            >
                                <Icon type="user" />
                                <span className="nav-text">Account</span>
                            </Menu.Item>
                        )}

                        {permissions.oneOf(["TRIP-R"]) && (
                            <Menu.Item
                                key="/trips"
                                onClick={e => history.push("/trips")}
                            >
                                <Icon type="environment" />
                                <span className="nav-text">Trips</span>
                            </Menu.Item>
                        )}

                        {permissions.oneOf(["USER-C", "USER-R"]) && (
                            <SubMenu
                                key="sub1"
                                title={
                                    <span>
                                        <Icon type="user" />
                                        <span>Users</span>
                                    </span>
                                }
                            >
                                {permissions.check("USER-R") && (
                                    <Menu.Item
                                        key="/users"
                                        onClick={e => history.push("/users")}
                                    >
                                        Show all
                                    </Menu.Item>
                                )}
                                {permissions.check("USER-C") && (
                                    <Menu.Item
                                        key="/users/add"
                                        onClick={e =>
                                            history.push("/users/add")
                                        }
                                    >
                                        Add new
                                    </Menu.Item>
                                )}
                            </SubMenu>
                        )}

                        {permissions.oneOf(["ROLE-C", "ROLE-R"]) && (
                            <SubMenu
                                key="/roles"
                                title={
                                    <span>
                                        <Icon type="build" />
                                        <span>Roles</span>
                                    </span>
                                }
                            >
                                {permissions.check("ROLE-R") && (
                                    <Menu.Item
                                        key="/roles"
                                        onClick={e => history.push("/roles")}
                                    >
                                        Show all
                                    </Menu.Item>
                                )}
                                {permissions.check("ROLE-C") && (
                                    <Menu.Item
                                        key="/roles/add"
                                        onClick={e =>
                                            history.push("/roles/add")
                                        }
                                    >
                                        Add new
                                    </Menu.Item>
                                )}
                            </SubMenu>
                        )}

                        {permissions.oneOf(["COMPANY-C-A", "COMPANY-R-A"]) ? (
                            <SubMenu
                                key="/companies"
                                title={
                                    <span>
                                        <Icon type="bank" />
                                        <span>Companies</span>
                                    </span>
                                }
                            >
                                {permissions.check("COMPANY-R-A") && (
                                    <Menu.Item
                                        key="/companies"
                                        onClick={e =>
                                            history.push("/companies")
                                        }
                                    >
                                        Show all
                                    </Menu.Item>
                                )}
                                {permissions.check("COMPANY-C-A") && (
                                    <Menu.Item
                                        key="/companies/add"
                                        onClick={e =>
                                            history.push("/companies/add")
                                        }
                                    >
                                        Add new
                                    </Menu.Item>
                                )}
                            </SubMenu>
                        ) : (
                            permissions.check("COMPANY-R-C") && (
                                <Menu.Item
                                    key="/companies"
                                    onClick={e =>
                                        history.push(
                                            "/companies/" +
                                                this.props.user.company.company
                                        )
                                    }
                                >
                                    <Icon type="bank" />
                                    <span className="nav-text">My company</span>
                                </Menu.Item>
                            )
                        )}

                        {permissions.oneOf(["EQ-C", "EQ-R"]) && (
                            <SubMenu
                                key="/equipment"
                                title={
                                    <span>
                                        <Icon type="car" />
                                        <span>Equipment</span>
                                    </span>
                                }
                            >
                                {permissions.check("EQ-R") && (
                                    <Menu.Item
                                        key="/equipment"
                                        onClick={e =>
                                            history.push("/equipment")
                                        }
                                    >
                                        Show all
                                    </Menu.Item>
                                )}
                                {permissions.check("EQ-C") && (
                                    <Menu.Item
                                        key="/equipment/add"
                                        onClick={e =>
                                            history.push("/equipment/add")
                                        }
                                    >
                                        Add new
                                    </Menu.Item>
                                )}
                                {permissions.check("EQ-R") && (
                                    <Menu.Item
                                        key="/equipment/types"
                                        onClick={e =>
                                            history.push("/equipment/types")
                                        }
                                    >
                                        Types
                                    </Menu.Item>
                                )}
                                {permissions.check("EQ-R") && (
                                    <Menu.Item
                                        key="/equipment/manufactures"
                                        onClick={e =>
                                            history.push(
                                                "/equipment/manufactures"
                                            )
                                        }
                                    >
                                        Manufactures
                                    </Menu.Item>
                                )}
                                {permissions.check("EQ-R") && (
                                    <Menu.Item
                                        key="/equipment/models"
                                        onClick={e =>
                                            history.push("/equipment/models")
                                        }
                                    >
                                        Models
                                    </Menu.Item>
                                )}
                            </SubMenu>
                        )}

                        {permissions.oneOf(["SYSTEM-R-A", "SYSTEM-U-A"]) && (
                            <SubMenu
                                key="/system"
                                title={
                                    <span>
                                        <Icon type="control" />
                                        <span>System</span>
                                    </span>
                                }
                            >
                                {permissions.check("SYSTEM-R-A") && (
                                    <Menu.Item
                                        key="/system"
                                        onClick={e => history.push("/system")}
                                    >
                                        Statistics
                                    </Menu.Item>
                                )}
                                {permissions.check("SYSTEM-U-A") && (
                                    <Menu.Item
                                        key="/system/settings"
                                        onClick={e =>
                                            history.push("/system/settings")
                                        }
                                    >
                                        Settings
                                    </Menu.Item>
                                )}
                            </SubMenu>
                        )}

                        <Menu.Item
                            key="/logout"
                            onClick={e => this.handleLogout()}
                        >
                            <Icon type="logout" />
                            <span className="nav-text">Logout</span>
                        </Menu.Item>
                    </Menu>
                </Sider>

                <Layout
                    style={{
                        overflow: this.props.app.disabled ? "hidden" : "auto"
                    }}
                >
                    <Layout>
                        <Spin spinning={this.props.app.loading}>
                            <Disabled style={disabledStyle}>
                                {this.props.app.disabledText &&
                                this.props.app.disabled ? (
                                    <Alert
                                        style={{}}
                                        type="error"
                                        message={this.props.app.disabledText}
                                        banner
                                    />
                                ) : (
                                    ""
                                )}
                            </Disabled>
                            <Content>
                                <Switch>
                                    <Route exact path="/" component={Home} />
                                    <Route path="/users" component={Users} />
                                    <Route
                                        path="/account"
                                        component={Account}
                                    />
                                    <Route path="/trips" component={Trips} />
                                    <Route path="/roles" component={Roles} />
                                    <Route
                                        path="/companies"
                                        component={Companies}
                                    />
                                    <Route path="/system" component={System} />
                                    <Route
                                        path="/equipment"
                                        component={Equipment}
                                    />
                                </Switch>
                            </Content>
                        </Spin>
                    </Layout>
                </Layout>
            </Layout>
        );
    }
}

const mapStateToProps = state => ({
    app: state.app,
    user: state.user
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);
