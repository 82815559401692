import io from "socket.io-client";
import { getToken } from "./utils/token";
import store from "./store";
import { set } from "./_actions/appActions";

class SocketClass {
    conn = null;

    connect(server = null) {
        this.conn = io(`${window.SOCKET}/client`, {
            query: { token: getToken() },
        });

        this.conn.on("connect", function() {
            store.dispatch(set({ socket: true }));
        });

        this.conn.on("disconnect", function() {
            store.dispatch(set({ socket: false }));
        });
    }
}

export const Socket = new SocketClass();
