import React, { Component } from 'react'

import MapComponent from "components/Map/CompanyAirportShow";

export default class Map extends Component {


    render() {

        if (this.props.company.airport && this.props.company.airport.location) {

            return (
                <div>
                    <MapComponent showFilters showEditMode company={this.props.company} airport={this.props.company.airport} />
                </div>
            )

        } else {

            return (
                <div>
                    Map is not specified
                </div>
            )

        }
    }
}
