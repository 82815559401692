import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Tag, Modal, Button, Card, Icon, message } from "antd";
import moment from "moment";
import fontColorContrast from "font-color-contrast";
import { Link } from "react-router-dom";
import { permissions } from "../utils/permissions";
import history from "../history";
import axios from "axios";
import { getToken } from "../utils/token";
import { unknownErrorMessage } from "../utils/app";

const { confirm } = Modal;

export class UsersList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSource: [],
            modalVisible: false,
            currentRecord: null,
        };

        this.columns = [
            {
                title: "",
                dataIndex: "info",
                key: "info",
                render: (el, record) => {
                    return (
                        <Icon
                            style={{ fontSize: 18 }}
                            onClick={e => this.openModal(e, record)}
                            type="info-circle"
                            theme="twoTone"
                        />
                    );
                },
            },
            {
                title: "Username",
                dataIndex: "username",
                key: "username",
            },
            {
                title: "E-mail",
                dataIndex: "email",
                key: "email",
            },
            {
                title: "Role",
                dataIndex: "role",
                key: "role",
                render: role => (
                    <Tag color={role.color}>
                        <span style={{ color: fontColorContrast(role.color) }}>
                            {role.roleName}
                        </span>
                    </Tag>
                ),
            },
            {
                title: "Phone number",
                dataIndex: "phoneNumber",
                key: "phoneNumber",
            },
            {
                title: "Company",
                dataIndex: "company",
                key: "company",
                render: company =>
                    company ? (
                        <Link to={"/companies/" + company.company}>
                            {company.companyName}
                        </Link>
                    ) : (
                        ""
                    ),
            },
            {
                title: "Create date",
                dataIndex: "createDate",
                key: "createDate",
                render: date => (
                    <span>{moment(date).format("D MMMM Y HH:mm")}</span>
                ),
            },
        ];
    }

    openModal(e, record) {
        e.stopPropagation();

        this.setState({ modalVisible: true, currentRecord: record });
    }

    async handleDelete(e) {
        e.stopPropagation();

        confirm({
            title: "Are you sure delete this user?",
            content: "This operation is permament.",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: async e => {
                try {
                    const API_RESPONSE = await axios.delete(
                        `${window.API}/users/${this.state.currentRecord._id}`,
                        {
                            headers: { Authorization: `Bearer ${getToken()}` },
                        },
                    );

                    if (
                        API_RESPONSE.status === 200 &&
                        API_RESPONSE.data.success === true
                    ) {
                        message.success("Successfully deleted the user.");

                        let index = this.props.dataSource.findIndex(
                            el =>
                                el.username ===
                                this.state.currentRecord.username,
                        );
                        if (index !== -1)
                            this.setState({
                                dataSource: this.props.dataSource.splice(
                                    index,
                                    1,
                                ),
                                modalVisible: false,
                            });
                    } else {
                        message.warning(API_RESPONSE.data.message);
                    }
                } catch (err) {
                    console.log(err.response);
                    unknownErrorMessage(err.response ? err.response.data.message : null);
                }
            },
        });
    }

    rowClick(record) {
        this.setState({ modalVisible: true, currentRecord: record });
    }

    handleOk = e => {
        this.setState({
            modalVisible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            modalVisible: false,
        });
    };

    render() {
        if (!permissions.check("USER-R")) return <div />;

        return (
            <div>
                <Table
                    showSearch={true}
                    rowKey={record => record._id}
                    filterOption={(inputValue, item) =>
                        item.name
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) !== -1 ||
                        item.description
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) !== -1
                    }
                    rowClassName="table-row"
                    columns={this.columns}
                    dataSource={this.props.dataSource}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event =>
                                history.push("/users/" + record.username),
                        };
                    }}
                />

                <Modal
                    title={
                        this.state.currentRecord
                            ? "User: " + this.state.currentRecord.username
                            : ""
                    }
                    visible={this.state.modalVisible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        permissions.check("USER-U") ? (
                            <Button
                                onClick={e =>
                                    history.push(
                                        "/users/" +
                                            (this.state.currentRecord
                                                ? this.state.currentRecord
                                                      .username
                                                : "") +
                                            "/update",
                                    )
                                }
                                type="ghost"
                                color="gold"
                                key="update"
                            >
                                Update
                            </Button>
                        ) : (
                            ""
                        ),
                        permissions.check("USER-D") ? (
                            <Button
                                type="danger"
                                key="delete"
                                onClick={e => this.handleDelete(e)}
                            >
                                Delete
                            </Button>
                        ) : (
                            ""
                        ),
                        <Button
                            onClick={e =>
                                history.push(
                                    "/users/" +
                                        (this.state.currentRecord
                                            ? this.state.currentRecord.username
                                            : ""),
                                )
                            }
                            type="ghost"
                            color="gold"
                            key="showProfile"
                        >
                            Show profile
                        </Button>,
                    ]}
                >
                    <p>
                        <strong>Username:</strong>{" "}
                        {this.state.currentRecord
                            ? this.state.currentRecord.username
                            : ""}
                    </p>
                    <p>
                        <strong>E-mail address:</strong>{" "}
                        {this.state.currentRecord
                            ? this.state.currentRecord.email
                            : ""}
                    </p>
                    <p>
                        <strong>Phone number:</strong>{" "}
                        {this.state.currentRecord
                            ? this.state.currentRecord.phoneNumber
                            : ""}
                    </p>
                    <p>
                        <strong>Role:</strong>{" "}
                        {this.state.currentRecord
                            ? this.state.currentRecord.role.roleName
                            : ""}
                    </p>

                    <Card
                        size="small"
                        title="Company"
                        extra={
                            <Link
                                to={
                                    "/companies/" +
                                    (this.state.currentRecord
                                        ? this.state.currentRecord.company
                                              .company
                                        : "")
                                }
                            >
                                More
                            </Link>
                        }
                    >
                        <p>
                            <strong>Name:</strong>{" "}
                            {this.state.currentRecord
                                ? this.state.currentRecord.company.companyName
                                : ""}
                        </p>
                        <p>
                            <strong>Address:</strong>{" "}
                            {this.state.currentRecord
                                ? this.state.currentRecord.company.address
                                : ""}
                        </p>
                    </Card>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
