import React, { Component } from 'react'
import { Row, Col, Card } from "antd"

export default class Info extends Component {
    render() {
        return (
            <div>
                <Row gutter={42}>
                    <Col style={{ marginBottom: 42 }} lg={12} md={24}>
                        <Card title="Basic information">
                            <p><strong>Name:</strong> {this.props.company ? this.props.company.companyName : ""}</p>
                        </Card>
                    </Col>
                    <Col style={{ marginBottom: 42 }} lg={12} md={24}>
                        <Card title="Additional information">
                            <p><strong>Address:</strong> {this.props.company ? this.props.company.address : ""}</p>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}
