import React, { Component } from "react";
import './App.css';
import history from "./history";
import { Provider } from "react-redux";
import store from "./store";
import { Router } from "react-router-dom";
import Routes from "./routes";
import 'antd/dist/antd.css';

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <div className="App">
            <Routes />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
