import React, { Component } from "react";
import { connect } from "react-redux";
import { Socket } from "../../../socket";
import { Vector as VectorSource } from "ol/source";
import Feature from "ol/Feature";
import { Point } from "ol/geom";
import { transform } from "ol/proj";
import { Style, Icon, Image } from "ol/style";
import { Vector as VectorLayer } from "ol/layer";
import axios from "axios";
import { getToken } from "../../../utils/token";
import { unknownErrorAlert, unknownErrorMessage } from "../../../utils/app";
import { permissions } from "utils/permissions";

export class EquipmentLocation extends Component {
    _socketComplete = false;
    _mapInit = false;

    iconStyle = new Style({
        image: new Icon({
            anchor: [0.5, 0.5],
            anchorXUnits: "fraction",
            anchorYUnits: "fraction",
            src: require("../../../icons/bus.png"),
            scale: 0.08,
        }),
    });

    constructor(props) {
        super(props);

        this.state = {
            source: null,
            vector: null,
        };
    }

    _isMounted = false;

    async componentDidMount() {
        this._socketComplete = false;
        this._isMounted = true;

        if (!this._socketComplete && permissions.check("DEVICE-LR"))
            this._socketEvents();
    }

    _socketEvents = () => {
        this._socketComplete = true;

        Socket.conn && Socket.conn.emit("join equipment-location");
        Socket.conn &&
            Socket.conn.on("equipment-location", data => {
                if (
                    data.device &&
                    data.location.equipment &&
                    this.props.company &&
                    data.company === this.props.company._id
                ) {
                    let feature = this.state.source.getFeatureById(
                        data.location.equipment,
                    );
                    if (feature) {
                        feature
                            .getGeometry()
                            .setCoordinates(
                                transform(
                                    [data.location.long, data.location.lat],
                                    "EPSG:4326",
                                    "EPSG:3857",
                                ),
                            );

                        feature.set("angle", data.location.heading);
                    } else {
                        let feature = new Feature({
                            name: "equipment",
                            geometry: new Point(
                                transform(
                                    [data.location.long, data.location.lat],
                                    "EPSG:4326",
                                    "EPSG:3857",
                                ),
                            ),
                        });

                        feature.set("angle", data.location.heading);
                        feature.setId(data.location.equipment);

                        if (this.props.hide) {
                            feature.setStyle(new Style({}));
                        }

                        this.state.source.addFeature(feature);
                    }
                }
            });
    };

    async componentDidUpdate(prevProps) {
        if (
            !this._socketComplete &&
            (this.props.app.socket || prevProps.app.socket)
        ) {
            this._socketEvents();
        }

        if (!this._mapInit && prevProps.map !== this.props.map) {
            this._mapInit = true;

            let source = new VectorSource({ wrapX: false });
            let vector = new VectorLayer({
                source,
                updateWhileAnimating: true,
                updateWhileInteracting: true,
                style: (feature, resolution) => {
                    this.iconStyle
                        .getImage()
                        .setScale(
                            (this.props.map.getView().getResolutionForZoom(22) /
                                resolution) *
                                1.2,
                        );
                    this.iconStyle
                        .getImage()
                        .setRotation((feature.get("angle") * Math.PI) / 180);
                    return this.iconStyle;
                },
            });

            this.props.map.addLayer(vector);
            this.setState({ source, vector });

            /**
             * Get last equipments location
             */

            if (permissions.check("DEVICE-LR")) {
                try {
                    const API_RESPONSE = await axios.get(
                        `${window.API}/devices/locations?company=${this.props.company._id}`,
                        {
                            headers: { Authorization: `Bearer ${getToken()}` },
                        },
                    );
                    if (
                        API_RESPONSE.status === 200 &&
                        API_RESPONSE.data.success
                    ) {
                        API_RESPONSE.data.locations.forEach(equipment => {
                            if (equipment.location) {
                                let feature = new Feature({
                                    name: "equipment",
                                    geometry: new Point(
                                        transform(
                                            [
                                                equipment.location.long,
                                                equipment.location.lat,
                                            ],
                                            "EPSG:4326",
                                            "EPSG:3857",
                                        ),
                                    ),
                                });
                                feature.set(
                                    "angle",
                                    equipment.location.heading,
                                );
                                feature.setId(equipment._id);
                                if (this.props.hide) {
                                    feature.setStyle(new Style({}));
                                }
                                this.state.source.addFeature(feature);
                            }
                        });
                    } else {
                        unknownErrorAlert("Requested company doesn't exists.");
                    }
                } catch (err) {
                    unknownErrorAlert();
                }
            }
        }
    }

    componentWillUnmount() {
        Socket.conn && Socket.conn.emit("leave equipment-location");
        Socket.conn && Socket.conn.removeListener("equipment-location");

        this._isMounted = false;
    }

    render() {
        return <div />;
    }
}

const mapStateToProps = state => ({
    app: state.app,
});

export default connect(mapStateToProps)(EquipmentLocation);
