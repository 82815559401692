import React, { Component } from "react";
import EquipmentList from "components/EquipmentList";
import store from "../../../../../store";
import { set } from "../../../../../_actions/appActions";
import axios from "axios";
import { getToken } from "../../../../../utils/token";
import {
    unknownErrorAlert,
    unknownErrorMessage,
} from "../../../../../utils/app";

export default class Equipment extends Component {
    state = {
        equipment: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            dataSource: [],
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getEquipment = async () => {
        try {
            this._isMounted && store.dispatch(set({ loading: true }));
            const API_RESPONSE = await axios.get(
                `${window.API}/equipment?company=${this.props.company._id}`,
                {
                    headers: { Authorization: `Bearer ${getToken()}` },
                },
            );

            if (API_RESPONSE.status === 200 && API_RESPONSE.data.success) {
                this._isMounted &&
                    this.setState({
                        equipment: API_RESPONSE.data.equipment,
                    });
            } else {
                unknownErrorAlert("Requested company doesn't exists.");
            }
        } catch (err) {
            unknownErrorAlert();
        }
        this._isMounted && store.dispatch(set({ loading: false }));
    };

    _isMounted = false;
    _setup = false;
    componentDidMount() {
        this._isMounted = true;

        if (this.props.company && this.props.company._id && !this._setup) {
            this._setup = true;
            this.getEquipment();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.company !== prevProps.company && !this._setup) {
            this._setup = true;
            this.getEquipment();
        }
    }

    render() {
        return (
            <div>
                <EquipmentList dataSource={this.state.equipment} />
            </div>
        );
    }
}
