import React, { Component } from 'react'
import PermissionsList from "components/PermissionsList"

export default class Permissions extends Component {

    render() {
        return (
            <div>
                <PermissionsList dataSource={this.props.role ? this.props.role.permissions : []} />
            </div>
        )
    }
}
