import React, { Component } from "react";
import Header from "../../../../components/Header";
import Content from "../../../../components/Content";
import store from "../../../../store";
import { set } from "../../../../_actions/appActions";
import { connect } from "react-redux";
import history from "../../../../history";
import { Button, Row, Col, message, Modal } from "antd";
import { permissions } from "../../../../utils/permissions";
import axios from "axios";
import { getToken } from "../../../../utils/token";
import { unknownErrorAlert, unknownErrorMessage } from "../../../../utils/app";

import Info from "./Tabs/Info";
import Map from "./Tabs/Map";
import Users from "./Tabs/Users";
import Roles from "./Tabs/Roles";
import Permissions from "./Tabs/Permissions";
import Equipment from "./Tabs/Equipment";

const { confirm } = Modal;

export class Company extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            companyName: null,
            company: {},
            activeTab:
                history.location.hash !== ""
                    ? history.location.hash.split("#")[1]
                    : "info",
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async getCompanyData() {
        try {
            const API_RESPONSE = await axios.get(
                `${window.API}/companies/${this.props.match.params.company}`,
                {
                    headers: { Authorization: `Bearer ${getToken()}` },
                },
            );

            if (
                API_RESPONSE.status === 200 &&
                API_RESPONSE.data.success &&
                API_RESPONSE.data.company
            ) {
                this._isMounted &&
                    this.setState({
                        companyName: API_RESPONSE.data.company.companyName,
                        company: API_RESPONSE.data.company,
                    });
            } else {
                unknownErrorAlert("Requested company doesn't exists.");
            }
        } catch (err) {
            unknownErrorAlert();
        }
    }

    async componentDidMount() {
        this._isMounted = true;
        this._isMounted && store.dispatch(set({ loading: true }));

        await this.getCompanyData();
        this._isMounted &&
            store.dispatch(
                set({
                    loading: false,
                    breadcrumb: [
                        { name: "Companies", href: "/companies" },
                        {
                            name: this.state.company.companyName,
                            href: "/companies/" + this.state.companyName,
                        },
                    ],
                }),
            );
    }

    tabChange = key => {
        this.setState({ activeTab: key });
    };

    renderTab() {
        switch (this.state.activeTab) {
            case "info":
                return <Info company={this.state.company} />;
            case "map":
                return <Map company={this.state.company} />;
            case "users":
                return <Users company={this.state.company} />;
            case "roles":
                return <Roles company={this.state.company} />;
            case "permissions":
                return <Permissions company={this.state.company} />;
            case "equipment":
                return <Equipment company={this.state.company} />;
            default:
                return <div />;
        }
    }

    async handleDelete() {
        confirm({
            title: "Are you sure delete this company?",
            content: "This operation is permament.",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: async e => {
                try {
                    const API_RESPONSE = await axios.delete(
                        `${window.API}/companies/${this.state.company._id}`,
                        {
                            headers: { Authorization: `Bearer ${getToken()}` },
                        },
                    );

                    if (
                        API_RESPONSE.status === 200 &&
                        API_RESPONSE.data.success === true
                    ) {
                        message.success("Successfully deleted the company.");
                        history.push("/companies");
                    } else {
                        message.warning(API_RESPONSE.data.message);
                    }
                } catch (err) {
                    unknownErrorMessage(err.response ? err.response.data.message : null);
                }
            },
        });
    }

    render() {
        if (!permissions.check("COMPANY-R")) return <div />;

        return (
            <div>
                <Header
                    extra={
                        <Row type="flex">
                            <Col>
                                {permissions.check("COMPANY-U-A") ||
                                (this.props.user.company._id ===
                                    this.state.company._id &&
                                    permissions.check("COMPANY-U-C")) ? (
                                    <Button
                                        onClick={e =>
                                            history.push(
                                                "/companies/" +
                                                    (this.state.company
                                                        ? this.state.company
                                                              .company + "/"
                                                        : "") +
                                                    "update",
                                            )
                                        }
                                        size="small"
                                    >
                                        Update
                                    </Button>
                                ) : (
                                    ""
                                )}
                            </Col>
                            <Col style={{ marginLeft: 12 }}>
                                {permissions.check("COMPANY-D-A") ? (
                                    <Button
                                        onClick={e => this.handleDelete()}
                                        size="small"
                                        type="danger"
                                    >
                                        Delete
                                    </Button>
                                ) : (
                                    ""
                                )}
                            </Col>
                        </Row>
                    }
                    activeTab={this.state.activeTab}
                    tabs={[
                        {
                            text: "Information",
                            key: "info",
                            onClick: this.tabChange,
                        },
                        { text: "Map", key: "map", onClick: this.tabChange },
                        permissions.check("EQ-R") && {
                            text: "Equipment",
                            key: "equipment",
                            onClick: this.tabChange,
                        },
                        permissions.oneOf(["ROLE-U", "ROLE-C"]) && {
                            text: "Permissions",
                            key: "permissions",
                            onClick: this.tabChange,
                        },
                        permissions.check("USER-R") && {
                            text: "Users",
                            key: "users",
                            onClick: this.tabChange,
                        },
                        permissions.check("ROLE-R") && {
                            text: "Roles",
                            key: "roles",
                            onClick: this.tabChange,
                        },
                    ]}
                >
                    Company: {this.state.companyName}
                </Header>
                <Content>{this.renderTab()}</Content>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
