import React, { Component } from 'react'
import { Table, Tag } from "antd"
import { translateScope } from "utils/app.js";

export default class PermissionsList extends Component {

    columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Scope",
            dataIndex: "scope",
            key: "scope",
            render: scope => <Tag>{translateScope(scope)}</Tag>
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description"
        }
    ]

    render() {
        return (
            <Table columns={this.columns} dataSource={this.props.dataSource} rowKey={record => record._id} />
        )
    }
}
