import React, { Component } from "react";
import Header from "../../../../components/Header";
import Content from "../../../../components/Content";
import store from "../../../../store";
import { set } from "../../../../_actions/appActions";
import { connect } from "react-redux";
import history from "../../../../history";
import { Button, Row, Col, message, Modal } from "antd";
import { permissions } from "../../../../utils/permissions";
import axios from "axios";
import { getToken } from "../../../../utils/token";
import { unknownErrorAlert, unknownErrorMessage } from "../../../../utils/app";

import Info from "./Tabs/Info";

const { confirm } = Modal;

export class User extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            username: null,
            user: {},
            activeTab:
                history.location.hash !== ""
                    ? history.location.hash.split("#")[1]
                    : "info",
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidMount() {
        this._isMounted = true;
        this._isMounted && store.dispatch(set({ loading: true }));

        try {
            const API_RESPONSE = await axios.get(
                `${window.API}/users/${this.props.match.params.user}`,
                {
                    headers: { Authorization: `Bearer ${getToken()}` },
                },
            );

            if (
                API_RESPONSE.status === 200 &&
                API_RESPONSE.data.success &&
                API_RESPONSE.data.user &&
                API_RESPONSE.data.user.username
            ) {
                this._isMounted &&
                    this.setState({
                        username: API_RESPONSE.data.user.username,
                        user: API_RESPONSE.data.user,
                    });

                this._isMounted &&
                    store.dispatch(
                        set({
                            loading: false,
                            breadcrumb: [
                                { name: "Users", href: "/users" },
                                {
                                    name: this.state.user.username,
                                    href: "/users/" + this.state.username,
                                },
                            ],
                        }),
                    );
            } else {
                unknownErrorAlert("Requested user doesn't exists.");
            }
        } catch (err) {
            unknownErrorAlert();
        }
    }

    tabChange = key => {
        this.setState({ activeTab: key });
    };

    renderTab() {
        switch (this.state.activeTab) {
            case "info":
                return <Info user={this.state.user} />;
            default:
                return <div />;
        }
    }

    async handleDelete(e) {
        confirm({
            title: "Are you sure delete this user?",
            content: "This operation is permament.",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: async e => {
                try {
                    const API_RESPONSE = await axios.delete(
                        `${window.API}/users/${this.state.user._id}`,
                        {
                            headers: { Authorization: `Bearer ${getToken()}` },
                        },
                    );

                    if (
                        API_RESPONSE.status === 200 &&
                        API_RESPONSE.data.success === true
                    ) {
                        message.success("Successfully deleted the user.");

                        history.push("/users");
                    } else {
                        message.warning(API_RESPONSE.data.message);
                    }
                } catch (err) {
                    unknownErrorMessage(err.response ? err.response.data.message : null);
                }
            },
        });
    }

    render() {
        if (!permissions.check("USER-R")) return <div />;

        return (
            <div>
                <Header
                    extra={
                        <Row type="flex">
                            <Col>
                                {permissions.check("USER-U-A") ||
                                (permissions.check("USER-U-C") &&
                                    this.state.user.company &&
                                    this.state.user.company._id ===
                                        this.props.user.company._id) ? (
                                    <Button
                                        onClick={e =>
                                            history.push(
                                                "/users/" +
                                                    (this.state.user
                                                        ? this.state.user
                                                              .username + "/"
                                                        : "") +
                                                    "update",
                                            )
                                        }
                                        size="small"
                                    >
                                        Update
                                    </Button>
                                ) : (
                                    ""
                                )}
                            </Col>
                            <Col style={{ marginLeft: 12 }}>
                                {permissions.check("USER-D-A") ||
                                (permissions.check("USER-D-C") &&
                                    this.state.user.company &&
                                    this.state.user.company._id ===
                                        this.props.user.company._id) ? (
                                    <Button
                                        onClick={e => this.handleDelete()}
                                        size="small"
                                        type="danger"
                                    >
                                        Delete
                                    </Button>
                                ) : (
                                    ""
                                )}
                            </Col>
                        </Row>
                    }
                    activeTab={this.state.activeTab}
                    tabs={[
                        {
                            text: "Information",
                            key: "info",
                            onClick: this.tabChange,
                        },
                    ]}
                >
                    User: {this.state.username}
                </Header>
                <Content>{this.renderTab()}</Content>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(User);
