import React, { Component } from "react";
import moment from "moment";

export default class Timer extends Component {
    state = {
        time: moment(new Date() - new Date(this.props.initial)).format(
            this.props.format ? this.props.format : "mm:ss"
        )
    };

    _interval = null;
    componentDidMount() {
        this._interval = setInterval(() => {
            this.setState({
                time: moment(new Date() - new Date(this.props.initial)).format(
                    this.props.format ? this.props.format : "mm:ss"
                )
            });
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this._interval);
    }

    render() {
        return <span>{this.state.time}</span>;
    }
}
