export const saveToken = token => {
    localStorage.setItem("jwt-token", token);
};

export const getToken = () => {
    return localStorage.getItem("jwt-token");
};

export const removeToken = () => {
    localStorage.removeItem("jwt-token");
};
