import React, { Component } from "react";
import { Row, Col, Card } from "antd";
import moment from "moment";

export default class Info extends Component {
    render() {
        return (
            <div>
                <Row gutter={42}>
                    <Col style={{ marginBottom: 42 }} lg={12} md={24}>
                        <Card title="Equipment basic">
                            <p>
                                <strong>Company: </strong>
                                {this.props.eq &&
                                    this.props.eq.company.companyName}
                            </p>
                            <p>
                                <strong>Type: </strong>
                                {this.props.eq && this.props.eq.type.name}
                            </p>
                            <p>
                                <strong>Manufacture: </strong>
                                {this.props.eq &&
                                    this.props.eq.manufacture.name}
                            </p>
                            <p>
                                <strong>Model: </strong>
                                {this.props.eq && this.props.eq.model.name}
                            </p>
                        </Card>
                    </Col>
                    <Col style={{ marginBottom: 42 }} lg={12} md={24}>
                        <Card title="More information">
                            <p>
                                <strong>Number plate: </strong>
                                {this.props.eq && this.props.eq.numberPlate}
                            </p>
                        </Card>
                    </Col>
                    {this.props.eq && this.props.eq.device.length === 1 && (
                        <Col style={{ marginBottom: 42 }} lg={12} md={24}>
                            <Card title="Registered device">
                                <p>
                                    <strong>Name: </strong>
                                    {this.props.eq &&
                                        this.props.eq.device[0].deviceInfo.name}
                                </p>
                                <p>
                                    <strong>Platform: </strong>
                                    {this.props.eq &&
                                        this.props.eq.device[0].deviceInfo
                                            .platform}
                                </p>
                                <p>
                                    <strong>Register date: </strong>
                                    {this.props.eq &&
                                        moment(
                                            this.props.eq.device[0].createDate
                                        ).format("Do MMMM YYYY, HH:mm")}
                                </p>
                            </Card>
                        </Col>
                    )}
                </Row>
            </div>
        );
    }
}
