import { getToken } from "./token";
import axios from "axios";

export const getUser = () => {
    return new Promise(function(resolve, reject) {
        const token = getToken();

        if (!token) resolve({ success: false });

        axios
            .get(`${window.API}/users/me`, {
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err);
            });
    });
};
