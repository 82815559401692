import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { permissions } from "../../../utils/permissions";

import Account from "./Index/index.js";

export default class index extends Component {
    render() {
        if (!permissions.oneOf(["USER-R-O", "USER-U-O"]))
            return <Redirect to="/" />;

        return (
            <div>
                <Switch>
                    <Route exact path="/account" component={Account} />
                </Switch>
            </div>
        );
    }
}
