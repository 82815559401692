import React, { Component } from "react";
import { Table, Modal, Icon } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { permissions } from "../utils/permissions";
import history from "../history";
import Timer from "components/Timer";
import TripPath from "components/Map/TripPath";

export class TripsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSource: [],
            modalVisible: false,
            currentRecord: null,
        };

        this.columns = [
            {
                title: "",
                dataIndex: "info",
                key: "info",
                render: (el, record) => {
                    return (
                        <Icon
                            style={{ fontSize: 18 }}
                            onClick={e => this.openModal(e, record)}
                            type="info-circle"
                            theme="twoTone"
                        />
                    );
                },
            },
            {
                title: "Driver",
                dataIndex: "driver",
                key: "driver",
                render: driver =>
                    driver.fullName ? driver.fullName : driver.username,
            },
            {
                title: "Requested by",
                dataIndex: "requester",
                key: "requester",
                render: requester =>
                    requester.fullName
                        ? requester.fullName
                        : requester.username,
            },
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                filters: [
                    {
                        text: "Finished",
                        value: "finished",
                    },
                    {
                        text: "In progress",
                        value: "accepted",
                    },
                    {
                        text: "Rejected",
                        value: "rejected",
                    },
                    {
                        text: "Cancelled",
                        value: "cancelled",
                    },
                    {
                        text: "Pending",
                        value: "pending",
                    },
                ],
                onFilter: (value, record) => record.currentStatus === value,
                render: status => {
                    return status[status.length - 1].title;
                },
            },
            {
                title: "Pickup spot",
                dataIndex: "spot",
                key: "spot",
                render: spot => spot.name,
            },
            {
                title: "Company",
                dataIndex: "company",
                key: "company",
                render: company => (company ? company.companyName : ""),
            },
            {
                title: "Duration",
                dataIndex: "duration",
                key: "duration",
                render: (a, record) => {
                    if (record.currentStatus === "finished") {
                        const difference = moment(
                            new Date(record.finishTimestamp),
                        ).diff(new Date(record.createDate), "seconds");

                        return moment("2015-01-01")
                            .startOf("day")
                            .seconds(difference)
                            .format(difference > 3600 ? "H:mm:ss" : "mm:ss");
                    } else if (record.currentStatus === "pending") {
                        return <Timer initial={record.createDate} />;
                    } else if (record.currentStatus === "accepted") {
                        return (
                            <Timer initial={record.acceptOrRejectTimestamp} />
                        );
                    } else {
                        const difference = moment(
                            new Date(record.acceptOrRejectTimestamp),
                        ).diff(new Date(record.createDate), "seconds");

                        return moment("2015-01-01")
                            .startOf("day")
                            .seconds(difference)
                            .format(difference > 3600 ? "H:mm:ss" : "mm:ss");
                    }
                },
            },
            {
                title: "Request date",
                dataIndex: "createDate",
                key: "createDate",
                render: date => (
                    <span>{moment(date).format("D MMMM Y HH:mm")}</span>
                ),
            },
        ];
    }

    openModal(e, record) {
        e.stopPropagation();

        this.setState({ modalVisible: true, currentRecord: record });
    }

    rowClick(record) {
        this.setState({ modalVisible: true, currentRecord: record });
    }

    handleOk = e => {
        this.setState({
            modalVisible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            modalVisible: false,
        });
    };

    render() {
        if (!permissions.check("TRIP-R")) return <div />;

        return (
            <div>
                <Table
                    showSearch={true}
                    rowKey={record => record._id}
                    filterOption={(inputValue, item) =>
                        item.name
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) !== -1 ||
                        item.description
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) !== -1
                    }
                    rowClassName="table-row"
                    columns={this.columns}
                    dataSource={this.props.dataSource}
                    onRow={(record, rowIndex) => {
                        // return {
                        //     onClick: event =>
                        //         history.push("/trips/" + record._id),
                        // };
                    }}
                />

                <Modal
                    title={
                        this.state.currentRecord
                            ? "Trip to " + this.state.currentRecord.spot.name
                            : ""
                    }
                    visible={this.state.modalVisible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <p>
                        <strong>Requested by: </strong>
                        {permissions.oneOf(["USER-R-C", "USER-R-A"]) ? (
                            <Link
                                to={`/users/${this.state.currentRecord &&
                                    this.state.currentRecord.requester
                                        .username}`}
                            >
                                {this.state.currentRecord &&
                                    this.state.currentRecord.requester.fullName}
                            </Link>
                        ) : (
                            this.state.currentRecord &&
                            this.state.currentRecord.requester.fullName
                        )}
                    </p>
                    <p>
                        <strong>Driver: </strong>
                        {permissions.oneOf(["USER-R-C", "USER-R-A"]) ? (
                            <Link
                                to={`/users/${this.state.currentRecord &&
                                    this.state.currentRecord.driver.username}`}
                            >
                                {this.state.currentRecord &&
                                    this.state.currentRecord.driver.fullName}
                            </Link>
                        ) : (
                            this.state.currentRecord &&
                            this.state.currentRecord.driver.fullName
                        )}
                    </p>
                    <p>
                        <strong>Status: </strong>
                        {this.state.currentRecord &&
                            this.state.currentRecord.status[
                                this.state.currentRecord.status.length - 1
                            ].title}{" "}
                        <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                            (
                            {this.state.currentRecord &&
                                this.state.currentRecord.status[
                                    this.state.currentRecord.status.length - 1
                                ].description}
                            )
                        </span>
                    </p>
                    <p>
                        <strong>Pickup spot: </strong>
                        {this.state.currentRecord &&
                            this.state.currentRecord.spot.name}
                    </p>
                    <p>
                        <strong>Request date: </strong>
                        {this.state.currentRecord &&
                            moment(this.state.currentRecord.createDate).format(
                                "D MMMM Y HH:mm",
                            )}
                    </p>
                    <p>
                        <strong>Accept/reject date: </strong>
                        {this.state.currentRecord &&
                            moment(
                                this.state.currentRecord
                                    .acceptOrRejectTimestamp,
                            ).format("D MMMM Y HH:mm")}
                    </p>
                    {this.state.currentRecord &&
                        this.state.currentRecord.currentStatus ===
                            "finished" && (
                            <p>
                                <strong>Finish date: </strong>
                                {moment(
                                    this.state.currentRecord.finishTimestamp,
                                ).format("D MMMM Y HH:mm")}
                            </p>
                        )}

                    <TripPath
                        trip={this.state.currentRecord}
                        path={
                            this.state.currentRecord &&
                            this.state.currentRecord.path
                        }
                        airport={
                            this.state.currentRecord &&
                            this.state.currentRecord.company.airport
                        }
                    />
                </Modal>
            </div>
        );
    }
}

export default TripsList;
