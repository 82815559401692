import React, { Component } from 'react'

import PermissionsList from "components/PermissionsList";

export default class Roles extends Component {
    render() {
        return (
            <div>
                <PermissionsList dataSource={this.props.company.permissions} />
            </div>
        )
    }
}
