import React, { Component } from 'react'
import styled from "styled-components"
import { Breadcrumb, Row, Col, Menu } from "antd";
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import history from "../history"

const StyledHeader = styled.div`
    padding: 24px 42px;
    background: #f9f9f9;
`;

export class Header extends Component {

    getUrl(breadcrumb, index) {
        if (breadcrumb[index].href.includes("@prev")) {
            return this.getUrl(breadcrumb, index - 1) + breadcrumb[index].href.split("@prev")[1];
        } else return breadcrumb[index].href;
    }

    generateBreadcrumb(breadcrumb) {

        return (
            breadcrumb.map((crumb, index) => {
                if (crumb.name) {
                    if (index + 1 === breadcrumb.length)
                        return <Breadcrumb.Item key={crumb.name}>{crumb.name}</Breadcrumb.Item>
                    else return <Breadcrumb.Item key={crumb.name}><Link to={this.getUrl(breadcrumb, index)}>{crumb.name}</Link></Breadcrumb.Item>
                } else return null;
            })
        )
    }

    handleTabClick(tab) {

        if (!this.props.addTabToUrl) history.push("#" + tab.key)
        tab.onClick(tab.key)
    }

    render() {
        return (
            <StyledHeader style={{ paddingBottom: this.props.tabs ? 0 : "" }}>
                <Breadcrumb>
                    <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                    {
                        this.props.app.breadcrumb ? this.generateBreadcrumb(this.props.app.breadcrumb) : ""
                    }

                </Breadcrumb>

                <Row style={{ marginTop: ".8em", marginBottom: ".4em" }} type="flex" justify="space-between" align="middle">
                    <Col lg={this.props.buttons ? 12 : 24} md={24}>
                        <h1 style={{ fontSize: "2em", fontWeight: 600, marginBottom: 0 }}>{this.props.children}</h1>
                    </Col>
                    {this.props.buttons ? <Col align="right" lg={12} md={24} style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end' }}>{this.props.buttons}</Col> : ""}
                </Row>
                {this.props.extra ? <div style={{ marginBottom: 12 }}>{this.props.extra}</div> : ""}

                {this.props.tabs ? (
                    <Row>
                        <Menu mode="horizontal" selectedKeys={[this.props.activeTab.toString()]} style={{ background: 'transparent' }}>
                            {this.props.tabs.map((tab, index) => {
                                if (tab.key) {
                                    return (
                                        <Menu.Item key={tab.key} onClick={e => this.handleTabClick(tab)}>
                                            {tab.text}
                                        </Menu.Item>
                                    )
                                } else return false
                            })}
                        </Menu>
                    </Row>
                ) : ""}
            </StyledHeader >
        )
    }
}

const mapStateToProps = (state) => ({
    app: state.app
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Header)