import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { setUser } from "../_actions/userActions";
import store from "../store";
import { getUser } from "../utils/account";
import { removeToken } from "../utils/token";
import { Socket } from "../socket";
import { notification, Icon } from "antd";

export class Auth extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            authenticated: false,
        };
    }

    async componentDidMount() {
        try {
            let data = await getUser();

            if (data.success && data.user._id !== undefined) {
                await store.dispatch(setUser(data.user));

                /**
                 * Socket.io
                 */

                Socket.connect();

                this.setState({ authenticated: true, loading: false });
            } else this.setState({ authenticated: false, loading: false });
        } catch (err) {
            this.setState({ authenticated: false, loading: false });
        }
    }

    _socketComplete = false;
    componentWillReceiveProps(nextProps) {
        /**
         * Init events listeners
         */

        if (
            !this._socketComplete &&
            (this.props.app.socket || nextProps.app.socket)
        ) {
            this._socketComplete = true;

            Socket.conn &&
                Socket.conn.on("notification", data => {
                    console.log(data);
                    const { message, description, icon } = data;

                    notification.open({
                        message,
                        description,
                        icon: <Icon type={icon} style={{ color: "#108ee9" }} />,
                    });
                });

            Socket.conn &&
                Socket.conn.on("logout", () => {
                    removeToken();
                    window.location.reload();
                });
        }
    }

    componentWillUnmount() {
        Socket.conn && Socket.conn.removeListener("notification");
        Socket.conn && Socket.conn.removeListener("logout");
    }

    render() {
        if (this.state.loading) return <div />;
        if (this.state.authenticated) return <div>{this.props.children}</div>;
        else {
            return <Redirect to="/login" />;
        }
    }
}

const mapStateToProps = state => ({
    app: state.app,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
