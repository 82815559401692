import React, { Component } from 'react'
import styled from "styled-components"

const Container = styled.div`
    padding: 16px 42px;
    margin-top: 16px;
`;

export default class Content extends Component {
    render() {
        return (
            <Container>
                {this.props.children}
            </Container>
        )
    }
}
