import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { permissions } from "../../../utils/permissions";

import Add from "./Add/index.js";
import Index from "./Index/index.js";
import Types from "./Types/index.js";
import Manufactures from "./Manufactures";
import Models from "./Models/index.js";
import Update from "./Update";
import Equipment from "./Equipment";

export default class index extends Component {
    render() {
        if (!permissions.oneOf(["EQ-C", "EQ-R", "EQ-U", "EQ-D"]))
            return <Redirect to="/" />;

        return (
            <div>
                <Switch>
                    <Route exact path="/equipment" component={Index} />
                    <Route exact path="/equipment/types" component={Types} />
                    <Route
                        exact
                        path="/equipment/manufactures"
                        component={Manufactures}
                    />
                    <Route exact path="/equipment/models" component={Models} />
                    <Route exact path="/equipment/add" component={Add} />
                    <Route
                        exact
                        path="/equipment/:company/:eq"
                        component={Equipment}
                    />
                    <Route
                        path="/equipment/:company/:eq/update"
                        component={Update}
                    />
                </Switch>
            </div>
        );
    }
}
