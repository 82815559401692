import React, { Component } from 'react'

import RolesList from "components/RolesList";

export default class Roles extends Component {

    constructor(props) {
        super(props)

        this.state = {
            dataSource: []
        }

    }

    componentDidMount() {

    }


    render() {
        return (
            <div>
                <RolesList dataSource={this.props.company.roles} />
            </div>
        )
    }
}
