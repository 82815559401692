import React, { Component } from 'react'
import UsersList from "components/UsersList";

export default class Users extends Component {
    render() {
        return (
            <div>
                <UsersList dataSource={this.props.role.users} />
            </div>
        )
    }
}
