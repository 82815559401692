export default function (state = { loading: false, socket: false }, action) {
    switch (action.type) {
        case "SET":
            return {
                ...state,
                ...action.payload
            }
        default: return state;
    }
}
