import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../../../../components/Header";
import Content from "../../../../components/Content";
import difference from "lodash/difference";
import { ChromePicker } from "react-color";
import store from "../../../../store";
import { set } from "../../../../_actions/appActions";
import { permissions } from "../../../../utils/permissions";
import axios from "axios";
import { getToken } from "../../../../utils/token";
import history from "../../../../history";
import { unknownErrorAlert, unknownErrorMessage } from "../../../../utils/app";

import {
    Form,
    Input,
    Select,
    Row,
    Col,
    Transfer,
    Card,
    Table,
    Tag,
    Button,
    message,
} from "antd";

const { Option } = Select;

const TableTransfer = ({
    leftColumns,
    rightColumns,
    loading,
    ...restProps
}) => (
    <Transfer {...restProps} showSelectAll={false}>
        {({
            direction,
            filteredItems,
            onItemSelectAll,
            onItemSelect,
            selectedKeys: listSelectedKeys,
            disabled: listDisabled,
        }) => {
            const columns = direction === "left" ? leftColumns : rightColumns;

            const rowSelection = {
                getCheckboxProps: item => ({
                    disabled: listDisabled || item.disabled,
                }),
                onSelectAll(selected, selectedRows) {
                    const treeSelectedKeys = selectedRows
                        .filter(item => !item.disabled)
                        .map(({ key }) => key);
                    const diffKeys = selected
                        ? difference(treeSelectedKeys, listSelectedKeys)
                        : difference(listSelectedKeys, treeSelectedKeys);
                    onItemSelectAll(diffKeys, selected);
                },
                onSelect({ key }, selected) {
                    onItemSelect(key, selected);
                },
                selectedRowKeys: listSelectedKeys,
            };

            return (
                <Table
                    loading={loading}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={filteredItems}
                    size="small"
                    style={{ pointerEvents: listDisabled ? "none" : null }}
                    onRow={({ key, disabled: itemDisabled }) => ({
                        onClick: () => {
                            if (itemDisabled || listDisabled) return;
                            onItemSelect(key, !listSelectedKeys.includes(key));
                        },
                    })}
                />
            );
        }}
    </Transfer>
);

const tableColumns = [
    {
        dataIndex: "name",
        title: "Name",
    },
    {
        dataIndex: "scope",
        title: "Scope",
        render: scope => <Tag>{scope}</Tag>,
    },
    {
        dataIndex: "description",
        title: "Descriptions",
    },
];

export class Update extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            targetKeys: [],
            dataSource: [],
            companiesData: [],
            role: null,
            roleName: null,
            color: "#ffffff",
            showColorPicker: false,
            data: {},
            changes: false,
            loadingButton: false,
            company: null,
            permissionsLoading: false,
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidMount() {
        this._isMounted = true;

        let company = this.props.match.params.company;
        let roleToFetch = this.props.match.params.role;

        this._isMounted && store.dispatch(set({ loading: true }));

        if (!permissions.check("ROLE-C-A")) {
            try {
                const API_RESPONSE = await axios.get(
                    `${window.API}/permissions?company=${this.props.user.company._id}`,
                    {
                        headers: { Authorization: `Bearer ${getToken()}` },
                    },
                );

                if (API_RESPONSE.status === 200 && API_RESPONSE.data.success) {
                    this._isMounted &&
                        this.setState({
                            dataSource: API_RESPONSE.data.permissions,
                        });
                } else unknownErrorMessage(API_RESPONSE.data.message);

                this._isMounted &&
                    this.setState({ company: this.props.user.company._id });
                this._isMounted && store.dispatch(set({ loading: false }));
            } catch (err) {
                unknownErrorAlert();
            }
        } else {
            try {
                const API_RESPONSE_COMPANIES = await axios.get(
                    `${window.API}/companies`,
                    {
                        headers: { Authorization: `Bearer ${getToken()}` },
                    },
                );

                const API_RESPONSE = await axios.get(
                    `${window.API}/permissions?company=${company}`,
                    {
                        headers: { Authorization: `Bearer ${getToken()}` },
                    },
                );

                if (
                    API_RESPONSE_COMPANIES.status === 200 &&
                    API_RESPONSE_COMPANIES.data.success
                ) {
                    this._isMounted &&
                        this.setState({
                            companiesData:
                                API_RESPONSE_COMPANIES.data.companies,
                        });
                }

                if (API_RESPONSE.status === 200 && API_RESPONSE.data.success) {
                    this._isMounted &&
                        this.setState({
                            dataSource: API_RESPONSE.data.permissions,
                        });
                } else unknownErrorMessage(API_RESPONSE.data.message);
            } catch (err) {
                unknownErrorAlert();
            }
        }

        try {
            const API_RESPONSE_ROLE = await axios.get(
                `${window.API}/roles/${roleToFetch}?company=${company}`,
                {
                    headers: { Authorization: `Bearer ${getToken()}` },
                },
            );

            if (
                API_RESPONSE_ROLE.status === 200 &&
                API_RESPONSE_ROLE.data.success &&
                API_RESPONSE_ROLE.data.role
            ) {
                this._isMounted &&
                    this.setState({
                        data: API_RESPONSE_ROLE.data.role,
                        company: API_RESPONSE_ROLE.data.role.company._id,
                        role: API_RESPONSE_ROLE.data.role.role,
                        roleName: API_RESPONSE_ROLE.data.role.roleName,
                        color: API_RESPONSE_ROLE.data.role.color,
                        targetKeys: API_RESPONSE_ROLE.data.role.permissions.map(
                            permission => permission._id,
                        ),
                    });
            } else unknownErrorAlert(API_RESPONSE_ROLE.data.message);
        } catch (err) {
            unknownErrorAlert();
        }

        this._isMounted &&
            store.dispatch(
                set({
                    loading: false,
                    breadcrumb: [
                        { name: "Roles", href: "/roles" },
                        {
                            name: this.state.data.roleName,
                            href:
                                "@prev/" +
                                this.state.data.company.company +
                                "/" +
                                this.state.data.role,
                        },
                        { name: "Update", href: "@prev/update" },
                    ],
                }),
            );
    }

    async companyChange(value) {
        this.setState({ company: value, permissionsLoading: true });

        try {
            const API_RESPONSE = await axios.get(
                `${window.API}/permissions?company=${value}`,
                {
                    headers: { Authorization: `Bearer ${getToken()}` },
                },
            );

            if (API_RESPONSE.status === 200 && API_RESPONSE.data.success) {
                this.setState({
                    dataSource: API_RESPONSE.data.permissions,
                    permissionsLoading: false,
                    targetKeys: this.state.targetKeys.filter(
                        key =>
                            API_RESPONSE.data.permissions.findIndex(
                                perm => perm._id === key,
                            ) !== -1 && key,
                    ),
                });
            } else unknownErrorMessage(API_RESPONSE.data.message);
        } catch (err) {
            unknownErrorMessage(
                err.response ? err.response.data.message : null,
            );
        }
    }

    inputChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
            changes: true,
        });
    }

    onChange = nextTargetKeys => {
        this.setState({ targetKeys: nextTargetKeys, changes: true });
    };

    async handleUpdate(e) {
        e.preventDefault();

        // Check all inputs
        if (
            this.state.role === null ||
            this.state.roleName === null ||
            this.state.color === null
        ) {
            message.warning("You must fill all fields!");
            return;
        }

        if (!/^[a-z0-9-_]+$/i.test(this.state.role)) {
            message.warning(
                "The role field can contain only alphanumeric characters with the exception of undescores and dashes.",
            );
            return;
        }

        if (this.state.role.length > 32 || this.state.roleName.length > 32) {
            message.warning(
                "The role and role name fields must be no longer than 32 characters.",
            );
            return;
        }

        if (
            (this.state.color.length !== 4 && this.state.color.length !== 7) ||
            this.state.color[0] !== "#"
        ) {
            message.warning("The color you've inserted is incorrect.");
            return;
        }

        const REQUEST_DATA = {
            role: this.state.role,
            roleName: this.state.roleName,
            permissions: this.state.targetKeys,
            company: this.state.company,
            color: this.state.color,
        };

        if (this.state.changes) {
            this.setState({ loadingButton: true });

            try {
                const API_RESPONSE = await axios.put(
                    `${window.API}/roles/${this.state.data._id}`,
                    REQUEST_DATA,
                    {
                        headers: { Authorization: `Bearer ${getToken()}` },
                    },
                );

                this.setState({ loadingButton: false });

                if (API_RESPONSE.data.success && API_RESPONSE.status === 200) {
                    const selectedCompany = this.state.companiesData.find(
                        company => company._id == this.state.company,
                    );
                    store.dispatch(
                        set({
                            loading: false,
                            breadcrumb: [
                                { name: "Roles", href: "/roles" },
                                {
                                    name: this.state.roleName,
                                    href:
                                        "@prev/" +
                                        selectedCompany.company +
                                        "/" +
                                        this.state.role,
                                },
                                { name: "Update", href: "@prev/update" },
                            ],
                        }),
                    );

                    message.success("Successfully added new company!");

                    history.push(
                        "/roles/" +
                            selectedCompany.company +
                            "/" +
                            this.state.role +
                            "/update",
                    );
                } else {
                    message.warning(API_RESPONSE.data.message);
                }
            } catch (err) {
                unknownErrorMessage(
                    err.response ? err.response.data.message : null,
                );
            }
        }
    }

    render() {
        if (!permissions.check("ROLE-U")) return <div />;

        return (
            <div>
                <Form onSubmit={e => this.handleUpdate(e)} method="POST">
                    <Header
                        buttons={
                            <Button
                                disabled={!this.state.changes}
                                loading={this.state.loadingButton}
                                htmlType="submit"
                            >
                                Update role
                            </Button>
                        }
                        style={{ paddingBottom: 12 }}
                    >
                        Update role:{" "}
                        {this.state.data ? this.state.data.roleName : ""}
                    </Header>

                    <Content>
                        <Row gutter={42}>
                            <Col lg={12} md={24} style={{ marginBottom: 42 }}>
                                <Card title="Basic information">
                                    {permissions.check("ROLE-C-A") ? (
                                        <Form.Item label="Company">
                                            <Select
                                                value={this.state.company}
                                                defaultValue={
                                                    this.state.company
                                                }
                                                placeholder="Choose company"
                                                name="company"
                                                onChange={value =>
                                                    this.companyChange(value)
                                                }
                                                required
                                            >
                                                {this.state.companiesData.map(
                                                    (company, index) => (
                                                        <Option
                                                            key={
                                                                "company-" +
                                                                index
                                                            }
                                                            value={company._id}
                                                        >
                                                            {
                                                                company.companyName
                                                            }
                                                        </Option>
                                                    ),
                                                )}
                                            </Select>
                                        </Form.Item>
                                    ) : (
                                        ""
                                    )}
                                    <Form.Item label="Role">
                                        <Input
                                            name="role"
                                            value={this.state.role}
                                            onChange={e => this.inputChange(e)}
                                            placeholder="Short name without spaces, e.g. admin"
                                        />
                                    </Form.Item>
                                    <Form.Item label="Role name">
                                        <Input
                                            name="roleName"
                                            value={this.state.roleName}
                                            onChange={e => this.inputChange(e)}
                                            placeholder="Role name e.g. Administrator"
                                        />
                                    </Form.Item>
                                </Card>
                            </Col>
                            <Col lg={12} md={24} style={{ marginBottom: 42 }}>
                                <Card title="Additional information">
                                    <Form.Item label="Color">
                                        <Input
                                            name="color"
                                            onChange={e => this.inputChange(e)}
                                            placeholder="Displaying color"
                                            value={this.state.color}
                                            onFocus={e =>
                                                this.setState({
                                                    showColorPicker: true,
                                                })
                                            }
                                        />
                                        {this.state.showColorPicker ? (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    zIndex: 99,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: "fixed",
                                                        top: 0,
                                                        left: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                    }}
                                                    onClick={e =>
                                                        this.setState({
                                                            showColorPicker: false,
                                                        })
                                                    }
                                                />
                                                <ChromePicker
                                                    color={this.state.color}
                                                    onChange={color =>
                                                        this.setState({
                                                            color: color.hex,
                                                            changes: true,
                                                        })
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </Form.Item>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ marginBottom: 42 }}>
                                <Card title="Permissions">
                                    <Form.Item label="Permissions">
                                        <TableTransfer
                                            rowKey={record => record._id}
                                            dataSource={this.state.dataSource}
                                            targetKeys={this.state.targetKeys}
                                            showSearch={true}
                                            onChange={this.onChange}
                                            filterOption={(inputValue, item) =>
                                                item.name
                                                    .toLowerCase()
                                                    .indexOf(
                                                        inputValue.toLowerCase(),
                                                    ) !== -1 ||
                                                item.description
                                                    .toLowerCase()
                                                    .indexOf(
                                                        inputValue.toLowerCase(),
                                                    ) !== -1
                                            }
                                            leftColumns={tableColumns}
                                            rightColumns={tableColumns}
                                        />
                                    </Form.Item>
                                </Card>
                            </Col>
                        </Row>
                    </Content>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Update);
