import React, { Component } from "react";
import Header from "../../../../components/Header";
import Content from "../../../../components/Content";
import store from "../../../../store";
import { set } from "../../../../_actions/appActions";
import { connect } from "react-redux";
import history from "../../../../history";
import { Button, Row, Col, message, Modal } from "antd";
import { permissions } from "../../../../utils/permissions";
import axios from "axios";
import { getToken } from "../../../../utils/token";
import { unknownErrorAlert, unknownErrorMessage } from "../../../../utils/app";
import RegisterModal from "./RegisterModal";
import { Socket } from "../../../../socket";

import Info from "./Tabs/Info";

const { confirm } = Modal;

export class User extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            registerModal: false,
            eq: null,
            activeTab:
                history.location.hash !== ""
                    ? history.location.hash.split("#")[1]
                    : "info",
        };
    }

    _socketComplete = false;
    componentDidUpdate(prevProps, prevState) {
        if (
            !this._socketComplete &&
            (this.props.app.socket || prevProps.app.socket)
        ) {
            this._socketComplete = true;

            Socket.conn && Socket.conn.emit("join device-register-change");
            Socket.conn &&
                Socket.conn.on("device-register-change", data => {
                    this.setState({
                        registerModal: false,
                        eq: {
                            ...this.state.eq,
                            device:
                                data.type === "register" ? [data.device] : [],
                            registerKey: null,
                        },
                    });
                });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;

        Socket.conn && Socket.conn.emit("leave device-register-change");
        Socket.conn && Socket.conn.removeListener("device-register-change");
    }

    async componentDidMount() {
        this._isMounted = true;
        this._isMounted && store.dispatch(set({ loading: true }));

        try {
            const API_RESPONSE = await axios.get(
                `${window.API}/equipment/${this.props.match.params.eq}`,
                {
                    headers: { Authorization: `Bearer ${getToken()}` },
                },
            );

            if (API_RESPONSE.status === 200 && API_RESPONSE.data.success) {
                this._isMounted &&
                    this.setState({
                        eq: API_RESPONSE.data.equipment,
                    });

                this._isMounted &&
                    store.dispatch(
                        set({
                            loading: false,
                            breadcrumb: [
                                { name: "Equipment", href: "/equipment" },
                                {
                                    name:
                                        (this.state.eq &&
                                            this.state.eq.manufacture.name) +
                                        " " +
                                        (this.state.eq &&
                                            this.state.eq.model.name),
                                    href:
                                        "/equipment/" + this.state.eq &&
                                        this.state.eq.company.company +
                                            "/" +
                                            this.state.eq &&
                                        this.state.eq._id,
                                },
                            ],
                        }),
                    );
            } else {
                unknownErrorAlert("Requested equipment doesn't exists.");
            }
        } catch (err) {
            unknownErrorAlert();
        }
    }

    tabChange = key => {
        this.setState({ activeTab: key });
    };

    renderTab() {
        switch (this.state.activeTab) {
            case "info":
                return <Info eq={this.state.eq && this.state.eq} />;
            default:
                return <div />;
        }
    }

    handleRegister = async () => {
        this.setState({ registerModal: true });
    };

    handleUnregister = async () => {
        confirm({
            title:
                "Are you sure you want to unregister device connected to this equipment?",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: async e => {
                try {
                    const API_RESPONSE_UR = await axios.post(
                        `${window.API}/devices/unregister`,
                        {
                            deviceId:
                                this.state.eq &&
                                this.state.eq.device &&
                                this.state.eq.device[0].deviceId,
                        },
                        {
                            headers: { Authorization: `Bearer ${getToken()}` },
                        },
                    );

                    if (
                        API_RESPONSE_UR.data.success &&
                        API_RESPONSE_UR.status === 200
                    ) {
                        message.success(
                            "Successfully unregistered the device!",
                        );
                        this.setState({ eq: { ...this.state.eq, device: [] } });
                    } else {
                        message.warning(API_RESPONSE_UR.data.message);
                    }
                } catch (err) {
                    unknownErrorMessage(
                        err.response ? err.response.data.message : null,
                    );
                }
            },
        });
    };

    async handleDelete() {
        confirm({
            title: "Are you sure delete this equipment?",
            content: "This operation is permament.",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: async e => {
                try {
                    const API_RESPONSE = await axios.delete(
                        `${window.API}/equipment/${this.state.eq._id}`,
                        {
                            headers: { Authorization: `Bearer ${getToken()}` },
                        },
                    );

                    if (
                        API_RESPONSE.status === 200 &&
                        API_RESPONSE.data.success === true
                    ) {
                        message.success("Successfully deleted the equipment.");
                        history.push("/equipment");
                    } else {
                        message.warning(API_RESPONSE.data.message);
                    }
                } catch (err) {
                    unknownErrorMessage(
                        err.response ? err.response.data.message : null,
                    );
                }
            },
        });
    }

    render() {
        if (!permissions.check("EQ-R")) return <div />;

        return (
            <div>
                <Header
                    extra={
                        <Row type="flex">
                            <Col>
                                {permissions.check("EQ-U-A") ||
                                (permissions.check("EQ-U-C") &&
                                    this.state.eq &&
                                    this.state.eq.company &&
                                    this.state.eq.company._id ===
                                        this.state.eq.company._id) ? (
                                    <Button
                                        onClick={e =>
                                            history.push(
                                                "/equipment/" + this.state.eq &&
                                                    this.state.eq.company
                                                        .company +
                                                        "/" +
                                                        this.state.eq &&
                                                    this.state.eq._id +
                                                        "/update",
                                            )
                                        }
                                        size="small"
                                    >
                                        Update
                                    </Button>
                                ) : (
                                    ""
                                )}
                            </Col>
                            <Col style={{ marginLeft: 12 }}>
                                {permissions.check("EQ-D-A") ||
                                (permissions.check("EQ-D-C") &&
                                    this.state.eq &&
                                    this.state.eq.company &&
                                    this.state.eq.company._id ===
                                        this.state.eq.company._id) ? (
                                    <Button
                                        onClick={e => this.handleDelete()}
                                        size="small"
                                        type="danger"
                                    >
                                        Delete
                                    </Button>
                                ) : (
                                    ""
                                )}
                            </Col>
                            {permissions.check(["EQ-RK", "DEVICE-R"]) &&
                            this.state.eq &&
                            this.state.eq.device &&
                            this.state.eq.device.length === 0 ? (
                                <Col style={{ marginLeft: 12 }}>
                                    <Button
                                        onClick={e => this.handleRegister()}
                                        size="small"
                                        type="ghost"
                                    >
                                        Register device
                                    </Button>
                                </Col>
                            ) : (
                                permissions.check(["EQ-RK", "DEVICE-R"]) &&
                                this.state.eq &&
                                this.state.eq.device &&
                                this.state.eq.device.length === 1 && (
                                    <Col style={{ marginLeft: 12 }}>
                                        <Button
                                            onClick={e =>
                                                this.handleUnregister()
                                            }
                                            size="small"
                                            type="ghost"
                                        >
                                            Unregister device
                                        </Button>
                                    </Col>
                                )
                            )}
                        </Row>
                    }
                    activeTab={this.state.activeTab}
                    tabs={[
                        {
                            text: "Information",
                            key: "info",
                            onClick: this.tabChange,
                        },
                    ]}
                >
                    Equipment:{" "}
                    {(this.state.eq ? this.state.eq.manufacture.name : "") +
                        " " +
                        (this.state.eq ? this.state.eq.model.name : "")}
                </Header>
                <Content>{this.renderTab()}</Content>
                <RegisterModal
                    generatedKey={key =>
                        this.setState({
                            eq: { ...this.state.eq, registerKey: key },
                        })
                    }
                    eq={this.state.eq}
                    handleClose={() => this.setState({ registerModal: false })}
                    visible={this.state.registerModal}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    app: state.app,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(User);
