import React, { Component } from 'react'
import { Switch, Route, Redirect } from "react-router-dom";
import { permissions } from "../../../utils/permissions"

import Roles from "./Index/index.js";
import Add from "./Add";
import Update from "./Update";
import Role from "./Role";

export default class index extends Component {
    render() {
        if (!permissions.oneOf(["ROLE-C", "ROLE-R", "ROLE-U", "ROLE-D"])) return <Redirect to="/" />

        return (
            <div>
                <Switch>
                    <Route exact path="/roles" component={Roles} />
                    <Route exact path="/roles/add" component={Add} />
                    <Route exact path="/roles/:company/:role" component={Role} />
                    <Route path="/roles/:company/:role/update" component={Update} />
                </Switch>
            </div>
        )
    }
}
