import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Form, Icon, Input, Button, message } from "antd";
import history from "../../history";
import axios from "axios";
import { saveToken } from "../../utils/token";

const LoginBody = styled.div`
    display: flex;
    height: 100%;
    flex: 1;
    background: #fdfdfd;
    justify-content: center;
    align-items: center;

    flex-direction: column;
`;

const Container = styled.div`
    display: flex;
    width: 100%;
    max-width: 400px;
    background: white;

    border-radius: 0.3em;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.03);

    text-align: center;
    justify-content: center;
    margin-top: 2em;

    padding: 3em;
`;

const StyledForm = styled(Form)`
    width: 100%;
`;

const StyledButton = styled(Button)`
    width: 100%;
    margin-top: 1em;
`;

const Logo = styled.img`
    width: 400px;
    height: auto;
    margin-bottom: 3em;
`;

const StyledInput = styled(Input)`
    height: 40px;
`;

export class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            username: null,
            password: null,
        };
    }

    async handleLogin(e) {
        e.preventDefault();

        if (this.state.username === null || this.state.password === null) {
            message.warning("You must fill all fields!");
            return;
        }

        this.setState({ loading: true });

        const REQUEST_DATA = {
            username: this.state.username,
            password: this.state.password,
            type: "web",
        };

        /**
         * Call API /login to get a token in response
         */

        try {
            const API_RESPONSE = await axios.post(
                `${window.API}/auth/login`,
                REQUEST_DATA,
            );

            this.setState({ loading: false });
            if (API_RESPONSE.status === 200 && API_RESPONSE.data.success) {
                message.success(API_RESPONSE.data.message);
                saveToken(API_RESPONSE.data.token);

                history.push("/");
            } else if (!API_RESPONSE.data.success) {
                message.warning(API_RESPONSE.data.message);
            }
        } catch (err) {
            this.setState({ loading: false });
            message.warning(
                err.response
                    ? err.response
                        ? err.response.data.message
                        : null
                    : "There was a problem with a server. Try later again.",
            );
        }
    }

    inputChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    render() {
        return (
            <LoginBody>
                <Logo src="/img/logo.png" />

                <h1>Log in to a system</h1>

                <Container>
                    <StyledForm
                        onSubmit={e => this.handleLogin(e)}
                        method="POST"
                    >
                        <Form.Item>
                            <StyledInput
                                name="username"
                                onChange={e => this.inputChange(e)}
                                prefix={
                                    <Icon
                                        type="user"
                                        style={{ color: "rgba(0,0,0,.25)" }}
                                    />
                                }
                                placeholder="Username or e-mail address"
                            />
                        </Form.Item>
                        <Form.Item>
                            <StyledInput
                                name="password"
                                onChange={e => this.inputChange(e)}
                                prefix={
                                    <Icon
                                        type="lock"
                                        style={{ color: "rgba(0,0,0,.25)" }}
                                    />
                                }
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>
                        <StyledButton
                            loading={this.state.loading}
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                        >
                            Log in
                        </StyledButton>
                    </StyledForm>
                </Container>
            </LoginBody>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
