import store from "../store";

export const permissions = {
    check: permissions => {
        const user = store.getState().user ? store.getState().user : null;
        let result = true;

        if (typeof permissions == "object") {
            permissions.forEach(permission => {
                if (!checkPermission(user, permission)) result = false;
            });
        } else if (typeof permissions == "string") {
            result = checkPermission(user, permissions);
        }

        return result;
    },

    oneOf: permissions => {

        const user = store.getState().user ? store.getState().user : null;
        let result = false;

        permissions.forEach(permission => {
            if (checkPermission(user, permission)) result = true;
            return;
        });

        return result;
    },

    superAdmin: () => {
        const user = store.getState().user ? store.getState().user : null;

        return user.superAdmin;
    }
}

let checkPermission = (user, permission) => {
    if (user.superAdmin) return true;

    if (permission.split("-").length > 2) {
        let scope = permission.split("-")[2];
        permission = permission.split("-")[0] + "-" + permission.split("-")[1];

        let index = (user && user.role.permissions) ? user.role.permissions.findIndex(p => p.name === permission) : -1;

        if (user && user.role.permissions && index !== -1 && user.role.permissions[index].scope === scope) {
            return true;
        } else return false;
    } else {
        if (user && user.role.permissions && user.role.permissions.findIndex(p => p.name === permission) !== -1) {
            return true;
        } else return false;
    }
}