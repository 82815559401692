import React, { Component } from 'react'

import UsersList from "components/UsersList";

export default class Users extends Component {

    constructor(props) {
        super(props)

        this.state = {
            dataSource: []
        }

    }

    componentDidMount() {

    }


    render() {
        return (
            <div>
                <UsersList dataSource={this.props.company.users} />
            </div>
        )
    }
}
