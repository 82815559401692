import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../../../../components/Header";
import Content from "../../../../components/Content";
import store from "../../../../store";
import { set } from "../../../../_actions/appActions";
import { permissions } from "../../../../utils/permissions";
import axios from "axios";
import { getToken } from "../../../../utils/token";
import history from "../../../../history";
import { unknownErrorAlert, unknownErrorMessage } from "../../../../utils/app";

import {
    Form,
    Input,
    Icon,
    Row,
    Col,
    Button,
    message,
    Card,
    Select,
    Checkbox,
} from "antd";

const { Option } = Select;

export class Update extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            company: null,
            role: null,
            superAdmin: false,
            fullName: null,
            email: null,
            phoneNumber: null,
            username: null,
            pass: null,
            companiesData: [],
            rolesData: [],
            loading: false,
            passwordInputType: "password",
            sendEmailWithPassword: true,
            sendEmailWithPasswordButtonLoading: false,
            data: { username: "pawelgdak" },
            changes: false,
            selectRoleLoading: false,
            appRole: null,
        };
    }

    async companyChange(value) {
        this.setState({
            company: value,
            role: null,
            selectRoleLoading: true,
            changes: true,
        });

        try {
            const API_RESPONSE_ROLES = await axios.get(
                `${window.API}/roles?company=${value}`,
                {
                    headers: { Authorization: `Bearer ${getToken()}` },
                },
            );

            if (
                API_RESPONSE_ROLES.status === 200 &&
                API_RESPONSE_ROLES.data.success
            ) {
                this.setState({
                    rolesData: API_RESPONSE_ROLES.data.roles,
                    selectRoleLoading: false,
                });
            } else unknownErrorMessage();
        } catch (err) {
            unknownErrorMessage(err.response ? err.response.data.message : null);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidMount() {
        this._isMounted = true;
        this._isMounted && store.dispatch(set({ loading: true }));

        try {
            if (permissions.check(["USER-C-A"])) {
                const API_RESPONSE_COMPANIES = await axios.get(
                    `${window.API}/companies`,
                    {
                        headers: { Authorization: `Bearer ${getToken()}` },
                    },
                );

                if (
                    API_RESPONSE_COMPANIES.status === 200 &&
                    API_RESPONSE_COMPANIES.data.success
                ) {
                    this._isMounted &&
                        this.setState({
                            companiesData:
                                API_RESPONSE_COMPANIES.data.companies,
                        });
                }
            } else {
                this._isMounted &&
                    this.setState({ company: this.props.user.company._id });
            }

            const API_RESPONSE_USER = await axios.get(
                `${window.API}/users/${this.props.match.params.user}`,
                {
                    headers: { Authorization: `Bearer ${getToken()}` },
                },
            );

            if (
                API_RESPONSE_USER.status === 200 &&
                API_RESPONSE_USER.data.success
            ) {
                this._isMounted &&
                    (await this.setState({
                        data: API_RESPONSE_USER.data.user,
                        username: API_RESPONSE_USER.data.user.username,
                        email: API_RESPONSE_USER.data.user.email,
                        fullName: API_RESPONSE_USER.data.user.fullName,
                        phoneNumber: API_RESPONSE_USER.data.user.phoneNumber,
                        company: API_RESPONSE_USER.data.user.company._id,
                        role: API_RESPONSE_USER.data.user.role._id,
                        superAdmin: API_RESPONSE_USER.data.user.superAdmin,
                        appRole: API_RESPONSE_USER.data.user.appRole
                            ? API_RESPONSE_USER.data.user.appRole.name
                            : null,
                    }));

                const API_RESPONSE_ROLES = await axios.get(
                    `${window.API}/roles?company=${
                        this.state.data.company
                            ? this.state.data.company.company
                            : ""
                    }`,
                    {
                        headers: { Authorization: `Bearer ${getToken()}` },
                    },
                );

                if (
                    API_RESPONSE_ROLES.status === 200 &&
                    API_RESPONSE_ROLES.data.success
                ) {
                    this._isMounted &&
                        this.setState({
                            rolesData: API_RESPONSE_ROLES.data.roles,
                        });
                }
            } else {
                unknownErrorAlert(API_RESPONSE_USER.data.message);
            }

            store.dispatch(set({ loading: false }));
        } catch (err) {
            unknownErrorAlert();
        }

        this._isMounted &&
            store.dispatch(
                set({
                    loading: false,
                    breadcrumb: [
                        { name: "Users", href: "/users" },
                        {
                            name: this.state.data.username,
                            href: "@prev/" + this.state.data.username,
                        },
                        { name: "Update", href: "@prev/update" },
                    ],
                }),
            );
    }

    inputChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
            changes: true,
        });
    }

    async handleUpdate(e) {
        e.preventDefault();

        // Check all inputs
        if (
            this.state.fullName === null ||
            this.state.role === null ||
            this.state.company === null ||
            this.state.email === null ||
            this.state.username === null ||
            this.state.appRole === null
        ) {
            message.warning("You must fill all required fields!");
            return;
        }

        if (!/^[a-z0-9-._]+$/i.test(this.state.username)) {
            message.warning(
                "The username can contain only alphanumeric characters with the exception of underscores, dots and dashes.",
            );
            return;
        }

        if (this.state.username.length < 4 || this.state.username.length > 42) {
            message.warning(
                "The username must be at least 3 characters long and no longer than 42 characters.",
            );
            return;
        }

        if (
            this.state.fullName.length > 150 ||
            this.state.email.length > 150 ||
            (this.state.phoneNumber && this.state.phoneNumber.length > 150) ||
            (this.state.pass && this.state.pass.length > 150)
        ) {
            message.warning(
                "The full name, email, password and phone number fields must be no longer than 150 characters.",
            );
            return;
        }

        if (this.state.pass && this.state.pass.length < 8) {
            message.warning("The password must be at least 8 characters long.");
            return;
        }

        if (this.state.changes) {
            this.setState({ loading: true });

            const REQUEST_DATA = {
                username: this.state.username,
                password: this.state.pass,
                fullName: this.state.fullName,
                email: this.state.email,
                phoneNumber: this.state.phoneNumber,
                company: this.state.company,
                role: this.state.role,
                superAdmin: this.state.superAdmin,
                appRole: this.state.appRole,
            };

            try {
                const API_RESPONSE = await axios.put(
                    `${window.API}/users/${this.state.data._id}`,
                    REQUEST_DATA,
                    {
                        headers: { Authorization: `Bearer ${getToken()}` },
                    },
                );

                if (API_RESPONSE.data.success && API_RESPONSE.status === 200) {
                    store.dispatch(
                        set({
                            breadcrumb: [
                                { name: "Users", href: "/users" },
                                {
                                    name: this.state.data.username,
                                    href:
                                        "@prev/" +
                                        API_RESPONSE.data.user.username,
                                },
                                { name: "Update", href: "@prev/update" },
                            ],
                        }),
                    );

                    message.success("Successfully updated the user.");
                    history.push(
                        "/users/" + API_RESPONSE.data.user.username + "/update",
                    );
                } else {
                    unknownErrorMessage(API_RESPONSE.data.message);
                }
            } catch (err) {
                unknownErrorMessage(err.response ? err.response.data.message : null);
            }

            this.setState({ loading: false });
        }
    }

    generatePassword() {
        this.setState({
            passwordInputType: "text",
            pass: Math.random()
                .toString(36)
                .substr(2, 12),
            changes: true,
        });
    }

    async handleSendEmailWithPassword(e) {
        e.preventDefault();

        this.setState({ sendEmailWithPasswordButtonLoading: true });

        try {
            const API_RESPONSE = await axios.post(
                `${window.API}/sendemailwithpassword`,
                { _id: this.state.data._id },
                {
                    headers: { Authorization: `Bearer ${getToken()}` },
                },
            );

            if (API_RESPONSE.data.success && API_RESPONSE.data.code === 200) {
                message.success("E-mail has been succesfully sent.");
            } else {
                unknownErrorMessage(API_RESPONSE.data.message);
            }
        } catch (err) {
            unknownErrorMessage(err.response ? err.response.data.message : null);
        }

        this.setState({ sendEmailWithPasswordButtonLoading: false });
    }

    render() {
        if (!permissions.check("USER-U")) return <div />;

        return (
            <div>
                <Form onSubmit={e => this.handleUpdate(e)} method="POST">
                    <Header
                        buttons={
                            <Button
                                disabled={!this.state.changes}
                                loading={this.state.loading}
                                htmlType="submit"
                            >
                                Update user
                            </Button>
                        }
                        style={{ paddingBottom: 12 }}
                    >
                        Update user:{" "}
                        {this.state.data ? this.state.data.username : ""}
                    </Header>

                    <Content>
                        <Row gutter={42}>
                            <Col lg={12} md={24} style={{ marginBottom: 42 }}>
                                <Card title="Login credentials">
                                    <Form.Item label="Username">
                                        <Input
                                            prefix={
                                                <Icon
                                                    type="user"
                                                    style={{
                                                        color:
                                                            "rgba(0,0,0,.25)",
                                                    }}
                                                />
                                            }
                                            name="username"
                                            onChange={e => this.inputChange(e)}
                                            placeholder="Username"
                                            required
                                            value={this.state.username}
                                        />
                                    </Form.Item>
                                    <Form.Item label="E-mail">
                                        <Input
                                            name="email"
                                            required
                                            onChange={e => this.inputChange(e)}
                                            placeholder="E-mail address, e.g. john.doe@example.com"
                                            value={this.state.email}
                                        />
                                    </Form.Item>
                                    <Form.Item label="New password">
                                        <Input
                                            name="pass"
                                            prefix={
                                                <Icon
                                                    type="lock"
                                                    style={{
                                                        color:
                                                            "rgba(0,0,0,.25)",
                                                    }}
                                                />
                                            }
                                            onChange={e => this.inputChange(e)}
                                            value={this.state.pass}
                                            placeholder="New password"
                                            type={this.state.passwordInputType}
                                        />
                                        <Button
                                            type="dashed"
                                            onClick={e =>
                                                this.generatePassword()
                                            }
                                        >
                                            Generate password
                                        </Button>
                                    </Form.Item>
                                    <Button
                                        loading={
                                            this.state
                                                .sendEmailWithPasswordButtonLoading
                                        }
                                        type="shadow"
                                        onClick={e =>
                                            this.handleSendEmailWithPassword(e)
                                        }
                                    >
                                        Send e-mail with link to generate
                                        password
                                    </Button>
                                </Card>
                            </Col>
                            <Col lg={12} md={24} style={{ marginBottom: 42 }}>
                                <Card title="User details">
                                    <Form.Item label="Full name">
                                        <Input
                                            name="fullName"
                                            required
                                            onChange={e => this.inputChange(e)}
                                            placeholder="Full name, e.g. John Doe"
                                            value={this.state.fullName}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Phone number">
                                        <Input
                                            name="phoneNumber"
                                            onChange={e => this.inputChange(e)}
                                            placeholder="Phone number, e.g. +123 521 423 845"
                                            value={this.state.phoneNumber}
                                        />
                                    </Form.Item>
                                    {permissions.check("USER-U-A") && (
                                        <Form.Item label="Company">
                                            <Select
                                                name="company"
                                                value={this.state.company}
                                                defaultValue={
                                                    this.state.company
                                                }
                                                onChange={value =>
                                                    this.companyChange(value)
                                                }
                                                required
                                            >
                                                <Option disabled value="def">
                                                    Choose company
                                                </Option>
                                                {this.state.companiesData.map(
                                                    (company, index) => (
                                                        <Option
                                                            key={
                                                                "company-" +
                                                                index
                                                            }
                                                            value={company._id}
                                                        >
                                                            {
                                                                company.companyName
                                                            }
                                                        </Option>
                                                    ),
                                                )}
                                            </Select>
                                        </Form.Item>
                                    )}
                                </Card>
                            </Col>
                            <Col lg={12} md={24} style={{ marginBottom: 42 }}>
                                <Card title="System-related information">
                                    <Form.Item label="Role">
                                        <Select
                                            loading={
                                                this.state.selectRoleLoading
                                            }
                                            name="role"
                                            value={
                                                this.state.rolesData.findIndex(
                                                    role =>
                                                        role._id ===
                                                        this.state.role,
                                                ) !== -1
                                                    ? this.state.role
                                                    : ""
                                            }
                                            defaultValue={this.state.role}
                                            onChange={value =>
                                                this.setState({
                                                    role: value,
                                                    changes: true,
                                                })
                                            }
                                            required
                                        >
                                            <Option disabled value="def">
                                                Choose role
                                            </Option>
                                            {this.state.rolesData.map(
                                                (role, index) => (
                                                    <Option
                                                        key={"role-" + index}
                                                        value={role._id}
                                                    >
                                                        {role.roleName}
                                                    </Option>
                                                ),
                                            )}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Application role">
                                        <Select
                                            name="appRole"
                                            value={this.state.appRole}
                                            onChange={value =>
                                                this.setState({
                                                    appRole: value,
                                                    changes: true,
                                                })
                                            }
                                            required
                                        >
                                            <Option disabled value="def">
                                                Choose application role
                                            </Option>
                                            <Option value="user">User</Option>
                                            <Option value="driver">
                                                Driver
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                    {permissions.superAdmin() ? (
                                        <Form.Item>
                                            <Checkbox
                                                checked={this.state.superAdmin}
                                                onChange={e =>
                                                    this.setState({
                                                        superAdmin:
                                                            e.target.checked,
                                                        changes: true,
                                                    })
                                                }
                                            >
                                                Super admin
                                            </Checkbox>
                                        </Form.Item>
                                    ) : (
                                        ""
                                    )}
                                </Card>
                            </Col>
                        </Row>
                    </Content>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Update);
