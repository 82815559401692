import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Tag, Modal, Button, Card, message, Icon } from "antd";
import { permissions } from "../utils/permissions";
import { Link } from "react-router-dom";
import history from "../history";
import axios from "axios";
import { getToken } from "../utils/token";
import { unknownErrorMessage } from "../utils/app";
import fontColorContrast from "font-color-contrast";

const { confirm } = Modal;

export class RolesList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSource: [],
            modalVisible: false,
            currentRecord: null,
        };

        this.userColumns = [
            {
                title: "Username",
                dataIndex: "username",
                key: "username",
            },
            {
                title: "E-mail",
                dataIndex: "email",
                key: "email",
            },
            {
                title: "Phone number",
                dataIndex: "phoneNumber",
                key: "phoneNumber",
            },
            {
                title: "Company",
                dataIndex: "company",
                key: "company",
                render: company => {
                    return (
                        <Link to={"/companies/" + company.company}>
                            {company.companyName}
                        </Link>
                    );
                },
            },
        ];

        this.columns = [
            {
                title: "",
                dataIndex: "info",
                key: "info",
                render: (el, record) => {
                    return (
                        <Icon
                            style={{ fontSize: 18 }}
                            onClick={e => this.openModal(e, record)}
                            type="info-circle"
                            theme="twoTone"
                        />
                    );
                },
            },
            {
                title: "Role name",
                dataIndex: "roleName",
                key: "roleName",
                render: (roleName, role) => (
                    <Tag color={role.color}>
                        <span style={{ color: fontColorContrast(role.color) }}>
                            {role.roleName}
                        </span>
                    </Tag>
                ),
            },
            {
                title: "Role",
                dataIndex: "role",
                key: "role",
            },
            permissions.check("COMPANY-R-A")
                ? {
                      title: "Company",
                      dataIndex: "company",
                      key: "company",
                      render: company => {
                          return <span>{company.companyName}</span>;
                      },
                      sorter: (a, b) =>
                          a.company.companyName.localeCompare(
                              b.company.companyName,
                          ),
                  }
                : {},
            {
                title: "Permissions",
                dataIndex: "permissions",
                key: "permissions",
                render: permissions => (
                    <span>
                        {permissions.map((permission, index) => {
                            if (index < 5) {
                                let color = "geekblue";
                                return (
                                    <Tag
                                        color={color}
                                        key={index + "-" + permission._id}
                                        title={permission.description}
                                    >
                                        {permission.name.toUpperCase()}
                                    </Tag>
                                );
                            } else if (index === 5) {
                                return (
                                    <Tag color={"green"} key="more">
                                        {permissions.length - 5} more
                                    </Tag>
                                );
                            } else return null;
                        })}
                    </span>
                ),
            },
            {
                title: "Users",
                dataIndex: "users",
                key: "users",
                render: users => (users ? <span>{users.length}</span> : ""),
            },
        ];
    }

    openModal(e, record) {
        e.stopPropagation();

        this.setState({ modalVisible: true, currentRecord: record });
    }

    async handleDelete(e) {
        e.stopPropagation();

        confirm({
            title: "Are you sure delete this role?",
            content: "This operation is permament.",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: async e => {
                try {
                    const API_RESPONSE = await axios.delete(
                        `${window.API}/roles/${this.state.currentRecord._id}`,
                        {
                            headers: { Authorization: `Bearer ${getToken()}` },
                        },
                    );

                    if (
                        API_RESPONSE.status === 200 &&
                        API_RESPONSE.data.success === true
                    ) {
                        message.success("Successfully deleted the role.");

                        let index = this.props.dataSource.findIndex(
                            el => el.role === this.state.currentRecord.role,
                        );
                        if (index !== -1)
                            this.setState({
                                dataSource: this.props.dataSource.splice(
                                    index,
                                    1,
                                ),
                                modalVisible: false,
                            });
                    } else {
                        message.warning(API_RESPONSE.data.message);
                    }
                } catch (err) {
                    unknownErrorMessage(
                        err.response ? err.response.data.message : null,
                    );
                }
            },
        });
    }

    rowClick(record) {
        this.setState({ modalVisible: true, currentRecord: record });
    }

    handleOk = e => {
        this.setState({
            modalVisible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            modalVisible: false,
        });
    };

    render() {
        if (!permissions.check("ROLE-R")) return <div />;

        return (
            <div>
                <Table
                    rowKey={record => record._id}
                    onChange={this.handleChange}
                    rowClassName="table-row"
                    columns={this.columns}
                    dataSource={this.props.dataSource}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event =>
                                history.push(
                                    "/roles/" +
                                        record.company.company +
                                        "/" +
                                        record.role,
                                ),
                        };
                    }}
                />

                <Modal
                    title={
                        this.state.currentRecord
                            ? this.state.currentRecord.roleName
                            : ""
                    }
                    visible={this.state.modalVisible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        permissions.check("ROLE-U") ? (
                            <Button
                                onClick={e =>
                                    history.push(
                                        "/roles/" +
                                            (this.state.currentRecord
                                                ? this.state.currentRecord
                                                      .company.company + "/"
                                                : "") +
                                            (this.state.currentRecord
                                                ? this.state.currentRecord.role
                                                : "") +
                                            "/update",
                                    )
                                }
                                type="ghost"
                                color="gold"
                                key="update"
                            >
                                Update
                            </Button>
                        ) : (
                            ""
                        ),
                        permissions.check("ROLE-D") ? (
                            <Button
                                type="danger"
                                key="delete"
                                onClick={e => this.handleDelete(e)}
                            >
                                Delete
                            </Button>
                        ) : (
                            ""
                        ),
                        <Button key="return" onClick={this.handleCancel}>
                            Ok
                        </Button>,
                    ]}
                >
                    <p>
                        <strong>Role:</strong>{" "}
                        {this.state.currentRecord
                            ? this.state.currentRecord.role
                            : ""}
                    </p>
                    {permissions.check("COMPANY-R-A") && (
                        <p>
                            <strong>Company:</strong>{" "}
                            <Link
                                to={
                                    "/companies/" +
                                    (this.state.currentRecord
                                        ? this.state.currentRecord.company
                                              .company
                                        : "")
                                }
                            >
                                {this.state.currentRecord
                                    ? this.state.currentRecord.company
                                          .companyName
                                    : ""}
                            </Link>
                        </p>
                    )}
                    <Card size="small" title="Permissions">
                        {this.state.currentRecord
                            ? this.state.currentRecord.permissions.map(
                                  permission => {
                                      return (
                                          <Tag
                                              style={{ marginBottom: ".8em" }}
                                              color="geekblue"
                                              key={
                                                  "permission-" + permission._id
                                              }
                                              title={permission.description}
                                          >
                                              {permission.name}
                                          </Tag>
                                      );
                                  },
                              )
                            : ""}
                    </Card>

                    <p style={{ marginTop: "2em", marginBottom: "1em" }}>
                        <strong>Users</strong>
                    </p>
                    <Table
                        rowKey={record => "user-" + record._id}
                        rowClassName="table-row"
                        size="middle"
                        columns={this.userColumns}
                        dataSource={
                            this.state.currentRecord
                                ? this.state.currentRecord.users
                                : []
                        }
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event =>
                                    history.push("/users/" + record.username),
                            };
                        }}
                    ></Table>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RolesList);
