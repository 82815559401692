import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../../../../components/Header";
import Content from "../../../../components/Content";
import difference from "lodash/difference";
import store from "../../../../store";
import { set } from "../../../../_actions/appActions";
import { permissions } from "../../../../utils/permissions";
import axios from "axios";
import { getToken } from "../../../../utils/token";
import history from "../../../../history";
import { unknownErrorAlert, unknownErrorMessage } from "../../../../utils/app";
import Map from "components/Map/CompanyAirportMap";

import {
    Form,
    Tooltip,
    Input,
    Icon,
    Row,
    Col,
    Transfer,
    Card,
    Table,
    Tag,
    Button,
    message,
    Upload,
} from "antd";

function beforeUpload(file) {
    const isValid = file.type === "image/jpeg" || file.type === "image/png";
    if (!isValid) {
        message.error("You can only upload JPG/PNG file!");
    }
    const isLt4M = file.size / 1024 / 1024 < 4;
    if (!isLt4M) {
        message.error("Image must smaller than 4MB!");
    }
    return isValid && isLt4M;
}

const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
    <Transfer {...restProps} showSelectAll={false}>
        {({
            direction,
            filteredItems,
            onItemSelectAll,
            onItemSelect,
            selectedKeys: listSelectedKeys,
            disabled: listDisabled,
        }) => {
            const columns = direction === "left" ? leftColumns : rightColumns;

            const rowSelection = {
                getCheckboxProps: item => ({
                    disabled: listDisabled || item.disabled,
                }),
                onSelectAll(selected, selectedRows) {
                    const treeSelectedKeys = selectedRows
                        .filter(item => !item.disabled)
                        .map(({ key }) => key);
                    const diffKeys = selected
                        ? difference(treeSelectedKeys, listSelectedKeys)
                        : difference(listSelectedKeys, treeSelectedKeys);
                    onItemSelectAll(diffKeys, selected);
                },
                onSelect({ key }, selected) {
                    onItemSelect(key, selected);
                },
                selectedRowKeys: listSelectedKeys,
            };

            return (
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={filteredItems}
                    size="small"
                    style={{ pointerEvents: listDisabled ? "none" : null }}
                    onRow={({ key, disabled: itemDisabled }) => ({
                        onClick: () => {
                            if (itemDisabled || listDisabled) return;
                            onItemSelect(key, !listSelectedKeys.includes(key));
                        },
                    })}
                />
            );
        }}
    </Transfer>
);

const tableColumns = [
    {
        dataIndex: "name",
        title: "Name",
    },
    {
        dataIndex: "scope",
        title: "Scope",
        render: scope => <Tag>{scope}</Tag>,
    },
    {
        dataIndex: "description",
        title: "Descriptions",
    },
];

export class Add extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            targetKeys: [],
            dataSource: [],
            company: null,
            companyName: null,
            address: null,
            loadingUpload: false,
            buttonLoading: false,
            selectMapCenter: false,
            fileName: null,
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidMount() {
        this._isMounted = true;

        this._isMounted &&
            store.dispatch(
                set({
                    loading: true,
                    breadcrumb: [
                        { name: "Companies", href: "/companies" },
                        { name: "Add company", href: "/companies/add" },
                    ],
                }),
            );

        try {
            const API_RESPONSE = await axios.get(`${window.API}/permissions`, {
                headers: { Authorization: `Bearer ${getToken()}` },
            });

            if (API_RESPONSE.status === 200 && API_RESPONSE.data.success) {
                this._isMounted &&
                    this.setState({
                        dataSource: API_RESPONSE.data.permissions,
                    });
                this._isMounted && store.dispatch(set({ loading: false }));
            }
        } catch (err) {
            unknownErrorAlert();
        }
    }

    inputChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    onChange = nextTargetKeys => {
        this.setState({ targetKeys: nextTargetKeys });
    };

    async handleAdd(e) {
        e.preventDefault();

        // Check all inputs
        if (this.state.company === null || this.state.companyName === null) {
            message.warning("You must fill all fields!");
            return;
        }

        if (!/^[a-z0-9-_]+$/i.test(this.state.company)) {
            message.warning(
                "The company field can contain only alphanumeric characters with the exception of undescores and dashes.",
            );
            return;
        }

        if (
            this.state.company.length > 32 ||
            this.state.companyName.length > 32
        ) {
            message.warning(
                "The company and company name fields must be no longer than 32 characters.",
            );
            return;
        }

        if (this.state.address && this.state.address.length > 500) {
            message.warning(
                "The address field must be no longer than 500 characters.",
            );
            return;
        }

        this.setState({ buttonLoading: true });

        const REQUEST_DATA = {
            company: this.state.company,
            companyName: this.state.companyName,
            permissions: this.state.targetKeys,
            address: this.state.address,
            airportName: this.state.airportName || null,
            airportMap: this.state.airportMap || null,
            mapCenterCoords: this.state.mapCenterCoords || null,
            mapBoundariesCoords: this.state.mapBoundariesCoords || null,
            fileName: this.state.fileName,
        };

        try {
            const API_RESPONSE = await axios.post(
                `${window.API}/companies`,
                REQUEST_DATA,
                {
                    headers: { Authorization: `Bearer ${getToken()}` },
                },
            );

            this.setState({ buttonLoading: false });

            if (API_RESPONSE.data.success && API_RESPONSE.status === 200) {
                message.success("Successfully added new company!");
                history.push("/companies/" + this.state.company);
            } else {
                message.warning(API_RESPONSE.data.message);
            }
        } catch (err) {
            unknownErrorMessage(
                err.response ? err.response.data.message : null,
            );
        }
    }

    handleFileUpload = info => {
        if (info.file.status === "uploading") {
            this.setState({ loadingUpload: true });
            return;
        }
        if (info.file.status === "done") {
            if (info.file.response.success && info.file.response.code === 200) {
                this.setState({
                    loadingUpload: false,
                    imageUrl: info.file.response.data.url,
                    fileName: info.file.response.data.fileName,
                });
            } else {
                message.error(info.file.response.message);
            }
        }
    };

    render() {
        if (!permissions.check("COMPANY-C")) return <div />;

        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? "loading" : "plus"} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        const { imageUrl } = this.state;

        return (
            <div>
                <Form onSubmit={e => this.handleAdd(e)} method="POST">
                    <Header
                        buttons={
                            <Button
                                loading={this.state.buttonLoading}
                                htmlType="submit"
                            >
                                Add company
                            </Button>
                        }
                        style={{ paddingBottom: 12 }}
                    >
                        Add company
                    </Header>

                    <Content>
                        <Row gutter={42}>
                            <Col style={{ marginBottom: 42 }} lg={12} md={24}>
                                <Card title="Basic information">
                                    <Form.Item label="Company">
                                        <Input
                                            name="company"
                                            onChange={e => this.inputChange(e)}
                                            placeholder="Short name without spaces, e.g. flyhigh"
                                        />
                                    </Form.Item>
                                    <Form.Item label="Company name">
                                        <Input
                                            name="companyName"
                                            onChange={e => this.inputChange(e)}
                                            placeholder="Company name e.g. FlyHigh"
                                        />
                                    </Form.Item>
                                </Card>
                            </Col>
                            <Col style={{ marginBottom: 42 }} lg={12} md={24}>
                                <Card title="Additional information">
                                    <Form.Item label="Address">
                                        <Input
                                            name="address"
                                            onChange={e => this.inputChange(e)}
                                            placeholder="Address of the company"
                                        />
                                    </Form.Item>
                                    <Form.Item label="Logo">
                                        <Upload
                                            name="logo"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            action={`${window.API}/uploadlogo`}
                                            headers={{
                                                Authorization: `Bearer ${getToken()}`,
                                            }}
                                            beforeUpload={beforeUpload}
                                            onChange={this.handleFileUpload}
                                        >
                                            {imageUrl ? (
                                                <img
                                                    style={{
                                                        maxWidth: "400px",
                                                        maxHeight: "400px",
                                                    }}
                                                    src={imageUrl}
                                                    alt="logo"
                                                />
                                            ) : (
                                                uploadButton
                                            )}
                                        </Upload>
                                    </Form.Item>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ marginBottom: 42 }}>
                                <Card title="Airport information">
                                    <Row gutter={42}>
                                        <Col lg={12} md={24}>
                                            <Form.Item label="Name">
                                                <Input
                                                    name="airportName"
                                                    onChange={e =>
                                                        this.inputChange(e)
                                                    }
                                                    placeholder="Airport name"
                                                />
                                            </Form.Item>
                                            <Form.Item label="Map">
                                                <Input
                                                    name="airportMap"
                                                    onChange={e =>
                                                        this.inputChange(e)
                                                    }
                                                    placeholder="Airport map name"
                                                />
                                            </Form.Item>
                                            <Form.Item label="Map center">
                                                <Tooltip
                                                    visible={
                                                        this.state
                                                            .mapCenterCoordsTooltip
                                                    }
                                                    title="Select position on the map"
                                                >
                                                    <Input
                                                        name="mapCenterCoords"
                                                        value={
                                                            this.state
                                                                .mapCenterCoords
                                                        }
                                                        placeholder="Map center coordinates"
                                                        onSelect={e =>
                                                            this.setState({
                                                                selectMapCenter: true,
                                                                mapCenterCoordsTooltip: true,
                                                            })
                                                        }
                                                        onBlur={e =>
                                                            this.setState({
                                                                selectMapCenter: false,
                                                                mapCenterCoordsTooltip: false,
                                                            })
                                                        }
                                                    />
                                                </Tooltip>
                                            </Form.Item>
                                            <Form.Item label="Map boundaries">
                                                <Tooltip
                                                    visible={
                                                        this.state
                                                            .mapBoundariesTooltip
                                                    }
                                                    title="Select position on the map"
                                                >
                                                    <Input
                                                        name="mapBoundariesCoords"
                                                        value={
                                                            this.state
                                                                .mapBoundariesCoords
                                                        }
                                                        placeholder="Map boundaries coordinates"
                                                        onSelect={e =>
                                                            this.setState({
                                                                selectMapBoundaries: true,
                                                                mapBoundariesTooltip: true,
                                                            })
                                                        }
                                                        onBlur={e =>
                                                            this.setState({
                                                                selectMapBoundaries: false,
                                                                mapBoundariesTooltip: false,
                                                            })
                                                        }
                                                    />
                                                </Tooltip>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={12} md={24}>
                                            <Map
                                                selectMapCenter={
                                                    this.state.selectMapCenter
                                                }
                                                selectMapBoundaries={
                                                    this.state
                                                        .selectMapBoundaries
                                                }
                                                onSelectMapCenter={coords =>
                                                    this.setState({
                                                        mapCenterCoords: coords.map(
                                                            coord => coord,
                                                        ),
                                                    })
                                                }
                                                onSelectMapBoundaries={coords =>
                                                    this.setState({
                                                        mapBoundariesCoords: coords.map(
                                                            coord => coord,
                                                        ),
                                                    })
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ marginBottom: 42 }}>
                                <Card title="Available permissions">
                                    <Form.Item>
                                        <TableTransfer
                                            rowKey={record => record._id}
                                            dataSource={this.state.dataSource}
                                            targetKeys={this.state.targetKeys}
                                            showSearch={true}
                                            onChange={this.onChange}
                                            filterOption={(inputValue, item) =>
                                                item.name
                                                    .toLowerCase()
                                                    .indexOf(
                                                        inputValue.toLowerCase(),
                                                    ) !== -1 ||
                                                item.description
                                                    .toLowerCase()
                                                    .indexOf(
                                                        inputValue.toLowerCase(),
                                                    ) !== -1
                                            }
                                            leftColumns={tableColumns}
                                            rightColumns={tableColumns}
                                        />
                                    </Form.Item>
                                </Card>
                            </Col>
                        </Row>
                    </Content>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
