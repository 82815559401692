import React, { Component } from 'react'
import { Link } from "react-router-dom"
import { Row, Col, Card } from "antd"

export default class Info extends Component {
    render() {
        return (
            <div>
                <Row gutter={42}>
                    <Col style={{ marginBottom: 42 }} lg={12} md={24}>
                        <Card title="User credentials">
                            <p><strong>Username:</strong> {this.props.user.username}</p>
                            <p><strong>E-mail address:</strong> {this.props.user.email}</p>
                        </Card>
                    </Col>
                    <Col style={{ marginBottom: 42 }} lg={12} md={24}>
                        <Card title="User details">
                            <p><strong>Full name:</strong> {this.props.user.fullName}</p>
                            <p><strong>Phone number:</strong> {this.props.phoneNumber ? this.props.user.phoneNumber : "none"}</p>
                            <p><strong>Company:</strong> <Link to={"/companies/" + (this.props.user.company ? this.props.user.company.company : "")}>{this.props.user.company ? this.props.user.company.companyName : ""}</Link></p>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}
