import React, { Component } from "react";
import { Table, Modal, Icon } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { permissions } from "../utils/permissions";
import history from "../history";

export class EquipmentList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSource: [],
            modalVisible: false,
            currentRecord: null
        };

        this.eqColumns = [
            {
                title: "Type",
                dataIndex: "type",
                key: "type-eq",
                render: type => <span>{type.name}</span>
            },
            {
                title: "Manufacture",
                dataIndex: "manufacture",
                key: "manufacture",
                render: manufacture => <span>{manufacture.name}</span>
            },
            {
                title: "Model",
                dataIndex: "model",
                key: "model",
                render: model => <span>{model.name}</span>
            },
            {
                title: "Number plate",
                dataIndex: "numberPlate",
                key: "numberPlate"
            },
            {
                title: "Company",
                dataIndex: "company",
                key: "company",
                render: company => (company ? company.companyName : "")
            }
        ];

        this.columns = [
            {
                title: "",
                dataIndex: "info",
                key: "info",
                render: (el, record) => {
                    return (
                        <Icon
                            style={{ fontSize: 18 }}
                            onClick={e => this.openModal(e, record)}
                            type="info-circle"
                            theme="twoTone"
                        />
                    );
                }
            },
            {
                title: "Type",
                dataIndex: "type",
                key: "type",
                render: el => el.name
            },
            {
                title: "Manufacture",
                dataIndex: "name",
                key: "name"
            },
            {
                title: "Company",
                dataIndex: "company",
                key: "company",
                render: company => (company ? company.companyName : "")
            },
            {
                title: "Equipment",
                dataIndex: "eq",
                key: "eq",
                render: (el, record) => {
                    return (
                        <span>
                            {this.props.eq &&
                                this.props.eq.filter(
                                    el => el.manufacture._id === record._id
                                ).length}
                        </span>
                    );
                }
            },
            {
                title: "Create date",
                dataIndex: "createDate",
                key: "createDate",
                sorter: (a, b) =>
                    new Date(a.createDate) - new Date(b.createDate),
                render: date => (
                    <span>{moment(date).format("D MMMM Y HH:mm")}</span>
                )
            }
        ];
    }

    openModal(e, record) {
        e.stopPropagation();

        this.setState({ modalVisible: true, currentRecord: record });
    }

    // async handleDelete(e) {
    //     e.stopPropagation();

    //     confirm({
    //         title: "Are you sure delete this equipment?",
    //         content: "This operation is permament.",
    //         okText: "Yes",
    //         okType: "danger",
    //         cancelText: "No",
    //         onOk: async e => {
    //             try {
    //                 const API_RESPONSE = await axios.post(
    //                     `${window.API}/deleteequipment`,
    //                     { equipment: this.state.currentRecord._id },
    //                     {
    //                         headers: { Authorization: `Bearer ${getToken()}` }
    //                     }
    //                 );

    //                 if (
    //                     API_RESPONSE.data.code === 200 &&
    //                     API_RESPONSE.data.success === true
    //                 ) {
    //                     message.success("Successfully deleted the equipment.");

    //                     let index = this.props.dataSource.findIndex(
    //                         el => el._id === this.state.currentRecord._id
    //                     );
    //                     if (index !== -1)
    //                         this.setState({
    //                             dataSource: this.props.dataSource.splice(
    //                                 index,
    //                                 1
    //                             ),
    //                             modalVisible: false
    //                         });
    //                 } else {
    //                     message.warning(API_RESPONSE.data.message);
    //                 }
    //             } catch (err) {
    //                 unknownErrorMessage();
    //             }
    //         }
    //     });
    // }

    rowClick(record) {
        this.setState({ modalVisible: true, currentRecord: record });
    }

    handleOk = e => {
        this.setState({
            modalVisible: false
        });
    };

    handleCancel = e => {
        this.setState({
            modalVisible: false
        });
    };

    render() {
        if (!permissions.check("EQ-R")) return <div />;

        return (
            <div>
                <Table
                    showSearch={true}
                    rowKey={record => record._id}
                    filterOption={(inputValue, item) =>
                        item.name
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) !== -1 ||
                        item.description
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) !== -1
                    }
                    rowClassName="table-row"
                    columns={this.columns}
                    dataSource={this.props.dataSource}
                    onRow={(record, rowIndex) => {
                        // return {
                        //     onClick: event =>
                        //         history.push(
                        //             "/equipment/" +
                        //                 record.company.company +
                        //                 "/" +
                        //                 record._id
                        //         )
                        // };
                    }}
                />

                <Modal
                    title={
                        this.state.currentRecord
                            ? "Equipment manufacture: " +
                              this.state.currentRecord.name
                            : ""
                    }
                    visible={this.state.modalVisible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <div>
                        <p>
                            <strong>Name: </strong>
                            {this.state.currentRecord &&
                                this.state.currentRecord.name}
                        </p>
                        <p>
                            <strong>Company: </strong>
                            <Link
                                to={`/companies/${this.state.currentRecord &&
                                    this.state.currentRecord.company.company}`}
                            >
                                {this.state.currentRecord &&
                                    this.state.currentRecord.company
                                        .companyName}
                            </Link>
                        </p>
                        <p style={{ marginTop: "2em", marginBottom: "1em" }}>
                            <strong>Equipment</strong>
                        </p>
                        <Table
                            rowKey={record => "eq-" + record._id}
                            rowClassName="table-row"
                            size="middle"
                            columns={this.eqColumns}
                            dataSource={
                                this.props.eq
                                    ? this.props.eq.filter(
                                          el =>
                                              el.manufacture._id ===
                                              (this.state.currentRecord
                                                  ? this.state.currentRecord._id
                                                  : -1)
                                      )
                                    : []
                            }
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event =>
                                        history.push(
                                            "/equipment/" +
                                                record.company.company +
                                                "/" +
                                                record._id
                                        )
                                };
                            }}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}

export default EquipmentList;
