import React, { Component } from 'react'
import Header from "../../../../components/Header"
import Content from "../../../../components/Content"
import store from "../../../../store"
import { set } from "../../../../_actions/appActions"
import { connect } from 'react-redux'
import history from "../../../../history"
import { permissions } from "../../../../utils/permissions"

import Users from "./Tabs/Users"

export class Role extends Component {

    _isMounted = false;

    constructor(props) {
        super(props)

        this.state = {
            activeTab: history.location.hash !== "" ? history.location.hash.split("#")[1] : "users"
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidMount() {

        this._isMounted = true;
        this._isMounted && store.dispatch(set({ loading: true }));

        this._isMounted && store.dispatch(set({ loading: false, breadcrumb: [{ name: "System", href: "/system" }] }));

    }

    tabChange = key => {
        this.setState({ activeTab: key })
    }

    renderTab() {
        switch (this.state.activeTab) {
            case "users":
                return <Users />
            default: return <div />
        }
    }

    render() {
        if (!permissions.check("ROLE-R")) return <div />;

        return (
            <div>
                <Header
                    activeTab={this.state.activeTab}
                    tabs={
                        [
                            { text: "Active users", key: "users", onClick: this.tabChange }
                        ]
                    }
                >System</Header>
                <Content>
                    {this.renderTab()}
                </Content>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Role)
