import store from "../store"
import { set } from "../_actions/appActions"
import { message } from "antd"

export const unknownErrorAlert = (msg) => {
    store.dispatch(set({ loading: false, disabled: true, disabledText: msg ? msg : "An error has ocurred while loading this page. Try later again." }));
};

export const unknownErrorMessage = (msg) => {
    message.warn(msg ? msg : "An error has ocurred while processing your request. Try later again.");
}

export const translateScope = scope => {
    switch (scope) {
        case 'A': return "System";
        case 'C': return "Company";
        case 'O': return "Own";
        case 'S': return "Specific";
        default: return scope;
    }
}