import React, { Component } from "react";
import Header from "../../../../components/Header";
import Content from "../../../../components/Content";
import { Button } from "antd";
import { permissions } from "../../../../utils/permissions";
import history from "../../../../history";
import { set } from "../../../../_actions/appActions";
import store from "../../../../store";
import axios from "axios";
import { getToken } from "../../../../utils/token";
import { unknownErrorAlert } from "../../../../utils/app";
import { Socket } from "../../../../socket";
import { connect } from "react-redux";
import update from "react-addons-update";

import EquipmentList from "components/EquipmentList";

class Index extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            dataSource: [],
        };
    }

    _socketComplete = false;
    componentDidUpdate(prevProps, prevState) {
        if (
            !this._socketComplete &&
            (this.props.app.socket || prevProps.app.socket)
        ) {
            this._socketComplete = true;

            Socket.conn && Socket.conn.emit("join active-devices");
            Socket.conn &&
                Socket.conn.on("active-devices", data => {
                    let eq = data.equipment;
                    let status = data.type === "connect" ? true : false;

                    let index = this.state.dataSource.findIndex(
                        el => el._id === eq,
                    );

                    if (
                        index !== -1 &&
                        typeof this.state.dataSource[index].status !== undefined
                    )
                        this.setState({
                            dataSource: update(this.state.dataSource, {
                                [index]: { status: { $set: status } },
                            }),
                        });
                });
        }
    }
    componentWillUnmount() {
        this._isMounted = false;

        Socket.conn && Socket.conn.emit("leave active-devices");
        Socket.conn && Socket.conn.removeListener("active-devices");
    }

    async componentDidMount() {
        this._isMounted = true;

        if (!permissions.check("EQ-R")) history.push("/");

        this._isMounted &&
            store.dispatch(
                set({
                    loading: true,
                    breadcrumb: [{ name: "Equipment", href: "/equipment" }],
                }),
            );

        try {
            const API_RESPONSE = await axios.get(`${window.API}/equipment`, {
                headers: { Authorization: `Bearer ${getToken()}` },
            });

            if (API_RESPONSE.status === 200 && API_RESPONSE.data.success) {
                this._isMounted &&
                    this.setState({
                        dataSource: API_RESPONSE.data.equipment,
                    });
                this._isMounted && store.dispatch(set({ loading: false }));
            }
        } catch (err) {
            unknownErrorAlert();
        }
    }

    render() {
        return (
            <div>
                <Header
                    extra={
                        permissions.check("EQ-C") ? (
                            <Button
                                onClick={e => history.push("/equipment/add")}
                                size="small"
                            >
                                Add
                            </Button>
                        ) : (
                            ""
                        )
                    }
                >
                    Equipment
                </Header>
                <Content>
                    <EquipmentList dataSource={this.state.dataSource} />
                </Content>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.app,
});

export default connect(mapStateToProps)(Index);
