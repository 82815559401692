import React, { Component } from "react";
import { connect } from "react-redux";
import Feature from "ol/Feature";
import { Polygon } from "ol/geom";
import { transform } from "ol/proj";
import { Style, Fill, Stroke } from "ol/style";

export class Boundaries extends Component {
    _setup = false;

    style = new Style({
        fill: new Fill({
            color: "rgba(30,30,250,0.05)"
        }),
        stroke: new Stroke({
            color: "rgba(30,30,250,0.1)",
            width: "2px"
        })
    });

    componentDidUpdate(prevProps) {
        let features = this.props.source && this.props.source.getFeatures();
        features &&
            features.forEach(feature => {
                if (this.props.hide) {
                    feature.setStyle(new Style({}));
                } else feature.setStyle(this.style);
            });

        if (this.props.source !== prevProps.source && !this._setup) {
            this._setup = true;

            let feature = new Feature({
                name: "boundaries",
                geometry: new Polygon([
                    [
                        transform(
                            [
                                this.props.boundaries.northEast.longitude,
                                this.props.boundaries.northEast.latitude
                            ],
                            "EPSG:4326",
                            "EPSG:3857"
                        ),
                        transform(
                            [
                                this.props.boundaries.southWest.longitude,
                                this.props.boundaries.northEast.latitude
                            ],
                            "EPSG:4326",
                            "EPSG:3857"
                        ),
                        transform(
                            [
                                this.props.boundaries.southWest.longitude,
                                this.props.boundaries.southWest.latitude
                            ],
                            "EPSG:4326",
                            "EPSG:3857"
                        ),
                        transform(
                            [
                                this.props.boundaries.northEast.longitude,
                                this.props.boundaries.southWest.latitude
                            ],
                            "EPSG:4326",
                            "EPSG:3857"
                        ),
                        transform(
                            [
                                this.props.boundaries.northEast.longitude,
                                this.props.boundaries.northEast.latitude
                            ],
                            "EPSG:4326",
                            "EPSG:3857"
                        )
                    ]
                ])
            });

            if (this.props.hide) {
                feature.setStyle(new Style({}));
            } else feature.setStyle(this.style);
            this.props.source.addFeature(feature);
        }
    }

    render() {
        return <div />;
    }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(Boundaries);
