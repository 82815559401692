import React, { Component } from "react";
import OlMap from "ol/Map";
import View from "ol/View";
import { transform } from "ol/proj";
import { LineString, Point } from "ol/geom";
import Feature from "ol/Feature";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { OSM, Vector as VectorSource } from "ol/source";
import { Style, Icon } from "ol/style";
import styled from "styled-components";

const MapDiv = styled.div`
    width: 100%;
    height: 280px;
`;

var iconStyle = new Style({
    image: new Icon({
        anchor: [0.5, 38],
        anchorXUnits: "fraction",
        anchorYUnits: "pixels",
        src: require("../../icons/marker.png"),
    }),
});

export default class TripPath extends Component {
    map;
    draw;
    addInteraction;
    raster;
    source;
    vector;

    state = {
        mapId: null,
    };

    async componentDidMount() {
        await this.setState({
            mapId: `map-${Math.floor(Math.random() * 10000)}`,
        });

        this.raster = new TileLayer({
            source: new OSM(),
        });

        this.source = new VectorSource({ wrapX: false });

        this.vector = new VectorLayer({
            source: this.source,
        });

        this.map = new OlMap({
            layers: [this.raster, this.vector],
            target: this.state.mapId,
            view: new View({
                center: transform(
                    [
                        this.props.airport.location.center.longitude,
                        this.props.airport.location.center.latitude,
                    ],
                    "EPSG:4326",
                    "EPSG:3857",
                ),
                zoom: 16,
            }),
        });

        this.drawPath();
    }

    drawPath = () => {
        this.source.clear();

        let feature;

        feature = new Feature({
            name: "pickup-spot",
            geometry: new Point(
                transform(
                    [
                        this.props.trip.spot.location.longitude,
                        this.props.trip.spot.location.latitude,
                    ],
                    "EPSG:4326",
                    "EPSG:3857",
                ),
            ),
        });

        feature.setStyle(iconStyle);
        this.source.addFeature(feature);

        feature = new Feature({
            name: "start",
            geometry: new Point(
                transform(
                    [
                        this.props.path.length > 0 && this.props.path[0].long,
                        this.props.path.length > 0 && this.props.path[0].lat,
                    ],
                    "EPSG:4326",
                    "EPSG:3857",
                ),
            ),
        });
        this.source.addFeature(feature);

        let points = this.props.path.map(p =>
            transform([p.long, p.lat], "EPSG:4326", "EPSG:3857"),
        );

        feature = new Feature({
            name: "path",
            geometry: new LineString(points),
        });

        this.source.addFeature(feature);
    };

    componentDidUpdate(prevProps, prevState) {
        this.map && this.map.updateSize();

        if (
            prevProps.trip &&
            this.props.trip &&
            prevProps.trip._id !== this.props.trip._id
        ) {
            this.drawPath();
        }

        this.props.airport &&
            this.map &&
            this.map
                .getView()
                .setCenter(
                    transform(
                        [
                            this.props.airport.location.center.longitude,
                            this.props.airport.location.center.latitude,
                        ],
                        "EPSG:4326",
                        "EPSG:3857",
                    ),
                );
    }

    render() {
        return <MapDiv id={this.state.mapId} className={this.state.mapId} />;
    }
}
