import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../../../../components/Header";
import Content from "../../../../components/Content";
import store from "../../../../store";
import { set } from "../../../../_actions/appActions";
import { permissions } from "../../../../utils/permissions";
import axios from "axios";
import { getToken } from "../../../../utils/token";
import history from "../../../../history";
import { unknownErrorAlert, unknownErrorMessage } from "../../../../utils/app";
import Modal from "./Modal";

import {
    Form,
    Input,
    Icon,
    Row,
    Col,
    Button,
    message,
    Card,
    Select,
    Divider,
} from "antd";

const { Option } = Select;

export class Add extends Component {
    _isMounted = false;

    closeModal = () => {
        this.setState({ modal: false, modalType: null, modalValue: null });
    };

    DropdownMenu = (menu, type) => {
        let title = "Add new";
        let icon = "plus";
        let disabled = false;
        switch (type) {
            case "type":
                title = this.state.newType ? "Edit new type" : "Add new type";
                icon = this.state.newType ? "edit" : "plus";
                if (!this.state.company) disabled = true;
                break;
            case "manufacture":
                title = this.state.newManufacture
                    ? "Edit new manufacture"
                    : "Add new manufacture";
                icon = this.state.newManufacture ? "edit" : "plus";
                if (!this.state.type || this.state.type === "def")
                    disabled = true;
                break;
            case "model":
                title = this.state.newModel
                    ? "Edit new model"
                    : "Add new model";
                icon = this.state.newModel ? "edit" : "plus";
                if (!this.state.manufacture || this.state.manufacture === "def")
                    disabled = true;
                break;
            default:
                return;
        }

        return (
            <div>
                {menu}
                {!disabled && (
                    <div>
                        <Divider
                            style={{
                                margin: "4px 0",
                            }}
                        />
                        <div
                            style={{
                                padding: "8px",
                                cursor: "pointer",
                            }}
                            onMouseDown={e => e.preventDefault()}
                            onClick={e => {
                                this.setState({ modal: true, modalType: type });

                                switch (type) {
                                    case "type":
                                        this.setState({
                                            modalValue: this.state.newType,
                                        });
                                        break;
                                    case "manufacture":
                                        this.setState({
                                            modalValue: this.state
                                                .newManufacture,
                                        });
                                        break;
                                    case "model":
                                        this.setState({
                                            modalValue: this.state.newModel,
                                        });
                                        break;
                                    default:
                                        return;
                                }
                            }}
                        >
                            <Icon type={icon} /> {title}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    addNewField = (name, type) => {
        switch (type) {
            case "type":
                this.setState({
                    newType: name,
                    type: "new",
                    model: "def",
                    newModel: null,
                    modelsData: [],
                    manufacture: "def",
                    newManufacture: null,
                    manufacturesData: [],
                });
                break;
            case "manufacture":
                this.setState({
                    newManufacture: name,
                    manufacture: "new",
                    model: "def",
                    newModel: null,
                    modelsData: [],
                });
                break;
            case "model":
                this.setState({ newModel: name, model: "new" });
                break;
            default:
                return;
        }
    };

    constructor(props) {
        super(props);

        this.state = {
            type: "def",
            manufacture: "def",
            model: "def",
            numberPlate: null,
            modal: false,
            modalType: null,
            modalValue: null,
            newType: null,
            newManufacture: null,
            newModel: null,
            company: null,
            companiesData: [],
            typesData: [],
            manufacturesData: [],
            modelsData: [],
            selectTypeLoading: false,
            selectManufactureLoading: false,
            selectModelLoading: false,
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidMount() {
        this._isMounted = true;
        if (!permissions.check("EQ-C")) history.push("/");

        store.dispatch(
            set({
                loading: true,
                breadcrumb: [
                    { name: "Equipment", href: "/equipment" },
                    { name: "Add equipment", href: "/equipment/add" },
                ],
            }),
        );

        try {
            if (permissions.check(["EQ-C-A"])) {
                const API_RESPONSE_COMPANIES = await axios.get(
                    `${window.API}/companies`,
                    {
                        headers: { Authorization: `Bearer ${getToken()}` },
                    },
                );

                if (
                    API_RESPONSE_COMPANIES.status === 200 &&
                    API_RESPONSE_COMPANIES.data.success
                ) {
                    this._isMounted &&
                        this.setState({
                            companiesData:
                                API_RESPONSE_COMPANIES.data.companies,
                        });
                }
            } else {
                this._isMounted &&
                    this.setState({ company: this.props.user.company._id });
            }

            if (!permissions.check("EQ-C-A")) {
                const API_RESPONSE_TYPES = await axios.get(
                    `${window.API}/equipment/types?company=${this.props.user.company._id}`,
                    {
                        headers: { Authorization: `Bearer ${getToken()}` },
                    },
                );

                if (
                    API_RESPONSE_TYPES.status === 200 &&
                    API_RESPONSE_TYPES.data.success
                ) {
                    this._isMounted &&
                        this.setState({
                            typesData: API_RESPONSE_TYPES.data.equipmentTypes,
                        });
                }
            }

            store.dispatch(set({ loading: false }));
        } catch (err) {
            unknownErrorAlert();
        }
    }

    inputChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    onChange = nextTargetKeys => {
        this.setState({ targetKeys: nextTargetKeys });
    };

    async handleAdd(e) {
        e.preventDefault();

        if (
            !this.state.model ||
            this.state.model === "def" ||
            !this.state.type ||
            this.state.type === "def" ||
            !this.state.manufacture ||
            this.state.manufacture === "def" ||
            !this.state.numberPlate ||
            !this.state.company
        ) {
            message.warn("You must fill all required fields!");
            return;
        }

        this.setState({ loading: true });

        const REQUEST_DATA = {
            numberPlate: this.state.numberPlate,
            type: this.state.type,
            newType: this.state.newType,
            manufacture: this.state.manufacture,
            newManufacture: this.state.newManufacture,
            model: this.state.model,
            newModel: this.state.newModel,
            company: this.state.company,
        };

        try {
            const API_RESPONSE = await axios.post(
                `${window.API}/equipment`,
                REQUEST_DATA,
                {
                    headers: { Authorization: `Bearer ${getToken()}` },
                },
            );

            if (API_RESPONSE.data.success && API_RESPONSE.status === 200) {
                message.success("Successfully added new equipment!");

                history.push(
                    "/equipment/" +
                        API_RESPONSE.data.equipment.company._id +
                        "/" +
                        API_RESPONSE.data.equipment._id,
                );
            } else {
                message.warning(API_RESPONSE.data.message);
            }
        } catch (err) {
            unknownErrorMessage(
                err.response ? err.response.data.message : null,
            );
        }

        this._isMounted && this.setState({ loading: false });
    }

    async companyChange(value) {
        this._isMounted &&
            this.setState({
                company: value,
                typesData: [],
                manufacturesData: [],
                modelsData: [],
                type: "def",
                newType: null,
                manufacture: "def",
                newManufacture: null,
                model: "def",
                newModel: null,
                selectTypeLoading: true,
            });

        try {
            const API_RESPONSE_TYPES = await axios.get(
                `${window.API}/equipment/types?company=${value}`,
                {
                    headers: { Authorization: `Bearer ${getToken()}` },
                },
            );

            if (
                API_RESPONSE_TYPES.status === 200 &&
                API_RESPONSE_TYPES.data.success
            ) {
                this._isMounted &&
                    this.setState({
                        typesData: API_RESPONSE_TYPES.data.equipmentTypes,
                        selectTypeLoading: false,
                    });
            } else unknownErrorMessage();
        } catch (err) {
            unknownErrorMessage(
                err.response ? err.response.data.message : null,
            );
        }
    }

    async typeChange(value) {
        this._isMounted &&
            this.setState({
                type: value,
                manufacturesData: [],
                modelsData: [],
                manufacture: "def",
                newManufacture: null,
                model: "def",
                newModel: null,
                selectManufactureLoading: true,
            });

        if (value !== "new") {
            try {
                const API_RESPONSE_TYPES = await axios.get(
                    `${window.API}/equipment/manufactures?company=${this.state.company}&type=${value}`,
                    {
                        headers: { Authorization: `Bearer ${getToken()}` },
                    },
                );

                if (
                    API_RESPONSE_TYPES.status === 200 &&
                    API_RESPONSE_TYPES.data.success
                ) {
                    this._isMounted &&
                        this.setState({
                            manufacturesData:
                                API_RESPONSE_TYPES.data.equipmentManufactures,
                            selectManufactureLoading: false,
                        });
                } else unknownErrorMessage();
            } catch (err) {
                unknownErrorMessage(
                    err.response ? err.response.data.message : null,
                );
            }
        }
    }

    async manufactureChange(value) {
        this._isMounted &&
            this.setState({
                manufacture: value,
                modelsData: [],
                model: "def",
                newModel: null,
                selectModelLoading: true,
            });

        if (value !== "new") {
            try {
                const API_RESPONSE_TYPES = await axios.get(
                    `${window.API}/equipment/models?company=${this.state.company}&type=${this.state.type}&manufacture=${value}`,
                    {
                        headers: { Authorization: `Bearer ${getToken()}` },
                    },
                );

                if (
                    API_RESPONSE_TYPES.status === 200 &&
                    API_RESPONSE_TYPES.data.success
                ) {
                    this._isMounted &&
                        this.setState({
                            modelsData: API_RESPONSE_TYPES.data.equipmentModels,
                            selectModelLoading: false,
                        });
                } else unknownErrorMessage();
            } catch (err) {
                unknownErrorMessage(
                    err.response ? err.response.data.message : null,
                );
            }
        }
    }

    render() {
        return (
            <div>
                <Form onSubmit={e => this.handleAdd(e)} method="POST">
                    <Header
                        buttons={
                            <Button
                                loading={this.state.loading}
                                htmlType="submit"
                            >
                                Add equipment
                            </Button>
                        }
                        style={{ paddingBottom: 12 }}
                    >
                        Add equipment
                    </Header>

                    <Content>
                        <Row gutter={42}>
                            <Col lg={12} md={24} style={{ marginBottom: 42 }}>
                                <Card title="Equipment basic">
                                    {permissions.check("EQ-C-A") ? (
                                        <Form.Item label="Company">
                                            <Select
                                                name="company"
                                                defaultValue="def"
                                                onChange={value =>
                                                    this.companyChange(value)
                                                }
                                                required
                                            >
                                                <Option disabled value="def">
                                                    Choose company
                                                </Option>
                                                {this.state.companiesData.map(
                                                    (company, index) => (
                                                        <Option
                                                            key={index}
                                                            value={company._id}
                                                        >
                                                            {
                                                                company.companyName
                                                            }
                                                        </Option>
                                                    ),
                                                )}
                                            </Select>
                                        </Form.Item>
                                    ) : (
                                        ""
                                    )}
                                    <Form.Item label="Type">
                                        <Select
                                            onChange={value =>
                                                this.typeChange(value)
                                            }
                                            value={this.state.type}
                                            defaultValue="def"
                                            loading={
                                                this.state.selectTypeLoading
                                            }
                                            dropdownRender={menu =>
                                                this.DropdownMenu(menu, "type")
                                            }
                                        >
                                            <Option disabled value="def">
                                                {this.state.company
                                                    ? "Choose type"
                                                    : "Choose company first"}
                                            </Option>
                                            {this.state.typesData.length > 0 &&
                                                this.state.typesData.map(
                                                    (type, index) => (
                                                        <Option
                                                            disabled={
                                                                type.disabled
                                                            }
                                                            key={
                                                                "type-" + index
                                                            }
                                                            value={type._id}
                                                        >
                                                            {type.name}
                                                        </Option>
                                                    ),
                                                )}
                                            {this.state.newType && (
                                                <Option value="new">
                                                    {this.state.newType}
                                                </Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Manufacture">
                                        <Select
                                            onSelect={value =>
                                                this.manufactureChange(value)
                                            }
                                            loading={
                                                this.state
                                                    .selectManufactureLoading
                                            }
                                            value={this.state.manufacture}
                                            defaultValue="def"
                                            dropdownRender={menu =>
                                                this.DropdownMenu(
                                                    menu,
                                                    "manufacture",
                                                )
                                            }
                                        >
                                            <Option disabled value="def">
                                                {this.state.type &&
                                                this.state.type !== "def"
                                                    ? "Choose manufacture"
                                                    : "Choose type first"}
                                            </Option>
                                            {this.state.manufacturesData
                                                .length > 0 &&
                                                this.state.manufacturesData.map(
                                                    (manufacture, index) => (
                                                        <Option
                                                            disabled={
                                                                manufacture.disabled
                                                            }
                                                            key={
                                                                "manufacture-" +
                                                                index
                                                            }
                                                            value={
                                                                manufacture._id
                                                            }
                                                        >
                                                            {manufacture.name}
                                                        </Option>
                                                    ),
                                                )}
                                            {this.state.newManufacture && (
                                                <Option value="new">
                                                    {this.state.newManufacture}
                                                </Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Model">
                                        <Select
                                            onSelect={model =>
                                                this.setState({ model })
                                            }
                                            loading={
                                                this.state.selectModelLoading
                                            }
                                            value={this.state.model}
                                            defaultValue="def"
                                            dropdownRender={menu =>
                                                this.DropdownMenu(menu, "model")
                                            }
                                        >
                                            <Option disabled value="def">
                                                {this.state.manufacture &&
                                                this.state.manufacture !== "def"
                                                    ? "Choose model"
                                                    : "Choose manufacture first"}
                                            </Option>
                                            {this.state.modelsData.length > 0 &&
                                                this.state.modelsData.map(
                                                    (model, index) => (
                                                        <Option
                                                            disabled={
                                                                model.disabled
                                                            }
                                                            key={
                                                                "model-" + index
                                                            }
                                                            value={model._id}
                                                        >
                                                            {model.name}
                                                        </Option>
                                                    ),
                                                )}
                                            {this.state.newModel && (
                                                <Option value="new">
                                                    {this.state.newModel}
                                                </Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Card>
                            </Col>
                            <Col lg={12} md={24} style={{ marginBottom: 42 }}>
                                <Card title="More information">
                                    <Form.Item label="Number plate">
                                        <Input
                                            name="numberPlate"
                                            required
                                            onChange={e => this.inputChange(e)}
                                            placeholder="e.g. OK125486"
                                        />
                                    </Form.Item>
                                </Card>
                            </Col>
                        </Row>
                    </Content>
                </Form>
                <Modal
                    value={this.state.modalValue}
                    addNew={this.addNewField}
                    closeModal={this.closeModal}
                    type={this.state.modalType}
                    visible={this.state.modal}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
