import React, { Component } from 'react'
import { connect } from 'react-redux'
import Header from "../../../components/Header"
import Content from "../../../components/Content"
import { Socket } from "../../../socket"

export class Home extends Component {

    componentDidMount() {

        Socket.conn && Socket.conn.on("get-data", data => {
            console.log(data);
        })

    }

    componentWillUnmount() {
        Socket.conn && Socket.conn.removeListener("get-data");
    }


    render() {
        return (
            <div>
                <Header>Dashboard</Header>
                <Content>
                    Dashboard
                </Content>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
