import React, { Component } from "react";
import Header from "../../../../components/Header";
import Content from "../../../../components/Content";
import { Button, Row, Col, Card, Modal, Form, Input, message } from "antd";
import { permissions } from "../../../../utils/permissions";
import history from "../../../../history";
import { set } from "../../../../_actions/appActions";
import store from "../../../../store";
import axios from "axios";
import { getToken } from "../../../../utils/token";
import { unknownErrorMessage } from "../../../../utils/app";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class Index extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            modalVisible: false,
            password: null,
            newPassword: null,
            newPassword2: null,
            buttonLoading: false,
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidMount() {
        this._isMounted = true;
        if (!permissions.check("USER-R-O")) history.push("/");
        this._isMounted &&
            store.dispatch(
                set({
                    breadcrumb: [{ name: "Account", href: "/account" }],
                }),
            );
    }

    handleOk = async e => {
        if (
            this.state.password === null ||
            this.state.newPassword === null ||
            this.state.newPassword2 === null
        ) {
            message.warning("You must fill all required fields!");
            return;
        }

        if (this.state.newPassword !== this.state.newPassword2) {
            message.warning("Passwords are not equal.");
            return;
        }

        if (this.state.newPassword.length > 150) {
            message.warning(
                "New password field must be no longer than 150 characters.",
            );
            return;
        }

        if (this.state.newPassword.length < 8) {
            message.warning("The password must be at least 8 characters long.");
            return;
        }

        this.setState({ buttonLoading: true });

        const REQUEST_DATA = {
            password: this.state.password,
            newPassword: this.state.newPassword,
            newPassword2: this.state.newPassword2,
        };

        try {
            const API_RESPONSE = await axios.post(
                `${window.API}/users/${this.props.user._id}/changePassword`,
                REQUEST_DATA,
                {
                    headers: { Authorization: `Bearer ${getToken()}` },
                },
            );

            if (API_RESPONSE.data.success && API_RESPONSE.status === 200) {
                message.success("Successfully changes password.");
                this.setState({
                    modalVisible: false,
                    password: null,
                    newPassword: null,
                    newPassword2: null,
                });
            } else {
                unknownErrorMessage(API_RESPONSE.data.message);
            }
        } catch (err) {
            unknownErrorMessage(err.response ? err.response.data.message : null);
        }

        this.setState({ buttonLoading: false });
    };

    handleCancel = e => {
        if (!this.state.buttonLoading)
            this.setState({
                modalVisible: false,
                password: null,
                newPassword: null,
                newPassword2: null,
            });
    };

    inputChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    render() {
        return (
            <div>
                <Header
                    extra={
                        permissions.check("USER-U-O") && (
                            <Button
                                size="small"
                                onClick={e =>
                                    this.setState({ modalVisible: true })
                                }
                            >
                                Change password
                            </Button>
                        )
                    }
                >
                    Account
                </Header>
                <Content>
                    <Row gutter={42}>
                        <Col style={{ marginBottom: 42 }} lg={12} md={24}>
                            <Card title="User credentials">
                                <p>
                                    <strong>Username:</strong>{" "}
                                    {this.props.user.username}
                                </p>
                                <p>
                                    <strong>E-mail address:</strong>{" "}
                                    {this.props.user.email}
                                </p>
                            </Card>
                        </Col>
                        <Col style={{ marginBottom: 42 }} lg={12} md={24}>
                            <Card title="User details">
                                <p>
                                    <strong>Full name:</strong>{" "}
                                    {this.props.user.fullName}
                                </p>
                                <p>
                                    <strong>Phone number:</strong>{" "}
                                    {this.props.phoneNumber
                                        ? this.props.user.phoneNumber
                                        : "none"}
                                </p>
                                <p>
                                    <strong>Company:</strong>{" "}
                                    <Link
                                        to={
                                            "/companies/" +
                                            (this.props.user.company
                                                ? this.props.user.company
                                                      .company
                                                : "")
                                        }
                                    >
                                        {this.props.user.company
                                            ? this.props.user.company
                                                  .companyName
                                            : ""}
                                    </Link>
                                </p>
                            </Card>
                        </Col>
                    </Row>
                </Content>

                {permissions.check("USER-U-O") && (
                    <Modal
                        title="Change password"
                        visible={this.state.modalVisible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={
                            <Button
                                loading={this.state.buttonLoading}
                                onClick={this.handleOk}
                                type="primary"
                            >
                                Update
                            </Button>
                        }
                    >
                        <Form.Item label="Current password">
                            <Input
                                name="password"
                                required
                                onChange={e => this.inputChange(e)}
                                placeholder="Your current password"
                                value={this.state.password}
                            />
                        </Form.Item>
                        <Form.Item label="New password">
                            <Input
                                type="password"
                                name="newPassword"
                                required
                                onChange={e => this.inputChange(e)}
                                placeholder="New password"
                                value={this.state.newPassword}
                            />
                        </Form.Item>
                        <Form.Item label="Repeat new password">
                            <Input
                                type="password"
                                name="newPassword2"
                                required
                                onChange={e => this.inputChange(e)}
                                placeholder="New password"
                                value={this.state.newPassword2}
                            />
                        </Form.Item>
                    </Modal>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
});

export default connect(mapStateToProps)(Index);
