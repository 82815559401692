import React, { Component } from "react";
import { Modal, Form, Input, message } from "antd";

export default class AddModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "Modal",
            name: null
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.value !== prevProps.value) {
            this.setState({ name: this.props.value });
        }
    }

    handleClose = () => {
        this.setState({ name: null });
        this.props.closeModal();
    };

    handleAdd = () => {
        if (!this.state.name) {
            message.warn("You must provide the name!");
            return;
        }

        if (this.state.name.length > 250) {
            message.warn(
                "The name field must be no longer than 250 characters!"
            );
            return;
        }

        this.setState({ name: null });
        this.props.addNew(this.state.name, this.props.type);
        this.props.closeModal();
    };

    inputChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        let title = "Modal",
            placeholder = "";
        switch (this.props.type) {
            case "type":
                title = "Add type";
                placeholder = "e.g. Bus";
                break;
            case "manufacture":
                title = "Add manufacture";
                placeholder = "e.g. Audi";
                break;
            case "model":
                title = "Add model";
                placeholder = "e.g. T5";
                break;
            default:
                title = "Model";
                placeholder = "";
        }

        return (
            <Modal
                onOk={this.handleAdd}
                okText={this.props.value ? "Edit" : "Add"}
                onCancel={this.handleClose}
                visible={this.props.visible}
                title={title}
            >
                <Form.Item label="Name">
                    <Input
                        value={this.state.name}
                        placeholder={placeholder}
                        name="name"
                        onChange={e => this.inputChange(e)}
                    />
                </Form.Item>
            </Modal>
        );
    }
}

AddModal.defaultProps = {
    visible: false
};
