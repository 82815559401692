import React, { Component } from "react";
import { connect } from "react-redux";
import { set } from "../../../../_actions/appActions";
import store from "../../../../store";
import Header from "../../../../components/Header";
import Content from "../../../../components/Content";
import { Table, Tag, Modal, Button, Card, message, Icon } from "antd";
import { permissions } from "../../../../utils/permissions";
import fontColorContrast from "font-color-contrast";
import history from "../../../../history";
import axios from "axios";
import { getToken } from "../../../../utils/token";
import { unknownErrorAlert, unknownErrorMessage } from "../../../../utils/app";

const { confirm } = Modal;

export class Companies extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            dataSource: [],
            modalVisible: false,
            currentRecord: null,
        };

        this.userColumns = [
            {
                title: "Username",
                dataIndex: "username",
                key: "username",
            },
            {
                title: "E-mail",
                dataIndex: "email",
                key: "email",
            },
            {
                title: "Phone number",
                dataIndex: "phoneNumber",
                key: "phoneNumber",
            },
            {
                title: "Role",
                dataIndex: "role",
                key: "role",
                render: role => {
                    return role ? (
                        <Tag color={role.color}>
                            <span
                                style={{ color: fontColorContrast(role.color) }}
                            >
                                {role.roleName}
                            </span>
                        </Tag>
                    ) : (
                        ""
                    );
                },
            },
        ];

        this.roleColums = [
            {
                title: "Role name",
                dataIndex: "roleName",
                key: "roleName",
            },
            {
                title: "Permissions",
                dataIndex: "permissions",
                key: "permissions",
                render: (permissions, record) => (
                    <span>
                        {permissions.map((permission, index) => {
                            if (index < 5) {
                                let color = "geekblue";
                                return (
                                    <Tag
                                        style={{ marginBottom: ".8em" }}
                                        color={color}
                                        key={
                                            String(record.role) +
                                            String(permission._id)
                                        }
                                        title={permission.description}
                                    >
                                        {permission.name.toUpperCase()}
                                    </Tag>
                                );
                            } else if (index === 5) {
                                return (
                                    <Tag
                                        style={{ marginBottom: ".8em" }}
                                        color={"green"}
                                        key="more"
                                    >
                                        {permissions.length - 5} more
                                    </Tag>
                                );
                            } else return null;
                        })}
                    </span>
                ),
            },
        ];

        this.columns = [
            {
                title: "",
                dataIndex: "info",
                key: "info",
                render: (el, record) => {
                    return (
                        <Icon
                            style={{ fontSize: 18 }}
                            onClick={e => this.openModal(e, record)}
                            type="info-circle"
                            theme="twoTone"
                        />
                    );
                },
            },
            {
                title: "Company name",
                dataIndex: "companyName",
                key: "companyName",
            },
            {
                title: "Company",
                dataIndex: "company",
                key: "company",
            },
            {
                title: "Address",
                dataIndex: "address",
                key: "address",
            },
            permissions.check("USER-R")
                ? {
                      title: "Users",
                      dataIndex: "users",
                      key: "users",
                      render: users =>
                          users ? <span>{users.length}</span> : "",
                  }
                : {},
        ];
    }

    openModal(e, record) {
        e.stopPropagation();

        this.setState({ modalVisible: true, currentRecord: record });
    }

    async handleDelete(e) {
        e.stopPropagation();

        confirm({
            title: "Are you sure delete this company?",
            content: "This operation is permament.",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: async e => {
                try {
                    const API_RESPONSE = await axios.delete(
                        `${window.API}/companies/${this.state.currentRecord._id}`,
                        {
                            headers: { Authorization: `Bearer ${getToken()}` },
                        },
                    );

                    if (
                        API_RESPONSE.status === 200 &&
                        API_RESPONSE.data.success === true
                    ) {
                        message.success("Successfully deleted the company.");

                        this.setState({
                            modalVisible: false,
                            dataSource: this.state.dataSource.filter(
                                el =>
                                    el.company !==
                                    this.state.currentRecord.company,
                            ),
                        });
                    } else {
                        message.warning(API_RESPONSE.data.message);
                    }
                } catch (err) {
                    unknownErrorMessage(
                        err.response ? err.response.data.message : null,
                    );
                }
            },
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidMount() {
        this._isMounted = true;
        this._isMounted &&
            store.dispatch(
                set({
                    loading: true,
                    breadcrumb: [{ name: "Companies", href: "/companies" }],
                }),
            );

        try {
            const API_RESPONSE = await axios.get(`${window.API}/companies`, {
                headers: { Authorization: `Bearer ${getToken()}` },
            });

            if (API_RESPONSE.status === 200 && API_RESPONSE.data.success) {
                this._isMounted &&
                    this.setState({ dataSource: API_RESPONSE.data.companies });
                this._isMounted && store.dispatch(set({ loading: false }));
            }
        } catch (err) {
            unknownErrorAlert();
        }
    }

    handleOk = e => {
        this.setState({
            modalVisible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            modalVisible: false,
        });
    };

    render() {
        if (!permissions.check("COMPANY-R")) return <div />;

        return (
            <div>
                <Header
                    extra={
                        permissions.check("COMPANY-C") ? (
                            <Button
                                onClick={e => history.push("/companies/add")}
                                size="small"
                            >
                                Add
                            </Button>
                        ) : (
                            ""
                        )
                    }
                >
                    Companies
                </Header>
                <Content>
                    <Table
                        rowKey={record => record._id}
                        rowClassName="table-row"
                        columns={this.columns}
                        dataSource={this.state.dataSource}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event =>
                                    history.push(
                                        "/companies/" + record.company,
                                    ),
                            };
                        }}
                    />

                    <Modal
                        title={
                            this.state.currentRecord
                                ? "Company: " +
                                  this.state.currentRecord.companyName
                                : ""
                        }
                        visible={this.state.modalVisible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                            permissions.check("COMPANY-U") ? (
                                <Button
                                    onClick={e =>
                                        history.push(
                                            "/companies/" +
                                                (this.state.currentRecord
                                                    ? this.state.currentRecord
                                                          .company
                                                    : "") +
                                                "/update",
                                        )
                                    }
                                    type="ghost"
                                    color="gold"
                                    key="update"
                                >
                                    Update
                                </Button>
                            ) : (
                                ""
                            ),
                            permissions.check("COMPANY-D") ? (
                                <Button
                                    type="danger"
                                    key="delete"
                                    onClick={e => this.handleDelete(e)}
                                >
                                    Delete
                                </Button>
                            ) : (
                                ""
                            ),
                            <Button
                                key="return"
                                onClick={e =>
                                    history.push(
                                        "/companies/" +
                                            (this.state.currentRecord
                                                ? this.state.currentRecord
                                                      .company
                                                : ""),
                                    )
                                }
                            >
                                Show company
                            </Button>,
                        ]}
                    >
                        <p>
                            <strong>Company:</strong>{" "}
                            {this.state.currentRecord
                                ? this.state.currentRecord.company
                                : ""}
                        </p>
                        <p>
                            <strong>Address:</strong>{" "}
                            {this.state.currentRecord
                                ? this.state.currentRecord.address
                                : ""}
                        </p>
                        <Card size="small" title="Permissions">
                            {this.state.currentRecord
                                ? this.state.currentRecord.permissions.map(
                                      permission => {
                                          return (
                                              <Tag
                                                  style={{
                                                      marginBottom: ".8em",
                                                  }}
                                                  color="geekblue"
                                                  key={
                                                      "permission-" +
                                                      permission._id
                                                  }
                                                  title={permission.description}
                                              >
                                                  {permission.name}
                                              </Tag>
                                          );
                                      },
                                  )
                                : ""}
                        </Card>

                        {permissions.check("USER-R") ? (
                            <div>
                                <p
                                    style={{
                                        marginTop: "2em",
                                        marginBottom: "1em",
                                    }}
                                >
                                    <strong>Users</strong>
                                </p>
                                <Table
                                    rowKey={record => record._id}
                                    rowClassName="table-row"
                                    size="middle"
                                    columns={this.userColumns}
                                    dataSource={
                                        this.state.currentRecord
                                            ? this.state.currentRecord.users
                                            : []
                                    }
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: event =>
                                                history.push(
                                                    "/users/" + record.username,
                                                ),
                                        };
                                    }}
                                ></Table>
                            </div>
                        ) : (
                            ""
                        )}

                        {permissions.check("ROLE-R") ? (
                            <div>
                                <p
                                    style={{
                                        marginTop: "2em",
                                        marginBottom: "1em",
                                    }}
                                >
                                    <strong>Roles</strong>
                                </p>
                                <Table
                                    rowKey={record => record._id}
                                    rowClassName="table-row"
                                    size="middle"
                                    columns={this.roleColums}
                                    dataSource={
                                        this.state.currentRecord
                                            ? this.state.currentRecord.roles
                                            : []
                                    }
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: event =>
                                                history.push(
                                                    "/roles/" +
                                                        (this.state
                                                            .currentRecord
                                                            ? this.state
                                                                  .currentRecord
                                                                  .company
                                                            : "") +
                                                        "/" +
                                                        record.role,
                                                ),
                                        };
                                    }}
                                ></Table>
                            </div>
                        ) : (
                            ""
                        )}
                    </Modal>
                </Content>
            </div>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
