import React, { Component } from "react";
import Header from "../../../../components/Header";
import Content from "../../../../components/Content";
import { Button } from "antd";
import { permissions } from "../../../../utils/permissions";
import history from "../../../../history";
import { set } from "../../../../_actions/appActions";
import store from "../../../../store";
import axios from "axios";
import { getToken } from "../../../../utils/token";
import { unknownErrorAlert } from "../../../../utils/app";

import UsersList from "components/UsersList";

export default class Index extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            dataSource: [],
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidMount() {
        this._isMounted = true;

        if (!permissions.check("USER-R")) history.push("/");

        this._isMounted &&
            store.dispatch(
                set({
                    loading: true,
                    breadcrumb: [{ name: "Users", href: "/users" }],
                }),
            );

        try {
            const API_RESPONSE = await axios.get(`${window.API}/users`, {
                headers: { Authorization: `Bearer ${getToken()}` },
            });

            if (API_RESPONSE.status === 200 && API_RESPONSE.data.success) {
                this._isMounted &&
                    this.setState({ dataSource: API_RESPONSE.data.users });
                this._isMounted && store.dispatch(set({ loading: false }));
            }
        } catch (err) {
            unknownErrorAlert();
        }
    }

    render() {
        return (
            <div>
                <Header
                    extra={
                        permissions.check("USER-C") ? (
                            <Button
                                onClick={e => history.push("/users/add")}
                                size="small"
                            >
                                Add
                            </Button>
                        ) : (
                            ""
                        )
                    }
                >
                    Users
                </Header>
                <Content>
                    <UsersList dataSource={this.state.dataSource} />
                </Content>
            </div>
        );
    }
}
