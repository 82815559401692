import React, { Component } from "react";
import { Row, Col } from "antd";
import { Socket } from "../../../../../socket";
import { connect } from "react-redux";
import { set } from "_actions/appActions";
import store from "../../../../../store";
import axios from "axios";
import { getToken } from "utils/token";
import { unknownErrorAlert } from "utils/app";

import { Table, Tag, Button, Modal, message, Form, Input } from "antd";
import fontColorContrast from "font-color-contrast";

export class ActiveUsersList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalNotification: false,
            selectedUser: null,

            notificationTitle: null,
            notificationDesc: null,
            notificationIcon: null,
        };

        this.columns = [
            {
                title: "Username",
                dataIndex: "username",
                key: "username",
            },
            {
                title: "E-mail",
                dataIndex: "email",
                key: "email",
            },
            {
                title: "Type",
                dataIndex: "type",
                key: "type",
                filters: [
                    { text: "Native", value: "native" },
                    { text: "Website", value: "web" },
                ],
                filterMultiple: false,
                onFilter: (value, record) => record.type.indexOf(value) === 0,
                render: type => <Tag>{type}</Tag>,
            },
            {
                title: "Active sockets",
                dataIndex: "sockets",
                key: "sockets",
                render: sockets => sockets.length,
                sorter: (a, b) => a.sockets.length - b.sockets.length,
            },
            {
                title: "Role",
                dataIndex: "role",
                key: "role",
                render: role => (
                    <Tag color={role.color}>
                        <span style={{ color: fontColorContrast(role.color) }}>
                            {role.roleName}
                        </span>
                    </Tag>
                ),
            },
            {
                title: "Company",
                dataIndex: "company",
                key: "company",
                render: company => (company ? company.companyName : ""),
            },
            {
                title: "",
                dataIndex: "actions",
                key: "actions",
                render: (actions, row) => (
                    <div style={{ float: "right" }}>
                        <Button
                            onClick={e => this.handleButtonNotification(row)}
                        >
                            Send notification
                        </Button>
                        <Button
                            style={{ marginLeft: 16 }}
                            onClick={e => this.handleButtonLogout(row)}
                        >
                            Logout
                        </Button>
                    </div>
                ),
            },
        ];
    }

    handleButtonNotification = selectedUser => {
        this.setState({ modalNotification: true, selectedUser });
    };

    handleButtonLogout = user => {
        Modal.confirm({
            title: `Are you sure you want to log out ${user.username}?`,
            okText: "Yes",
            cancelText: "No",
            onOk: () => {
                Socket.conn &&
                    Socket.conn.emit("logout", {
                        user: user.userId,
                        type: user.type,
                    });

                message.success(`Logged out ${user.username}`);
            },
        });
    };

    handleClose = () => {
        this.setState({ modalNotification: false, selectedUser: null });
    };

    handleSendNotification = () => {
        Modal.confirm({
            title: "Are you sure you want to send a notification?",
            okText: "Yes",
            cancelText: "No",
            onOk: () => {
                console.log(this.state.selectedUser._id);
                Socket.conn &&
                    Socket.conn.emit("notification", {
                        user: this.state.selectedUser._id,
                        message: this.state.notificationTitle,
                        description: this.state.notificationDesc,
                        icon: this.state.notificationIcon,
                        type: this.state.selectedUser.type,
                    });

                message.success("Notification sent");
                this.handleClose();
            },
        });
    };

    inputChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    render() {
        return (
            <div>
                <Table
                    rowKey={row => row.type + "-" + row.socketId}
                    dataSource={this.props.dataSource}
                    columns={this.columns}
                />
                <Modal
                    title="Send notification"
                    onCancel={this.handleClose}
                    onOk={this.handleSendNotification}
                    visible={this.state.modalNotification}
                >
                    <Form.Item label="Title">
                        <Input
                            name="notificationTitle"
                            placeholder="Notification title"
                            onChange={e => this.inputChange(e)}
                        />
                    </Form.Item>
                    <Form.Item label="Description">
                        <Input.TextArea
                            name="notificationDesc"
                            placeholder="Notification description"
                            onChange={e => this.inputChange(e)}
                        />
                    </Form.Item>
                    <Form.Item label="Icon">
                        <Input
                            name="notificationIcon"
                            placeholder="Notification icon"
                            onChange={e => this.inputChange(e)}
                        />
                    </Form.Item>
                </Modal>
            </div>
        );
    }
}

class Users extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            updateCount: 0,
            users: [],
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        this._isMounted && store.dispatch(set({ loading: true }));

        try {
            const API_RESPONSE = await axios.get(`${window.API}/users/active`, {
                headers: { Authorization: `Bearer ${getToken()}` },
            });

            if (API_RESPONSE.status === 200 && API_RESPONSE.data.success) {
                let users = [];
                API_RESPONSE.data.users.forEach(user => {
                    let index = users.findIndex(
                        u => u.userId === user.userId && u.type === user.type,
                    );
                    if (index !== -1) {
                        users[index].sockets.push(user.socketId);
                    } else {
                        user.sockets = [user.socketId];
                        users.push(user);
                    }
                });

                this._isMounted && this.setState({ users });
                this._isMounted && store.dispatch(set({ loading: false }));
            }
        } catch (err) {
            unknownErrorAlert();
        }
    }

    _socketComplete = false;
    componentDidUpdate(prevProps, prevState) {
        if (
            !this._socketComplete &&
            (this.props.app.socket || prevProps.app.socket)
        ) {
            this._socketComplete = true;

            Socket.conn && Socket.conn.emit("join active-socket-update");
            Socket.conn &&
                Socket.conn.on("active-socket-update", ({ user, type }) => {
                    let users = this.state.users;
                    let index = users.findIndex(
                        u => u.userId === user.userId && u.type === user.type,
                    );

                    if (type === "connect") {
                        if (index !== -1) {
                            users[index].sockets.push(user.socketId);
                        } else {
                            user.sockets = [user.socketId];
                            users.push(user);
                        }
                    } else if (type === "disconnect") {
                        if (index !== -1) {
                            let socketIndex = users[index].sockets.indexOf(
                                user.socketId,
                            );
                            users[index].sockets.splice(socketIndex, 1);

                            if (users[index].sockets.length === 0) {
                                users.splice(index, 1);
                            }
                        }
                    }

                    this.setState({ users });
                });
        }
    }

    handleAddUser(user) {
        let users = this.state.users;
        let obj = users.find(
            u => u.userId === user.userId && u.type === user.type,
        );

        if (obj) {
            obj.sockets.push(user.socketId);
        } else {
            user.sockets = [user.socketId];
            users.push(user);
        }

        this.setState({ users });
        // TODO Naprawić bo nie updatuje dziecka
    }

    componentWillUnmount() {
        this._isMounted = false;

        Socket.conn && Socket.conn.emit("leave active-socket-update");
        Socket.conn && Socket.conn.removeListener("active-socket-update");
    }

    render() {
        return (
            <div>
                <Row gutter={42}>
                    <Col>
                        <ActiveUsersList dataSource={this.state.users} />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    app: state.app,
});

export default connect(mapStateToProps)(Users);
