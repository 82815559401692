import React, { Component } from 'react'
import { Switch, Route, Redirect } from "react-router-dom";
import { permissions } from "../../../utils/permissions"

import System from "./System/index.js";
import Settings from "./Settings";

export default class index extends Component {
    render() {
        if (!permissions.oneOf(["SYSTEM-R", "SYSTEM-U"])) return <Redirect to="/" />

        return (
            <div>
                <Switch>
                    <Route exact path="/system" component={System} />
                    <Route exact path="/system/settings" component={Settings} />
                </Switch>
            </div>
        )
    }
}
