import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Modal, Form, Input, message } from "antd";
import { Point } from "ol/geom";
import { transform } from "ol/proj";
import { Select, Translate } from "ol/interaction.js";
import { Style, Icon } from "ol/style";
import { permissions } from "utils/permissions";
import store from "store";
import Feature from "ol/Feature";
import { set } from "_actions/appActions";
import axios from "axios";
import { getToken } from "utils/token";
import { unknownErrorMessage } from "utils/app";

const { confirm } = Modal;
let feature_id = 0;

var iconStyle = new Style({
    image: new Icon({
        anchor: [0.5, 38],
        anchorXUnits: "fraction",
        anchorYUnits: "pixels",
        src: require("../../../icons/marker.png"),
    }),
});

export class Spots extends Component {
    state = {
        editSpotsMode: false,
        currentEditState: null,
        addSpotModal: false,
        newSpotFeature: null,
        viewSpotModal: false,
        viewSpotFeature: null,
        deletedSpots: [],

        featureName: null,
        featureDesc: null,
        featureLat: null,
        featureLong: null,
    };

    _setup = false;
    async componentDidUpdate(prevProps, prevState) {
        let features = this.props.source && this.props.source.getFeatures();
        features &&
            features.forEach(feature => {
                if (this.props.hide) {
                    feature.setStyle(new Style({}));
                } else feature.setStyle(iconStyle);
            });

        if (prevProps.source !== this.props.source && !this._setup) {
            let select = new Select({
                filter: feature =>
                    feature.get("name") === "pickup-spot" ||
                    feature.get("name") === "pickup-spot-new",
            });

            let translate = new Translate({
                features: select.getFeatures(),
            });

            await this.setState({ select, translate });

            if (this.props.showEditMode && this.props.map) {
                this.props.map.addInteraction(select);
            }

            if (
                this.props.showPickupSpots &&
                this.props.company &&
                this.props.company.spots
            ) {
                this._setup = true;
                this.props.company.spots.forEach(spot => {
                    let feature = new Feature({
                        name: "pickup-spot",
                        object_id: spot._id,
                        spotName: spot.name,
                        description: spot.description,
                        lat: spot.location.latitude,
                        long: spot.location.longitude,
                        geometry: new Point(
                            transform(
                                [
                                    spot.location.longitude,
                                    spot.location.latitude,
                                ],
                                "EPSG:4326",
                                "EPSG:3857",
                            ),
                        ),
                    });

                    if (this.props.hide) {
                        feature.setStyle(new Style({}));
                    } else feature.setStyle(iconStyle);
                    this.props.source.addFeature(feature);
                });
            }

            this.props.map.on("click", evt => {
                var feature = this.props.map.forEachFeatureAtPixel(
                    evt.pixel,
                    function(feature) {
                        return feature;
                    },
                );

                if (
                    feature &&
                    (feature.get("name") === "pickup-spot" ||
                        feature.get("name") === "pickup-spot-new")
                ) {
                    if (this.state.currentEditState == null) {
                        this.setState({
                            viewSpotModal: true,
                            viewSpotFeature: feature,
                            featureName: feature.get("spotName"),
                            featureDesc: feature.get("description"),
                            featureLat: feature.get("lat"),
                            featureLong: feature.get("long"),
                        });
                    }
                }
            });

            this.props.map.on("pointerdown", evt => {
                var feature = this.props.map.forEachFeatureAtPixel(
                    evt.pixel,
                    function(feature) {
                        return feature;
                    },
                );

                if (
                    feature &&
                    (feature.get("name") === "pickup-spot" ||
                        feature.get("name") === "pickup-spot-new")
                ) {
                    if (this.state.currentEditState === "moveSpot") {
                        this.state.select.getFeatures().clear();
                        this.state.select.getFeatures().push(feature);
                    }
                }
            });
        }
    }

    async handleEditClick() {
        if (this.state.editSpotsMode) {
            store.dispatch(set({ loading: true }));

            let newSpots = [];
            this.props.source
                .getFeatures()
                .filter(feature => feature.get("name") === "pickup-spot-new")
                .forEach(spot => {
                    newSpots.push({
                        name: spot.get("spotName"),
                        description: spot.get("description"),
                        lat: spot.get("lat"),
                        long: spot.get("long"),
                        feature_id: spot.getId(),
                    });
                });

            let updatedSpots = [];
            this.props.source
                .getFeatures()
                .filter(
                    feature =>
                        feature.get("name") === "pickup-spot" &&
                        feature.get("updated") === true,
                )
                .forEach(spot => {
                    updatedSpots.push({
                        object_id: spot.get("object_id"),
                        name: spot.get("spotName"),
                        description: spot.get("description"),
                        lat: spot.get("lat"),
                        long: spot.get("long"),
                    });
                });

            const REQUEST_DATA = {
                newSpots,
                updatedSpots,
                deletedSpots: this.state.deletedSpots,
            };

            try {
                const API_RESPONSE = await axios.put(
                    `${window.API}/spots/${this.props.company &&
                        this.props.company._id}`,
                    REQUEST_DATA,
                    {
                        headers: { Authorization: `Bearer ${getToken()}` },
                    },
                );

                if (API_RESPONSE.data.success && API_RESPONSE.status === 200) {
                    let addedSpots = API_RESPONSE.data.spots;

                    message.success("Successfully updated the pick-up spots!");

                    if (addedSpots) {
                        addedSpots.forEach(spot => {
                            let feature = this.props.source.getFeatureById(
                                spot.feature_id,
                            );

                            feature.setId(spot._id);
                            feature.set("name", "pickup-spot");
                        });
                    }

                    this.props.source.getFeatures().forEach(feature => {
                        if (feature.get("name") === "pickup-spot") {
                            feature.set("updated", false);
                        }
                    });

                    this.setState({ deletedSpots: [] });
                } else {
                    message.warning(API_RESPONSE.data.message);
                }
            } catch (err) {
                unknownErrorMessage(
                    err.response ? err.response.data.message : null,
                );
            }

            store.dispatch(set({ loading: false }));
        } else {
            this.setState({
                filters: { ...this.state.filters, "pickup-spot": true },
            });
            this.props.handleSwitchFilter(
                "pickup-spot",
                true,
                this.props.source,
            );
        }

        this.setState({ editSpotsMode: !this.state.editSpotsMode });
    }

    handleAddSpot() {
        if (this.state.currentEditState === "addSpot") {
            this.setState({ currentEditState: null });
            this.props.map.removeInteraction(this.props.draw);
        } else {
            this.setState({ currentEditState: "addSpot" });
            this.props.map.addInteraction(this.props.draw);
            this.props.map.removeInteraction(this.state.translate);

            this.props.draw.on("drawend", data => {
                data.feature.set("name", "pickup-spot");
                data.feature.setId(++feature_id);

                this.props.map.removeInteraction(this.props.draw);

                let coords = data.feature
                    .getGeometry()
                    .transform("EPSG:3857", "EPSG:4326")
                    .getCoordinates();
                data.feature
                    .getGeometry()
                    .transform("EPSG:4326", "EPSG:3857")
                    .getCoordinates();
                data.feature.setStyle(iconStyle);

                this.setState({
                    addSpotModal: true,
                    newSpotFeature: data.feature,
                    currentEditState: null,
                    featureName: null,
                    featureDesc: null,
                    featureLat: coords[1],
                    featureLong: coords[0],
                });
            });
        }
    }

    handleMoveSpot() {
        if (this.state.currentEditState === "moveSpot") {
            this.setState({ currentEditState: null });
            this.props.map.removeInteraction(this.state.translate);
        } else {
            this.setState({ currentEditState: "moveSpot" });
            this.props.map.removeInteraction(this.props.draw);
            this.props.map.addInteraction(this.state.translate);

            this.state.translate.on("translateend", data => {
                let feature = data.features.getArray();
                if (feature.length === 1) {
                    feature = feature[0];

                    let coords = feature
                        .getGeometry()
                        .transform("EPSG:3857", "EPSG:4326")
                        .getCoordinates();
                    feature
                        .getGeometry()
                        .transform("EPSG:4326", "EPSG:3857")
                        .getCoordinates();

                    feature.setProperties({
                        lat: coords[1],
                        long: coords[0],
                    });

                    if (feature.get("name") === "pickup-spot") {
                        feature.set("updated", true);
                    }
                }
            });
        }
    }

    renderSpotsMenu() {
        return (
            <div style={{ display: "inline-block", marginLeft: 12 }}>
                {permissions.check("SPOT-C-A") ||
                (permissions.check("SPOT-C-C") &&
                    this.props.company &&
                    this.props.user &&
                    this.props.company._id === this.props.user.company._id) ? (
                    <Button
                        type={
                            this.state.currentEditState === "addSpot"
                                ? "primary"
                                : "default"
                        }
                        onClick={e => this.handleAddSpot()}
                    >
                        Add spot
                    </Button>
                ) : (
                    <div />
                )}
                {(permissions.check("SPOT-U-A") ||
                    (permissions.check("SPOT-U-C") &&
                        this.props.company &&
                        this.props.user &&
                        this.props.company._id ===
                            this.props.user.company._id)) && (
                    <Button
                        style={{ marginLeft: 4 }}
                        type={
                            this.state.currentEditState === "moveSpot"
                                ? "primary"
                                : "default"
                        }
                        onClick={e => this.handleMoveSpot()}
                    >
                        Move spot
                    </Button>
                )}
            </div>
        );
    }

    handleCancelViewSpot = () => {
        this.setState({ viewSpotModal: false });
    };

    handleOkAddSpot = () => {
        if (!this.state.featureName) {
            message.warning("You must provide spot name!");
            return;
        }

        this.state.newSpotFeature.setProperties({
            name: "pickup-spot-new",
            spotName: this.state.featureName,
            description: this.state.featureDesc,
            lat: this.state.featureLat,
            long: this.state.featureLong,
        });

        this.setState({ addSpotModal: false });
    };

    handleOkViewSpot = () => {
        if (!this.state.featureName) {
            message.warning("You must provide spot name!");
            return;
        }

        this.state.viewSpotFeature.setProperties({
            spotName: this.state.featureName,
            description: this.state.featureDesc,
            updated: true,
        });

        this.setState({ viewSpotModal: false });
    };

    handleCancelAddSpot = () => {
        this.props.source.removeFeature(this.state.newSpotFeature);
        this.state.select.getFeatures().clear();
        this.setState({ addSpotModal: false, newSpotFeature: null });
    };

    handleDelete = e => {
        e.stopPropagation();

        confirm({
            title: "Are you sure delete this spot?",
            content: "This operation is permament.",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: () => {
                if (
                    this.state.viewSpotFeature.get("name") === "pickup-spot-new"
                ) {
                    this.props.source.removeFeature(this.state.viewSpotFeature);
                } else if (
                    this.state.viewSpotFeature.get("name") === "pickup-spot"
                ) {
                    this.setState({
                        deletedSpots: [
                            ...this.state.deletedSpots,
                            this.state.viewSpotFeature.get("object_id"),
                        ],
                    });
                    this.props.source.removeFeature(this.state.viewSpotFeature);
                }

                this.state.select.getFeatures().clear();
                this.setState({ viewSpotModal: false });
            },
        });
    };

    inputChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    footerDelete = () => {
        if (this.state.editSpotsMode) {
            if (
                permissions.check("SPOT-D-A") ||
                (permissions.check("SPOT-D-C") &&
                    this.props.company &&
                    this.props.user &&
                    this.props.company._id === this.props.user.company._id)
            ) {
                return (
                    <Button
                        type="danger"
                        key="delete"
                        onClick={this.handleDelete}
                    >
                        Delete
                    </Button>
                );
            } else return <div key="delete-none" />;
        } else return <div key="delete-none" />;
    };

    footerSave = () => {
        if (this.state.editSpotsMode) {
            if (
                permissions.check("SPOT-U-A") ||
                (permissions.check("SPOT-U-C") &&
                    this.props.company &&
                    this.props.user &&
                    this.props.company._id === this.props.user.company._id)
            ) {
                return (
                    <Button key="returnView" onClick={this.handleOkViewSpot}>
                        Save
                    </Button>
                );
            } else return <div key="save-none" />;
        } else return <div key="save-none" />;
    };

    render() {
        return (
            <div>
                {this.props.showEditMode &&
                    (permissions.oneOf(["SPOT-C-A", "SPOT-U-A", "SPOT-D-A"]) ||
                        (permissions.oneOf([
                            "SPOT-C-C",
                            "SPOT-U-C",
                            "SPOT-D-C",
                        ]) &&
                            this.props.company &&
                            this.props.user &&
                            this.props.company._id ===
                                this.props.user.company._id)) && (
                        <Row style={{ marginBottom: 16 }}>
                            <Col>
                                <Button
                                    type={
                                        this.state.editSpotsMode
                                            ? "primary"
                                            : "default"
                                    }
                                    onClick={e => this.handleEditClick()}
                                >
                                    {!this.state.editSpotsMode
                                        ? "Edit spots"
                                        : "Save & exit"}
                                </Button>
                                {this.state.editSpotsMode ? (
                                    this.renderSpotsMenu()
                                ) : (
                                    <div />
                                )}
                            </Col>
                        </Row>
                    )}

                <Modal
                    title="New pick-up spot"
                    visible={this.state.addSpotModal}
                    onOk={this.handleOkAddSpot}
                    onCancel={this.handleCancelAddSpot}
                    footer={[
                        <Button key="return" onClick={this.handleOkAddSpot}>
                            Ok
                        </Button>,
                    ]}
                >
                    <Form.Item label="Name">
                        <Input
                            value={this.state.featureName}
                            name="featureName"
                            placeholder="Pick-up spot name"
                            onChange={e => this.inputChange(e)}
                        />
                    </Form.Item>

                    <Form.Item label="Description">
                        <Input.TextArea
                            value={this.state.featureDesc}
                            name="featureDesc"
                            placeholder="Pick-up spot description"
                            onChange={e => this.inputChange(e)}
                        />
                    </Form.Item>

                    <Form.Item label="Latitude">
                        <Input
                            disabled
                            value={this.state.featureLat}
                            name="featureLat"
                            placeholder="Pick-up latitude"
                            onChange={e => this.inputChange(e)}
                        />
                    </Form.Item>

                    <Form.Item label="Longitude">
                        <Input
                            disabled
                            value={this.state.featureLong}
                            name="featureLong"
                            placeholder="Pick-up longitude"
                            onChange={e => this.inputChange(e)}
                        />
                    </Form.Item>
                </Modal>

                <Modal
                    title={`Pick-up spot: ${this.state.viewSpotFeature &&
                        this.state.viewSpotFeature.get("spotName")}`}
                    visible={this.state.viewSpotModal}
                    onCancel={this.handleCancelViewSpot}
                    footer={[this.footerDelete(), this.footerSave()]}
                >
                    {this.state.editSpotsMode &&
                    (permissions.check("SPOT-U-A") ||
                        (permissions.check("SPOT-U-C") &&
                            this.props.company &&
                            this.props.user &&
                            this.props.company._id ===
                                this.props.user.company._id)) ? (
                        <div>
                            <Form.Item label="Name">
                                <Input
                                    value={this.state.featureName}
                                    name="featureName"
                                    placeholder="Pick-up spot name"
                                    onChange={e => this.inputChange(e)}
                                />
                            </Form.Item>

                            <Form.Item label="Description">
                                <Input.TextArea
                                    value={this.state.featureDesc}
                                    name="featureDesc"
                                    placeholder="Pick-up spot description"
                                    onChange={e => this.inputChange(e)}
                                />
                            </Form.Item>

                            <Form.Item label="Latitude">
                                <Input
                                    disabled
                                    value={this.state.featureLat}
                                    name="featureLat"
                                    placeholder="Pick-up latitude"
                                    onChange={e => this.inputChange(e)}
                                />
                            </Form.Item>

                            <Form.Item label="Longitude">
                                <Input
                                    disabled
                                    value={this.state.featureLong}
                                    name="featureLong"
                                    placeholder="Pick-up longitude"
                                    onChange={e => this.inputChange(e)}
                                />
                            </Form.Item>
                        </div>
                    ) : (
                        <div>
                            <p>
                                <strong>Name:</strong> {this.state.featureName}
                            </p>

                            <p>
                                <strong>Description:</strong>{" "}
                                {this.state.featureDesc}
                            </p>

                            <p>
                                <strong>Latitude:</strong>{" "}
                                {this.state.featureLat}
                            </p>

                            <p>
                                <strong>Longitutde:</strong>{" "}
                                {this.state.featureLong}
                            </p>
                        </div>
                    )}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(Spots);
