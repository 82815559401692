import React, { Component } from "react";
import { Modal, Button, message, Input } from "antd";
import styled from "styled-components";
import axios from "axios";
import { getToken } from "../../../../utils/token";
import { unknownErrorAlert, unknownErrorMessage } from "../../../../utils/app";
import QRCode from "qrcode.react";

const Container = styled.div`
    display: flex;
    text-align: center;
    flex-direction: column;
`;

const QRContainer = styled.div`
    margin-top: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
`;

const ButtonContainer = styled.div`
    align-self: center;
    margin-top: 24px;
`;

export default class RegisterModal extends Component {
    state = {
        loading: false,
    };

    handleGenerateKey = async () => {
        this.setState({ loading: true });

        try {
            const API_RESPONSE = await axios.post(
                `${window.API}/equipment/${this.props.eq &&
                    this.props.eq._id}/generatekey`,
                {},
                {
                    headers: { Authorization: `Bearer ${getToken()}` },
                },
            );

            if (API_RESPONSE.data.success && API_RESPONSE.status === 200) {
                message.success("Successfully generated the register key!");

                this.props.generatedKey(API_RESPONSE.data.registerKey);
            } else {
                message.warning(API_RESPONSE.data.message);
            }
        } catch (err) {
            unknownErrorMessage(
                err.response ? err.response.data.message : null,
            );
        }

        this.setState({ loading: false });
    };

    renderRegisterModalContent = () => {
        if (this.props.eq && !this.props.eq.registerKey) {
            return (
                <Container>
                    This equipment doesn't have register key. In order to
                    register the device, you must generate the register key.
                    <ButtonContainer>
                        <Button
                            onClick={this.handleGenerateKey}
                            loading={this.state.loading}
                            type="dashed"
                        >
                            Generate register key
                        </Button>
                    </ButtonContainer>
                </Container>
            );
        } else {
            return (
                <Container>
                    <p>
                        In order to register device to this equipment, you must
                        open Application and navigate to{" "}
                        <strong>Settings > Register device</strong>
                    </p>
                    <p style={{ textAlign: "left" }}>Register key:</p>
                    <Input value={this.props.eq && this.props.eq.registerKey} />
                    <QRContainer>
                        <QRCode
                            size={500}
                            style={{
                                width: "80%",
                                height: "auto",
                            }}
                            value={this.props.eq && this.props.eq.registerKey}
                        />
                    </QRContainer>
                </Container>
            );
        }
    };

    handleCancel = () => {
        this.props.handleClose();
    };

    render() {
        return (
            <div>
                <Modal
                    onCancel={this.handleCancel}
                    onOk={this.handleCancel}
                    title="Register device"
                    visible={this.props.visible}
                >
                    {this.renderRegisterModalContent()}
                </Modal>
            </div>
        );
    }
}

RegisterModal.defaultProps = {
    eq: {},
};
