import React, { Component } from "react";
import OlMap from "ol/Map";
import View from "ol/View";
import { transform } from "ol/proj";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { OSM, Vector as VectorSource } from "ol/source";
import { Style, Icon } from "ol/style";
import styled from "styled-components";
import Cookies from "js-cookie";
import Draw from "ol/interaction/Draw";
import { Switch, Row, Col } from "antd";
import { permissions } from "utils/permissions";
import { connect } from "react-redux";

import Boundaries from "./Elements/Boundaries";
import Spots from "./Elements/Spots";
import EquipmentLocation from "./Elements/EquipmentLocation";

const MapDiv = styled.div`
    width: 100%;
    height: 600px;
`;

const Label = styled.span`
    margin-right: 6px;
`;

var iconStyle = new Style({
    image: new Icon({
        anchor: [0.5, 38],
        anchorXUnits: "fraction",
        anchorYUnits: "pixels",
        src: require("../../icons/marker.png")
    })
});

class CompanyAirportShow extends Component {
    mapId = null;

    componentWillUnmount() {
        this.setState({ mapSetUp: false });
    }

    state = {
        mapId: null,
        mapSetUp: false,
        map: null,
        draw: null,
        raster: null,
        source: null,
        vector: null,
        translate: null,
        select: null,
        spotsSource: null,

        cookies: {},
        filters: {
            boundaries: true,
            "pickup-spot": true
        }
    };

    async componentDidMount() {
        await this.setState({
            mapId: `map-${Math.floor(Math.random() * 10000)}`
        });

        let cookies = Cookies.getJSON("company-map");
        await this.setState({ cookies, filters: cookies && cookies.filters });

        // Map init
        let raster = new TileLayer({
            source: new OSM()
        });

        let spotsSource = new VectorSource({ wrapX: false });
        let spotsVector = new VectorLayer({
            source: spotsSource
        });

        let source = new VectorSource({ wrapX: false });
        let vector = new VectorLayer({
            source: source
        });

        let map = new OlMap({
            layers: [raster, vector, spotsVector],
            target: this.state.mapId,
            view: new View({
                center: transform(
                    [
                        this.props.airport.location.center.longitude,
                        this.props.airport.location.center.latitude
                    ],
                    "EPSG:4326",
                    "EPSG:3857"
                ),
                zoom: 14
            })
        });

        let draw = new Draw({
            source: spotsSource,
            type: "Point",
            style: iconStyle
        });

        await this.setState({ source, vector, raster, map, spotsSource, draw });
    }

    handleSwitchFilter = (filter, switchState, source = this.state.source) => {
        let cookie = Cookies.getJSON("company-map");
        let newFilters = {
            ...cookie,
            filters: {
                ...(cookie && cookie.filters)
            }
        };
        newFilters.filters[filter] = switchState;
        this.setState({ filters: newFilters.filters });

        Cookies.set("company-map", newFilters);
    };

    componentDidUpdate(prevProps, prevState) {
        this.state.map && this.state.map.updateSize();
    }

    render() {
        return (
            <div>
                {this.props.showFilters && (
                    <Row style={{ marginBottom: 16 }}>
                        <Col>
                            <Label>Show boundaries</Label>
                            <Switch
                                checked={
                                    this.state.filters
                                        ? this.state.filters["boundaries"]
                                        : false
                                }
                                onChange={checked =>
                                    this.handleSwitchFilter(
                                        "boundaries",
                                        checked
                                    )
                                }
                            />

                            {permissions.check("SPOT-R") && (
                                <div style={{ display: "inline-block" }}>
                                    <Label style={{ marginLeft: 12 }}>
                                        Show pick-up spots
                                    </Label>
                                    <Switch
                                        checked={
                                            this.state.filters
                                                ? this.state.filters[
                                                      "pickup-spot"
                                                  ]
                                                : false
                                        }
                                        onChange={checked =>
                                            this.handleSwitchFilter(
                                                "pickup-spot",
                                                checked,
                                                this.state.spotsSource
                                            )
                                        }
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>
                )}

                <Spots
                    handleSwitchFilter={this.handleSwitchFilter}
                    hide={
                        (this.props.showFilters &&
                            (this.state.filters &&
                                !this.state.filters["pickup-spot"])) ||
                        (!this.props.showFilters && !this.props.showPickupSpots)
                    }
                    map={this.state.map}
                    draw={this.state.draw}
                    source={this.state.spotsSource}
                    {...this.props}
                />

                <MapDiv id={this.state.mapId} className={this.state.mapId} />

                <Boundaries
                    hide={
                        (this.props.showFilters &&
                            (this.state.filters &&
                                !this.state.filters["boundaries"])) ||
                        (!this.props.showFilters && !this.props.showBoundaries)
                    }
                    source={this.state.source}
                    boundaries={this.props.airport.location.boundaries}
                />

                {permissions.check("DEVICE-LR") && (
                    <EquipmentLocation
                        company={this.props.company}
                        map={this.state.map}
                    />
                )}
            </div>
        );
    }
}

CompanyAirportShow.defaultProps = {
    showBoundaries: true,
    showPickupSpots: true
};

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps)(CompanyAirportShow);
