import React, { Component } from 'react'
import { Switch, Route, Redirect } from "react-router-dom";
import { permissions } from "../../../utils/permissions"

import Companies from "./Index/index.js";
import Add from "./Add";
import Update from "./Update";
import Company from "./Company"

export default class index extends Component {

    render() {
        if(!permissions.oneOf(["COMPANY-C", "COMPANY-R", "COMPANY-U", "COMPANY-D"])) return <Redirect to="/" />
        
        return (
            <div>
                <Switch>
                    <Route exact path="/companies" component={Companies} />
                    <Route exact path="/companies/add" component={Add} />
                    <Route exact path="/companies/:company" component={Company} />
                    <Route path="/companies/:company/update" component={Update} />
                </Switch>
            </div>
        )
    }
}
