import React, { Component } from "react";
import Header from "../../../../components/Header";
import Content from "../../../../components/Content";
import { permissions } from "../../../../utils/permissions";
import history from "../../../../history";
import { set } from "../../../../_actions/appActions";
import store from "../../../../store";
import axios from "axios";
import { getToken } from "../../../../utils/token";
import { unknownErrorAlert } from "../../../../utils/app";

import EquipmentManufacturesList from "components/EquipmentManufacturesList";

export default class Index extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            dataSource: [],
            dataSourceEQ: [],
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidMount() {
        this._isMounted = true;

        if (!permissions.check("EQ-R")) history.push("/");

        this._isMounted &&
            store.dispatch(
                set({
                    loading: true,
                    breadcrumb: [
                        {
                            name: "Equipment manufactures",
                            href: "/equipment/manufactures",
                        },
                    ],
                }),
            );

        try {
            const API_RESPONSE = await axios.get(
                `${window.API}/equipment/manufactures`,
                {
                    headers: { Authorization: `Bearer ${getToken()}` },
                },
            );

            if (API_RESPONSE.status === 200 && API_RESPONSE.data.success) {
                this._isMounted &&
                    this.setState({
                        dataSource: API_RESPONSE.data.equipmentManufactures,
                    });
                this._isMounted && store.dispatch(set({ loading: false }));
            }

            const API_RESPONSE_EQ = await axios.get(`${window.API}/equipment`, {
                headers: { Authorization: `Bearer ${getToken()}` },
            });

            if (
                API_RESPONSE_EQ.status === 200 &&
                API_RESPONSE_EQ.data.success
            ) {
                this._isMounted &&
                    this.setState({
                        dataSourceEQ: API_RESPONSE_EQ.data.equipment,
                    });
                this._isMounted && store.dispatch(set({ loading: false }));
            }
        } catch (err) {
            unknownErrorAlert();
        }
    }

    render() {
        return (
            <div>
                <Header>Equipment manufactures</Header>
                <Content>
                    <EquipmentManufacturesList
                        eq={this.state.dataSourceEQ}
                        dataSource={this.state.dataSource}
                    />
                </Content>
            </div>
        );
    }
}
